import React from "react";
import classNames from "classnames";
import {
  Hidden,
  Popper,
  Grow,
  MenuItem,
  Paper,
  ClickAwayListener,
  Divider,
  MenuList,
} from "@material-ui/core";
import Button from "../components/Button";
import { NavLink } from "react-router-dom";
import { Person, Language } from "@material-ui/icons";
import { changeLanguage, LOCALE } from "../../properties/Locale";
import { AuthRepository } from "../../auth/AuthRepository";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";

const useStyles = makeStyles(styles);

export default function HeaderLinks() {
  const classes = useStyles();
  const [openLanguage, setOpenLanguage] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickLanguage = (event) => {
    if (openLanguage && openLanguage.contains(event.target)) {
      setOpenLanguage(null);
    } else {
      setOpenLanguage(event.currentTarget);
    }
  };

  const handleCloseLanguage = (item) => {
    setOpenLanguage(null);
    changeLanguage(item);
  };

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = () => {
    AuthRepository.logout().then(
      (res) => {},
      (err) => {}
    );
  };
  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openLanguage ? "language-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickLanguage}
          className={classes.buttonLink}
        >
          <Language className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>{LOCALE.language}</p>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openLanguage)}
          anchorEl={openLanguage}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openLanguage }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="language-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseLanguage}>
                  <MenuList role="menu">
                    {LOCALE.getAvailableLanguages().map((item) => (
                      <MenuItem
                        onClick={() => handleCloseLanguage(item)}
                        className={classes.dropdownItem}
                      >
                        {item.toUpperCase()}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>{LOCALE.my_profile}</p>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      component={NavLink}
                      to={"/app/profile"}
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      {LOCALE.my_profile}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      {LOCALE.logout}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
