import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Icon,
  Tooltip,
  TextField,
  Button,
  Checkbox,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/components/tableStyle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
// core components
import { LOCALE } from "../../../properties/Locale";
import { MunicipalitiesRepository } from "./MunicipalitiesRepository";
import { changePageTitle } from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles);

export default function MunicipalitiesComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    totalElements: 0,
    number: 0,
    content: [],
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("name");
  const [orderDirection, setOrderDirection] = useState("ASC");
  const [filterBy, setFilterBy] = useState();
  const [filterValue, setFilterValue] = useState();
  const [filterData, setFilterData] = useState({ name: "" });
  const [isActive, setIsActive] = useState(true);
  const globalState = useSelector((state) => state);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.municipalities));
    loadMunicipalities(page, size, orderBy, orderDirection);
  }, [
    page,
    size,
    globalState.triggerRerender,
    filterValue,
    orderBy,
    orderDirection,
  ]);

  const loadMunicipalities = (page, size, orderBy, orderDirection) => {
    MunicipalitiesRepository.fetchAllMunicipalities({
      page: page,
      size: size,
      orderBy: orderBy,
      orderDirection: orderDirection,
      filterBy: filterBy,
      filterValue: filterValue,
      isActive: null,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleFilterMunicipalityFieldChange = (name) => (event) => {
    setFilterData({ [name]: event.target.value });
    setFilterBy(name);
    setFilterValue(event.target.value);
  };

  const handleClickChangeOrderBy = (event) => {
    let orderValue = event.currentTarget.value;
    if (orderBy === orderValue) {
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
    } else {
      setOrderBy(orderValue);
      setOrderDirection("DESC");
    }
  };

  return (
    <div>
      <Card>
        <CardHeader color="theme">
          <Tooltip
            title={LOCALE.municipalities_create_new_municipality}
            aria-label={LOCALE.municipalities_create_new_municipality}
          >
            <Link
              to={`/app/configuration/municipalities/create`}
              style={{ textDecoration: "none" }}
            >
              <Button
                color="primary"
                size="small"
                startIcon={<Icon>person_add</Icon>}
                variant="contained"
                aria-label="create"
              >
                {LOCALE.municipalities_create_new_municipality}
              </Button>
            </Link>
          </Tooltip>
        </CardHeader>
        <CardBody>
          <Table aria-label="municipalities table" className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.name}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"name"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "name" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                  <TextField
                    id="input_filter_name"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterMunicipalityFieldChange("name")}
                    placeholder={`${
                      LOCALE.filter_by
                    } ${LOCALE.name.toLowerCase()}`}
                    value={filterData?.name ? filterData?.name : ""}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.municipalities_municipality_name_en}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"nameEn"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "nameEn" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                  <TextField
                    id="input_filter_name_en"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterMunicipalityFieldChange("nameEn")}
                    placeholder={`${
                      LOCALE.filter_by
                    } ${LOCALE.name_en.toLowerCase()}`}
                    value={filterData?.nameEn ? filterData?.nameEn : ""}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.name_al}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"nameAl"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "nameAl" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                  <TextField
                    id="input_filter_name_al"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterMunicipalityFieldChange("nameAl")}
                    value={filterData?.nameAl ? filterData?.nameAl : ""}
                    placeholder={`${
                      LOCALE.filter_by
                    } ${LOCALE.name_al.toLowerCase()}`}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.code}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"code"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "code" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                  <TextField
                    id="input_filter_code"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterMunicipalityFieldChange("code")}
                    placeholder={`${
                      LOCALE.filter_by
                    } ${LOCALE.code.toLowerCase()}`}
                    value={filterData?.code ? filterData?.code : ""}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      marginBottom: "62px",
                      minWidth: "80px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {LOCALE.active}
                  </div>
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      marginBottom: "62px",
                      minWidth: "80px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {LOCALE.actions}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content.map((municipality, index) => (
                <TableRow
                  key={municipality.name}
                  className={classes.tableBodyRow}
                  style={index % 2 ? { background: "#f0f0f0" } : {}}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableCell}
                  >
                    {municipality.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {municipality.nameEn}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {municipality.nameAl}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {municipality.code}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Checkbox
                      checked={true}
                      disabled
                      inputProps={{ "aria-label": "primary checkbox" }}
                    ></Checkbox>
                  </TableCell>
                  <TableCell
                    style={{ width: "100px", textAlign: "center" }}
                    className={classes.tableCell}
                  >
                    <Tooltip title={LOCALE.details} aria-label={LOCALE.details}>
                      <Link
                        to={`/app/configuration/municipalities/${municipality.id}/details`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          color="primary"
                          aria-label="details"
                        >
                          <Icon>ballot</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title={LOCALE.edit} aria-label={LOCALE.edit}>
                      <Link
                        to={`/app/configuration/municipalities/${municipality.id}/edit`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          style={{ color: "#F1C40F" }}
                          aria-label="edit"
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title={LOCALE.delete} aria-label={LOCALE.delete}>
                      <Link
                        to={`/app/configuration/municipalities/${municipality.id}/delete`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="delete"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={6}
                  count={data.totalElements}
                  rowsPerPage={size}
                  page={data.number}
                  labelRowsPerPage={LOCALE.rows_per_page}
                  SelectProps={{
                    inputProps: { "aria-label": LOCALE.rows_per_page },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
