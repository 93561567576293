import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  IconButton,
  Icon,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/layouts/appStyle";
// core components
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import { SchoolsRepository } from "./SchoolsRepository";
import { LOCALE } from "../../../properties/Locale";
import EntityMetadata from "../../../common/components/EntityMetadata";
import CardBody from "../../../common/components/Card/CardBody";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import { MunicipalitiesRepository } from "../../configuration/municipalities/MunicipalitiesRepository";

const useStyles = makeStyles(styles);

export default function SchoolEditComponent(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(false);
  const [schoolId] = useState(props.match.params.schoolId);
  const [rerender, triggerRerenderLocal] = React.useState(false);
  const [municipalities, setMunicipalities] = useState();
  const [data, setData] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const loadSchool = (id) => {
      setLoading(true);
      SchoolsRepository.fetchSchool(id).then((res) => {
        setData(res.data);
        setLoading(false);
      });
    };

    loadSchool(schoolId);
  }, [schoolId]);

  useEffect(() => {
    setData(props.data);
    MunicipalitiesRepository.fetchAllMunicipalities({
      page: 0,
      size: 100,
      orderBy: "name",
      orderDirection: "ASC",
    })
      .then((res) => {
        setMunicipalities(res.data);
      })
      .catch((err) => {});
  }, []);

  const setDataProp = (event, name) => {
    var dataProp = data;
    if (name === "municipality") {
      dataProp.municipality["id"] = event.target.value;
    } else {
      dataProp[name] = event.target.value;
    }
    setData(dataProp);
    triggerRerenderLocal(!rerender);
  };

  const handleSubmit = () => {
    showLoadingBar();
    SchoolsRepository.updateSchool(data)
      .then((res) => {
        hideLoadingBar();
        dispatch(triggerRerender());
        dispatch(
          notifyShowSuccessMessage(LOCALE.schools_update_success_message)
        );
        setRedirectTo("/app/configuration/schools");
      })
      .catch((err) => {
        hideLoadingBar();
      });
  };

  const showLoadingBar = () => {
    setLoading(true);
    setBtnSubmitLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
    setBtnSubmitLoading(false);
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          maxWidth="md"
          fullWidth={true}
          onClose={() => {}}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.schools_edit}${data?.name ? ": " + data?.name : ""}`}
              <Link
                to={`/app/configuration/schools/`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {loading ? <LinearProgress /> : ""}
            <div>
              <DialogContent>
                <TextField
                  id="name"
                  label={LOCALE.name}
                  fullWidth={true}
                  value={data?.name ? data?.name : ""}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setDataProp(e, "name")}
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                >
                  <InputLabel>{LOCALE.municipality}</InputLabel>
                  {municipalities && data && (
                    <Select
                      label={LOCALE.municipality}
                      value={data?.municipality?.id}
                      onChange={(e) => setDataProp(e, "municipality")}
                    >
                      {municipalities?.content.map((municipality) => (
                        <MenuItem key={municipality.id} value={municipality.id}>
                          {municipality.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
                <EntityMetadata data={data} />
              </DialogContent>
              <DialogActions>
                {!btnSubmitLoading && (
                  <Link
                    to={`/app/configuration/schools/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button size="small" variant="outlined" color="secondary">
                      {LOCALE.cancel}
                    </Button>
                  </Link>
                )}
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  disabled={btnSubmitLoading}
                  className={btnSubmitLoading ? "btn-loading" : ""}
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  {LOCALE.save}
                  {btnSubmitLoading && (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      style={{ position: "absolute" }}
                    />
                  )}
                </Button>
              </DialogActions>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
