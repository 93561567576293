import {
  drawerPanelWidth,
  transition,
  container,
  loginPanelWidth,
  grayColor,
  whiteColor,
  blackColor,
  hexToRgb,
  defaultFont,
  bgImg,
} from "../../jss/mainStyle";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  sideBarPanel: {
    position: "relative",
    float: "left",
    width: `${drawerPanelWidth}px`,
    overflow: "auto",
    top: "0",
    overflowScrolling: "touch",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerPanelWidth}px)`,
    },
    position: "relative",
    float: "right",
    ...transition,
    width: "100%",
    height: "14%",
    overflowScrolling: "touch",
    backgroundColor: "#ecf0f1",
  },
  loginWrapper: {
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    color: "white",
    top: "0",
    left: "0",
    backgroundImage: "url(" + bgImg + ")",
    backgroundSize: "cover",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8",
    },
  },
  loginLeftPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${loginPanelWidth}px)`,
    },
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    height: "100%",
    display: "flex",
    color: "white",
    top: "0",
    left: "0",
    zIndex: "4",
  },
  loginRightPanel: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "column",
      display: "flex",
      width: "100%",
      opacity: "90%",
      height: "95%",
    },
    width: `${loginPanelWidth}px`,
    overflow: "auto",
    position: "relative",
    height: "100vh",
    float: "right",
    ...transition,
    overFlowScrolling: "touch",
    zIndex: "4",
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
    height: "100%",
    overflow: "auto",
  },
  container,
  map: {
    marginTop: "70px",
  },
  grid: {
    margin: "0 -15px !important",
  },
  gridItem: {
    padding: "0 15px !important",
    display: "flex",
    justifyContent: "center",
  },

  cardTitleWhite: {
    color: whiteColor,
    marginTop: "5px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleGray: {
    color: grayColor[2],
    marginTop: "15px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "6px",
    textDecoration: "none",
    "& small": {
      color: grayColor[2],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  itemIcon: {
    width: "25px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "8px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
  },
  itemIconGray: {
    width: "25px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "8px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(grayColor[1]) + ", 0.8)",
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    color: whiteColor,
    width: "175px",
  },
  loginLeftPanelImg: {
    marginTop: "15%",
    opacity: "50%",
    width: "200px",
  },
  loginLeftPanelText: {
    opacity: "60%",
    fontSize: "30pt",
  },
  paperLogin: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "100%",
    },
    overflow: "auto",
    height: "80%",
    width: "90%",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10%",
    },
    marginTop: "15%",
  },
  avatarLogin: {
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      height: "40px",
    },
    margin: "0 auto",
    textAlign: "center",
    backgroundColor: "#ab3027",
    width: "50px",
    height: "50px",
  },
  panelLogin: {
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
    width: "75%",
    marginTop: "5%",
  },
});

export default appStyle;
