import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import {
  Grid,
  Typography,
  TextField,
  Link,
  CircularProgress,
  Hidden,
  Button,
  Paper,
  Avatar,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/layouts/appStyle";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
// core components
import { changeLanguage, LOCALE } from "../../properties/Locale";
import {
  notifyShowErrorMessage,
  COMMON_ACTIONS,
} from "../../common/actions/CommonActions";
import { AuthService } from "../../auth/AuthService";
import Snackbar from "../../common/components/Snackbar/Snackbar.js";
import { validateLoginForm } from "./LoginValidations";
import { AuthRepository } from "../../auth/AuthRepository";

const useStyles = makeStyles(styles);

export default function LoginComponent() {
  const classes = useStyles();
  const counter = useSelector((state) => state);
  const {
    handleSubmit: loginUserForm,
    register: registerLoginUser,
    errors: errorsLoginUser,
  } = useForm({
    validationSchema: validateLoginForm,
  });

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(counter.loading);
  const [redirect, setRedirect] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {}, []);

  const handleChangeLocale = (e) => {
    changeLanguage(e);
  };
  const onLoginUser = (values) => {
    setLoading(true);
    AuthRepository.fetchToken(values).then(
      (res) => {
        AuthService.setToken(res.data);
        setTimeout(function () {
          setRedirect("/app/dashboard");
        }, 500);
      },
      (err) => {
        setLoading(false);
        dispatch(notifyShowErrorMessage(err.message));
      }
    );
  };
  const handleClose = () => {
    if (counter.open) {
      dispatch({
        type: COMMON_ACTIONS.CLEAR_NOTIFICATIONS,
        payload: null,
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className={classes.loginWrapper}>
      <Hidden smDown implementation="css">
        <div className={classes.loginLeftPanel}>
          <img
            alt=""
            src={require("../../assets/img/grb.png")}
            width="200px"
            style={{ marginTop: "15%", opacity: "50%" }}
          ></img>
          <Typography component="h1" variant="h2" style={{ opacity: "60%" }}>
            <p style={{ fontSize: "18pt" }}>{LOCALE.login_h1}</p>
            {LOCALE.login_h2}
          </Typography>
        </div>
      </Hidden>

      <div className={classes.loginRightPanel}>
        <Hidden mdUp implementation="css">
          <div style={{ padding: "10px" }}>
            <img
              alt=""
              src={require("../../assets/img/grb.png")}
              width="40px"
              style={{ opacity: "50%" }}
            ></img>
            <Typography component="h1" variant="h2" style={{ opacity: "60%" }}>
              <p style={{ fontSize: "7pt" }}>{LOCALE.login_h1}</p>
              <p style={{ fontSize: "10pt" }}>{LOCALE.login_h2}</p>
            </Typography>
          </div>
        </Hidden>
        <Paper className={classes.paperLogin}>
          <Grid container className={classes.gridMargin}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Avatar className={classes.avatarLogin}>
                <LockOutlinedIcon />
              </Avatar>
              <h2 className={classes.cardTitleGray}>{LOCALE.login_signin}</h2>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <div className={classes.panelLogin}>
            <form onSubmit={loginUserForm(onLoginUser)}>
              <div>
                <TextField
                  variant="outlined"
                  error={errorsLoginUser?.username}
                  helperText={errorsLoginUser?.username?.message}
                  margin="normal"
                  fullWidth
                  label={LOCALE.login_username}
                  name="username"
                  autoFocus
                  inputRef={registerLoginUser}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  error={errorsLoginUser?.password}
                  helperText={errorsLoginUser?.password?.message}
                  fullWidth
                  name="password"
                  label={LOCALE.login_password}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  inputRef={registerLoginUser}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ marginTop: "3%", marginBottom: "3%" }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  )}
                  {!loading && LOCALE.login_signin}
                </Button>
              </div>
            </form>
            {/* <Grid container style={{ marginTop: "25px" }}>
              <Grid item xs={9}></Grid>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => handleChangeLocale("mk")}
                >
                  МК
                </Link>
              </Grid>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => handleChangeLocale("al")}
                >
                  AL
                </Link>
              </Grid>
            </Grid> */}
          </div>
        </Paper>
      </div>

      {counter.open && (
        <Snackbar
          place="br"
          icon={counter.variant}
          color={counter.variant}
          message={counter.message}
          open={true}
          closeNotification={handleClose}
          close
        />
      )}
    </div>
  );
}
