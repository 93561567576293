import React from "react";
import classNames from "classnames";
import { AppBar, IconButton, Toolbar, Hidden } from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerStyle";
import HeaderLinks from "./HeaderLinks";
import { useDispatch, useSelector } from "react-redux";
import { COMMON_ACTIONS } from "../actions/CommonActions";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const globalState = useSelector((state) => state);
  const dispatch = useDispatch();
  function toogle() {
    if (globalState.mobileOpen) {
      dispatch({
        type: COMMON_ACTIONS.MOBILE_SCREEN,
        payload: {
          mobileOpen: false,
        },
      });
    } else {
      dispatch({
        type: COMMON_ACTIONS.MOBILE_SCREEN,
        payload: {
          mobileOpen: true,
        },
      });
    }
  }

  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  return (
    <div>
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <div className={classes.title}>{globalState.pageTitle}</div>
          </div>
          <Hidden smDown implementation="css">
            <HeaderLinks />
          </Hidden>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toogle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <hr />
      </AppBar>
    </div>
  );
}
