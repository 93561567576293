import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import {
  TablePagination,
  TableRow,
  TableFooter,
  Icon,
  IconButton,
  Tooltip,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Button,
  Chip,
} from "@material-ui/core";
// @material-ui/styles
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { makeStyles } from "@material-ui/core/styles";
import { ObjectionRepository } from "../eVoucher/ObjectionRepository";
import { LOCALE } from "../../properties/Locale";
import CardHeader from "../../common/components/Card/CardHeader";
import Card from "../../common/components/Card/Card";
import CardBody from "../../common/components/Card/CardBody";
import { changePageTitle } from "../../common/actions/CommonActions";
import styles from "../../assets/jss/components/tableStyle";

const useStyles = makeStyles(styles);

export default function ObjectionReviewsComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    totalElements: 0,
    number: 0,
    content: [],
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("dateCreated");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const globalState = useSelector((state) => state);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.objection));
    loadObjections(page, size, orderBy, orderDirection);
  }, [page, size, globalState.triggerRerender, orderBy, orderDirection]);

  const loadObjections = (
    page,
    size,
    orderBy,
    orderDirection,
    filterBy = null,
    filterValue = null
  ) => {
    ObjectionRepository.fetchAll({
      page: page,
      size: size,
      orderBy: orderBy,
      orderDirection: orderDirection,
      filterBy: filterBy,
      filterValue: filterValue,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleClickChangeOrderBy = (event) => {
    let orderValue = event.currentTarget.value;
    if (orderBy === orderValue) {
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
    } else {
      setOrderBy(orderValue);
      setOrderDirection("DESC");
    }
  };

  return (
    <div>
      <Card>
        <CardHeader color="theme">{LOCALE.objections}</CardHeader>
        <CardBody>
          <Table aria-label="objection table" className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.date_created}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"dateCreated"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "dateCreated" ? (
                      orderDirection === "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.embg}
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.email}
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.telephone}
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.status}
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content.map((objection, index) => (
                <TableRow
                  key={objection.id}
                  className={classes.tableBodyRow}
                  style={index % 2 ? { background: "#f0f0f0" } : {}}
                >
                  <TableCell className={classes.tableCell}>
                    {moment(objection.dateCreated).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}{" "}
                    ({moment(objection.dateCreated).fromNow()})
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {objection.embg}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {objection.email}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {objection.phone}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {objection.status === "PENDING" && (
                      <Chip
                        label={LOCALE.pending.toUpperCase()}
                        color="primary"
                      />
                    )}
                    {objection.status === "REJECTED" && (
                      <Chip
                        label={LOCALE.rejected.toUpperCase()}
                        color="secondary"
                      />
                    )}
                    {objection.status === "ACCEPTED" && (
                      <Chip
                        label={LOCALE.accepted.toUpperCase()}
                        style={{ backgroundColor: "green", color: "white" }}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip title={LOCALE.view} aria-label={LOCALE.view}>
                      <Link
                        to={`/app/objectionReviews/${objection.id}/review`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          style={{ color: "orange" }}
                          aria-label="edit"
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={6}
                  count={data.totalElements}
                  rowsPerPage={size}
                  page={data.number}
                  labelRowsPerPage={LOCALE.rows_per_page}
                  SelectProps={{
                    inputProps: { "aria-label": LOCALE.rows_per_page },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
