import Axios from "axios";
import { SETTINGS } from "../../properties/ApiProps";

export const VoucherRequestRepository = {
  createVoucherSchoolSuppliesRequest: (data, recaptchaToken) => {
    const formData = new FormData();
    formData.append(
      "nameSurname",
      data.schoolSuppliesVoucherRequest.nameSurname
    );
    formData.append("embg", data.schoolSuppliesVoucherRequest.embg);
    formData.append(
      "addressLine",
      data.schoolSuppliesVoucherRequest.addressLine
    );
    formData.append("email", data.schoolSuppliesVoucherRequest.email);
    formData.append("school", data.schoolSuppliesVoucherRequest.school);
    formData.append("telephone", data.schoolSuppliesVoucherRequest.telephone);
    formData.append(
      "municipality",
      data.schoolSuppliesVoucherRequest.municipality
    );
    formData.append("idNumber", data.schoolSuppliesVoucherRequest.idNumber);
    formData.append(
      "idAuthority",
      data.schoolSuppliesVoucherRequest.idAuthority
    );
    formData.append(
      "idDateOfExpiry",
      data.schoolSuppliesVoucherRequest.idDateOfExpiry
    );
    formData.append("proofsWay", data.schoolSuppliesVoucherRequest.proofsWay);
    if (data.file1?.length > 0) {
      formData.append("REGULAR_STUDENT_PROOF", data.file1[0]);
    }
    if (data.file2?.length > 0) {
      formData.append("FINANCIAL_STATUS_OF_FAMILY_PROOF", data.file2[0]);
    }
    if (data.file3?.length > 0) {
      formData.append(
        "LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF",
        data.file3[0]
      );
    }
    if (data.file4?.length > 0) {
      formData.append("USE_OF_PERSONAL_DATA_PROOF", data.file4[0]);
    }
    return Axios({
      url: SETTINGS.API_URL + "/voucherRequest/schoolSupplies",
      method: "POST",
      params: { "g-recaptcha-response": recaptchaToken },
      data: formData,
    });
  },

  createVoucherStudyCostsRequest: (data, recaptchaToken) => {
    const formData = new FormData();
    formData.append("nameSurname", data.studyCostsVoucherRequest.nameSurname);
    formData.append("embg", data.studyCostsVoucherRequest.embg);
    formData.append("addressLine", data.studyCostsVoucherRequest.addressLine);
    formData.append("email", data.studyCostsVoucherRequest.email);
    formData.append("faculty", data.studyCostsVoucherRequest.faculty);
    formData.append("yearsOfStudy", data.studyCostsVoucherRequest.yearsOfStudy);
    formData.append("proofsWay", data.studyCostsVoucherRequest.proofsWay);
    if (data.file1?.length > 0) {
      formData.append("REGULAR_STUDENT_PROOF", data.file1[0]);
    }
    if (data.file2?.length > 0) {
      formData.append("FINANCIAL_STATUS_OF_FAMILY_PROOF", data.file2[0]);
    }
    if (data.file3?.length > 0) {
      formData.append(
        "LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF",
        data.file3[0]
      );
    }
    if (data.file4?.length > 0) {
      formData.append("USE_OF_PERSONAL_DATA_PROOF", data.file4[0]);
    }
    if (data.file5?.length > 0) {
      formData.append("NUMBER_OF_PASSED_EXAMS_PROOF", data.file5[0]);
    }
    if (data.file6?.length > 0) {
      formData.append("UPPI_PROOF", data.file6[0]);
    }
    if (data.file10?.length > 0) {
      formData.append("WAY_OF_ATTENDING_CLASSES_PROOF", data.file10[0]);
    }
    return Axios({
      url: SETTINGS.API_URL + "/voucherRequest/studyCosts",
      method: "POST",
      params: { "g-recaptcha-response": recaptchaToken },
      data: formData,
    });
  },

  createVoucherTransportCostsRequest: (data, recaptchaToken) => {
    const formData = new FormData();
    formData.append(
      "nameSurname",
      data.transportCostsVoucherRequest.nameSurname
    );
    formData.append("embg", data.transportCostsVoucherRequest.embg);
    formData.append(
      "addressLine",
      data.transportCostsVoucherRequest.addressLine
    );
    formData.append("email", data.transportCostsVoucherRequest.email);
    formData.append("proofsWay", data.transportCostsVoucherRequest.proofsWay);
    if (data.file4?.length > 0) {
      formData.append("USE_OF_PERSONAL_DATA_PROOF", data.file4[0]);
    }
    if (data.file8?.length > 0) {
      formData.append("MONTHLY_TRANSPORTATION_CARD_PROOF", data.file8[0]);
    }
    if (data.file9?.length > 0) {
      formData.append(
        "FIRST_TIME_IN_THE_FIRST_CYCLE_OF_STUDIES_PROOF",
        data.file9[0]
      );
    }
    if (data.file10?.length > 0) {
      formData.append("WAY_OF_ATTENDING_CLASSES_PROOF", data.file10[0]);
    }
    return Axios({
      url: SETTINGS.API_URL + "/voucherRequest/transportCosts",
      method: "POST",
      params: { "g-recaptcha-response": recaptchaToken },
      data: formData,
    });
  },

  createVoucherStudentDoormsRequest: (data, recaptchaToken) => {
    const formData = new FormData();
    formData.append(
      "nameSurname",
      data.studentDoormsVoucherRequest.nameSurname
    );
    formData.append("embg", data.studentDoormsVoucherRequest.embg);
    formData.append(
      "addressLine",
      data.studentDoormsVoucherRequest.addressLine
    );
    formData.append("email", data.studentDoormsVoucherRequest.email);
    formData.append(
      "studentDoorm",
      data.studentDoormsVoucherRequest.studentDoorm
    );
    formData.append(
      "yearsOfStudy",
      data.studentDoormsVoucherRequest.yearsOfStudy
    );
    formData.append("proofsWay", data.studentDoormsVoucherRequest.proofsWay);
    if (data.file1?.length > 0) {
      formData.append("REGULAR_STUDENT_PROOF", data.file1[0]);
    }
    if (data.file2?.length > 0) {
      formData.append("FINANCIAL_STATUS_OF_FAMILY_PROOF", data.file2[0]);
    }
    if (data.file3?.length > 0) {
      formData.append(
        "LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF",
        data.file3[0]
      );
    }
    if (data.file4?.length > 0) {
      formData.append("USE_OF_PERSONAL_DATA_PROOF", data.file4[0]);
    }
    if (data.file10?.length > 0) {
      formData.append("WAY_OF_ATTENDING_CLASSES_PROOF", data.file10[0]);
    }
    if (data.file5?.length > 0) {
      formData.append("NUMBER_OF_PASSED_EXAMS_PROOF", data.file5[0]);
    }
    if (data.file6?.length > 0) {
      formData.append("UPPI_PROOF", data.file6[0]);
    }
    return Axios({
      url: SETTINGS.API_URL + "/voucherRequest/studentDoorms",
      method: "POST",
      params: { "g-recaptcha-response": recaptchaToken },
      data: formData,
    });
  },

  createVoucherStudentPrivateAccommodationRequest: (data, recaptchaToken) => {
    const formData = new FormData();
    formData.append(
      "nameSurname",
      data.studentPrivateAccommodationVoucherRequest.nameSurname
    );
    formData.append(
      "embg",
      data.studentPrivateAccommodationVoucherRequest.embg
    );
    formData.append(
      "addressLine",
      data.studentPrivateAccommodationVoucherRequest.addressLine
    );
    formData.append(
      "email",
      data.studentPrivateAccommodationVoucherRequest.email
    );
    formData.append(
      "faculty",
      data.studentPrivateAccommodationVoucherRequest.faculty
    );
    formData.append(
      "yearsOfStudy",
      data.studentPrivateAccommodationVoucherRequest.yearsOfStudy
    );
    formData.append(
      "proofsWay",
      data.studentPrivateAccommodationVoucherRequest.proofsWay
    );
    if (data.file7?.length > 0) {
      formData.append("PURCHASE_AGREEMENT_WITH_TENANT_PROOF", data.file7[0]);
    }
    if (data.file10?.length > 0) {
      formData.append("WAY_OF_ATTENDING_CLASSES_PROOF", data.file10[0]);
    }
    if (data.file1?.length > 0) {
      formData.append("REGULAR_STUDENT_PROOF", data.file1[0]);
    }
    if (data.file2?.length > 0) {
      formData.append("FINANCIAL_STATUS_OF_FAMILY_PROOF", data.file2[0]);
    }
    if (data.file3?.length > 0) {
      formData.append(
        "LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF",
        data.file3[0]
      );
    }
    if (data.file4?.length > 0) {
      formData.append("USE_OF_PERSONAL_DATA_PROOF", data.file4[0]);
    }
    if (data.file5?.length > 0) {
      formData.append("NUMBER_OF_PASSED_EXAMS_PROOF", data.file5[0]);
    }
    if (data.file6?.length > 0) {
      formData.append("UPPI_PROOF", data.file6[0]);
    }
    return Axios({
      url: SETTINGS.API_URL + "/voucherRequest/studentPrivateAccommodation",
      method: "POST",
      params: { "g-recaptcha-response": recaptchaToken },
      data: formData,
    });
  },

  getVoucherTypes: () => {
    return Axios({
      url: SETTINGS.API_URL + "/voucherType",
      method: "GET",
    });
  },
};
