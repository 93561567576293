import React from "react";
import {
  Chip,
  Icon,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import moment from "moment";
import { LOCALE } from "../../properties/Locale";

export default function EntityMetadata(props) {
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>{LOCALE.created_at}</TableCell>
          <TableCell>
            <Chip
              icon={<Icon>today</Icon>}
              label={
                props?.data?.dateCreated
                  ? moment(props?.data?.dateCreated).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ) +
                    " " +
                    "(" +
                    moment(props?.data?.dateCreated).fromNow() +
                    ")"
                  : "N/A"
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{LOCALE.created_by}</TableCell>
          <TableCell>
            <Chip
              icon={<Icon>perm_identity</Icon>}
              label={props?.data?.createdBy ? props?.data?.createdBy : "N/A"}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{LOCALE.modified_at}</TableCell>
          <TableCell>
            <Chip
              icon={<Icon>today</Icon>}
              label={
                props?.data?.dateModified
                  ? moment(props.data?.dateModified).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ) +
                    " " +
                    "(" +
                    moment(props?.data?.dateModified).fromNow() +
                    ")"
                  : "Not modified"
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{LOCALE.modified_by}</TableCell>
          <TableCell>
            <Chip
              icon={<Icon>perm_identity</Icon>}
              label={
                props?.data?.dateModified ? props?.data?.modifiedBy : "N/A"
              }
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
