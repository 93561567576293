import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  TablePagination,
  TableRow,
  TableFooter,
  Icon,
  IconButton,
  Tooltip,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Chip,
  Tabs,
  Tab,
  Dialog,
  Grid,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import tableStyles from "../../../assets/jss/components/tableStyle";
import styles from "../../../assets/jss/layouts/appStyle";
// core components
import { LOCALE } from "../../../properties/Locale";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  changePageTitle,
} from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import { EVoucherAdministrationRepository } from "./EVoucherAdministrationRepository";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useTableStyles = makeStyles(tableStyles);
const useStyles = makeStyles(styles);

export default function EVoucherAdministration() {
  const classesTable = useTableStyles();
  const dispatch = useDispatch();
  const [voucherTypes, setVoucherTypes] = React.useState();
  const [selectedType, setSelectedType] = React.useState();
  const [rerender, triggerRerender] = React.useState(false);
  useEffect(() => {
    dispatch(changePageTitle(LOCALE.module_administration));

    EVoucherAdministrationRepository.getVoucherTypes().then((res) => {
      setVoucherTypes(res.data);
    });
  }, []);

  useEffect(() => {
    triggerRerender(!rerender);
  }, [selectedType?.id]);

  const handleDateChange = (date, name) => {
    let data = selectedType;
    data[name] = date;
    setSelectedType(data);
    triggerRerender(!rerender);
  };

  const handleChangeActive = (event) => {
    let data = selectedType;
    data[event.target.name] = event.target.checked;
    setSelectedType(data);
    triggerRerender(!rerender);
  };

  const saveModule = () => {
    EVoucherAdministrationRepository.saveVoucherType(
      selectedType
    ).then((res) => {});
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={4} sm={12}>
          <Card>
            <CardHeader color="theme">
              <Typography>{LOCALE.modules}</Typography>
            </CardHeader>
            <CardBody>
              <List component="nav" aria-label="all-privileges">
                {voucherTypes?.map((item) => (
                  <div key={item.name}>
                    <ListItem
                      button
                      selected={item.id === selectedType?.id}
                      onClick={() => setSelectedType(item)}
                    >
                      <ListItemText primary={LOCALE.getString(item.name)} />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={8} sm={12}>
          <Card>
            <CardHeader color="theme">
              <Typography>{LOCALE.details}</Typography>
            </CardHeader>
            <CardBody>
              {selectedType && (
                <Grid container spacing={2}>
                  <Grid item md={4} sm={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="dense"
                        id="date-picker-inline"
                        label={LOCALE.date_from}
                        value={selectedType?.dateFrom}
                        onChange={(date) => handleDateChange(date, "dateFrom")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="dense"
                        id="date-picker-inline"
                        label={LOCALE.date_to}
                        value={selectedType?.dateTo}
                        onChange={(date) => handleDateChange(date, "dateTo")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedType?.active}
                          onChange={handleChangeActive}
                          name="active"
                          margin="dense"
                        />
                      }
                      label={LOCALE.active}
                    />
                  </Grid>
                  <Grid item md={12} sm={12}>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      fullWidth
                      onClick={() => saveModule()}
                    >
                      {LOCALE.save}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
