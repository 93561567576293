import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const AuthRepository = {
  fetchToken: user => {
    return Axios({
      url: SETTINGS.API_URL + "oauth/token",
      method: "POST",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER
      },
      params: {
        username: user.username,
        password: user.password,
        grant_type: "password"
      }
    });
  },

  refreshToken: () => {
    return Axios({
      url: SETTINGS.API_URL + "oauth/token",
      method: "POST",
      params: {
        refresh_token: JSON.parse(localStorage.getItem("accessToken"))
          .refresh_token,
        grant_type: "refresh_token"
      },
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER
      }
    });
  },

  logout: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    window.location.href = "/login";
  }
};
