import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import bgImg from "../../assets/img/productCurvyLines.png";
import schoolSuppliesPurple from "../../assets/img/school-supplies-sm-purple.png";
import schoolSuppliesBlue from "../../assets/img/school-supplies-sm-blue.png";

import MagicHover from "magic-hover";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import Skeleton from "@material-ui/lab/Skeleton";
import { VoucherRequestRepository } from "./voucherRequestRepository";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import DialogComponent from "../../common/components/DialogComponent";

const useStyles = makeStyles(styles);

export default function EVoucherComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [voucherTypes, setVoucherTypes] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [openLanguage, setOpenLanguage] = React.useState(null);
  const [
    openSchoolSuppliesDialog,
    setOpenSchoolSuppliesDialog,
  ] = React.useState(false);
  const [openStudyCostsDialog, setOpenStudyCostsDialog] = React.useState(false);
  const [
    openTransportCostsDialog,
    setOpenTransportCostsDialog,
  ] = React.useState(false);
  const [openStudentDoormsDialog, setOpenStudentDoormsDialog] = React.useState(
    false
  );
  const [
    openStudentPrivateAccommodationDialog,
    setOpenStudentPrivateAccommodationDialog,
  ] = React.useState(false);

  React.useEffect(() => {
    loadVoucherTypes();
  }, []);

  const loadVoucherTypes = () => {
    VoucherRequestRepository.getVoucherTypes().then((res) => {
      setVoucherTypes(res.data);
      setLoading(false);
    });
  };

  const handleClickLanguage = (event) => {
    if (openLanguage && openLanguage.contains(event.target)) {
      setOpenLanguage(null);
    } else {
      setOpenLanguage(event.currentTarget);
    }
  };

  const handleCloseLanguage = (item) => {
    if (item === "al") {
      setOpenLanguage(null);
      changeLanguage(item);
      window.location.href = "https://digjitalizohu.mon.gov.mk";
    } else {
      setOpenLanguage(null);
      changeLanguage(item);
      window.location.href = "https://digitalizirajse.mon.gov.mk";
    }
  };

  const closeDialog = (type) => {
    if (type === "schoolSupplies") {
      setOpenSchoolSuppliesDialog(false);
    } else if (type === "studyCosts") {
      setOpenStudyCostsDialog(false);
    } else if (type === "studentDoorms") {
      setOpenStudentDoormsDialog(false);
    } else if (type === "studentPrivateAccommodation") {
      setOpenStudentPrivateAccommodationDialog(false);
    } else if (type === "transportCosts") {
      setOpenTransportCostsDialog(false);
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(" + bgImg + ")",
        backgroundSize: "100% 100%",
        backgroundAttachment: "fixed",
      }}
    >
      {redirectTo && <Redirect to={redirectTo} />}
      <Container fixed style={{ paddingTop: "5vh", paddingBottom: "15vh" }}>
        <Grid container style={{ textAlign: "center", display: "inline" }}>
          <Grid item md={12} xs={12}>
            <img
              alt=""
              src={require("../../assets/img/grb_gold.png")}
              width="70px"
              style={{ opacity: "60%" }}
            ></img>
          </Grid>
        </Grid>
        <Grid container style={{ textAlign: "center" }}>
          <Grid item md={12} xs={12}>
            <Typography gutterBottom style={{ paddingTop: "5px" }}>
              {LOCALE.ministry_of_education_and_science}
              <br />
              <strong>{LOCALE.login_h2}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={5} style={{ paddingTop: "7vh" }}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <MagicHover>
              <Card>
                <CardActionArea
                  disabled={
                    (voucherTypes &&
                      voucherTypes.filter((e) => e.name === "STUDY_COSTS")[0]
                        .active === false) ||
                    loading
                  }
                  onClick={() => setRedirectTo("/studyCosts")}
                >
                  {!loading ? (
                    <CardMedia
                      component="img"
                      alt={LOCALE.study_costs}
                      height="140"
                      image={schoolSuppliesBlue}
                      title={LOCALE.study_costs}
                    />
                  ) : (
                    <Skeleton animation="wave" variant="rect" height={140} />
                  )}
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      {!loading ? (
                        LOCALE.study_costs
                      ) : (
                        <Skeleton animation="wave" />
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ minHeight: "140px" }}
                    >
                      {!loading ? (
                        LOCALE.study_costs_h1
                      ) : (
                        <Skeleton height={140} animation="wave" />
                      )}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                {!loading ? (
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setRedirectTo("/studyCosts")}
                      disabled={
                        voucherTypes &&
                        voucherTypes.filter((e) => e.name === "STUDY_COSTS")[0]
                          .active === false
                      }
                    >
                      {LOCALE.submit}
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => setOpenStudyCostsDialog(true)}
                      disabled={
                        voucherTypes &&
                        voucherTypes.filter((e) => e.name === "STUDY_COSTS")[0]
                          .active === false
                      }
                    >
                      {LOCALE.statementForm}
                    </Button>
                  </CardActions>
                ) : (
                  <Skeleton height={45} animation="wave" />
                )}
              </Card>
            </MagicHover>
            {openStudyCostsDialog && (
              <DialogComponent
                dialogTitle={LOCALE.study_costs}
                closeDialog={() => closeDialog("studyCosts")}
                contentText="contentText"
                dialogType="studyCosts"
              ></DialogComponent>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <MagicHover>
              <Card>
                <CardActionArea
                  disabled={
                    (voucherTypes &&
                      voucherTypes.filter((e) => e.name === "STUDENT_DOORM")[0]
                        .active === false) ||
                    loading
                  }
                  onClick={() => setRedirectTo("/studentsDoorm")}
                >
                  {!loading ? (
                    <CardMedia
                      component="img"
                      alt={LOCALE.student_doorms}
                      height="140"
                      image={schoolSuppliesPurple}
                      title={LOCALE.student_doorms}
                    />
                  ) : (
                    <Skeleton variant="rect" height={140} animation="wave" />
                  )}
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      {!loading ? (
                        LOCALE.student_doorms
                      ) : (
                        <Skeleton animation="wave" />
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ minHeight: "140px" }}
                    >
                      {!loading ? (
                        LOCALE.student_doorms_h1
                      ) : (
                        <Skeleton height={140} animation="wave" />
                      )}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                {!loading ? (
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setRedirectTo("/studentsDoorm")}
                      disabled={
                        voucherTypes &&
                        voucherTypes.filter(
                          (e) => e.name === "STUDENT_DOORM"
                        )[0].active === false
                      }
                    >
                      {LOCALE.submit}
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => setOpenStudentDoormsDialog(true)}
                      disabled={
                        voucherTypes &&
                        voucherTypes.filter(
                          (e) => e.name === "STUDENT_DOORM"
                        )[0].active === false
                      }
                    >
                      {LOCALE.statementForm}
                    </Button>
                  </CardActions>
                ) : (
                  <Skeleton height={45} animation="wave" />
                )}
              </Card>
            </MagicHover>
            {openStudentDoormsDialog && (
              <DialogComponent
                dialogTitle={LOCALE.student_doorms}
                closeDialog={() => closeDialog("studentDoorms")}
                contentText="contentText"
                dialogType="studentDoorms"
              ></DialogComponent>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <MagicHover>
              <Card>
                <CardActionArea
                  onClick={() => setRedirectTo("/privateAccommodation")}
                  disabled={
                    (voucherTypes &&
                      voucherTypes.filter(
                        (e) => e.name === "STUDENT_PRIVATE_ACCOMMODATION"
                      )[0].active === false) ||
                    loading
                  }
                >
                  {!loading ? (
                    <CardMedia
                      component="img"
                      alt={LOCALE.student_private_accommodation}
                      height="140"
                      image={schoolSuppliesBlue}
                      title={LOCALE.student_private_accommodation}
                    />
                  ) : (
                    <Skeleton variant="rect" height={140} animation="wave" />
                  )}
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      {!loading ? (
                        LOCALE.student_private_accommodation
                      ) : (
                        <Skeleton animation="wave" />
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ minHeight: "140px" }}
                    >
                      {!loading ? (
                        LOCALE.student_private_accommodation_h1
                      ) : (
                        <Skeleton height={140} animation="wave" />
                      )}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                {!loading ? (
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setRedirectTo("/privateAccommodation")}
                      disabled={
                        voucherTypes &&
                        voucherTypes.filter(
                          (e) => e.name === "STUDENT_PRIVATE_ACCOMMODATION"
                        )[0].active === false
                      }
                    >
                      {LOCALE.submit}
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() =>
                        setOpenStudentPrivateAccommodationDialog(true)
                      }
                      disabled={
                        voucherTypes &&
                        voucherTypes.filter(
                          (e) => e.name === "STUDENT_PRIVATE_ACCOMMODATION"
                        )[0].active === false
                      }
                    >
                      {LOCALE.statementForm}
                    </Button>
                  </CardActions>
                ) : (
                  <Skeleton height={45} animation="wave" />
                )}
              </Card>{" "}
            </MagicHover>
            {openStudentPrivateAccommodationDialog && (
              <DialogComponent
                dialogTitle={LOCALE.student_private_accommodation}
                closeDialog={() => closeDialog("studentPrivateAccommodation")}
                contentText="contentText"
                dialogType="studentPrivateAccommodation"
              ></DialogComponent>
            )}
          </Grid>
          {/* <Grid item lg={3} md={4} sm={12} xs={12}>
            <MagicHover>
              <Card>
                <CardActionArea
                  disabled={
                    (voucherTypes &&
                      voucherTypes.filter(
                        (e) => e.name === "TRANSPORT_COSTS"
                      )[0].active === false) ||
                    loading
                  }
                  onClick={() => setRedirectTo("/transportCosts")}
                >
                  {!loading ? (
                    <CardMedia
                      component="img"
                      alt={LOCALE.transportCosts}
                      height="140"
                      image={schoolSuppliesPurple}
                      title={LOCALE.transportCosts}
                    />
                  ) : (
                    <Skeleton animation="wave" variant="rect" height={140} />
                  )}
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      {!loading ? (
                        LOCALE.transportCosts
                      ) : (
                        <Skeleton animation="wave" />
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ minHeight: "140px" }}
                    >
                      {!loading ? (
                        LOCALE.transportCosts_h1
                      ) : (
                        <Skeleton height={140} animation="wave" />
                      )}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                {!loading ? (
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setRedirectTo("/transportCosts")}
                      disabled={
                        voucherTypes &&
                        voucherTypes.filter(
                          (e) => e.name === "TRANSPORT_COSTS"
                        )[0].active === false
                      }
                    >
                      {LOCALE.submit}
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => setOpenTransportCostsDialog(true)}
                      disabled={
                        voucherTypes &&
                        voucherTypes.filter(
                          (e) => e.name === "TRANSPORT_COSTS"
                        )[0].active === false
                      }
                    >
                      {LOCALE.statementForm}
                    </Button>
                  </CardActions>
                ) : (
                  <Skeleton height={45} animation="wave" />
                )}
              </Card>
            </MagicHover>
            {openTransportCostsDialog && (
              <DialogComponent
                dialogTitle={LOCALE.transportCosts}
                closeDialog={() => closeDialog("transportCosts")}
                contentText="contentText"
                dialogType="transportCosts"
              ></DialogComponent>
            )}
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <MagicHover>
              <Card>
                <CardActionArea
                  disabled={
                    (voucherTypes &&
                      voucherTypes.filter(
                        (e) => e.name === "SCHOOL_SUPPLIES"
                      )[0].active === false) ||
                    loading
                  }
                  onClick={() => setRedirectTo("/schoolSupplies")}
                >
                  {!loading ? (
                    <CardMedia
                      component="img"
                      alt={LOCALE.school_supplies}
                      height="140"
                      image={schoolSuppliesPurple}
                      title={LOCALE.school_supplies}
                    />
                  ) : (
                    <Skeleton animation="wave" variant="rect" height={140} />
                  )}
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      {!loading ? (
                        LOCALE.school_supplies
                      ) : (
                        <Skeleton animation="wave" />
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ minHeight: "140px" }}
                    >
                      {!loading ? (
                        LOCALE.school_supplies_h1
                      ) : (
                        <Skeleton animation="wave" height={140} />
                      )}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                {!loading ? (
                  <CardActions>
                    <Button
                      size="small"
                      disabled={
                        voucherTypes &&
                        voucherTypes.filter(
                          (e) => e.name === "SCHOOL_SUPPLIES"
                        )[0].active === false
                      }
                      color="primary"
                      onClick={() => setRedirectTo("/schoolSupplies")}
                    >
                      {LOCALE.submit}
                    </Button>
                    <Button
                      size="small"
                      onClick={() => setOpenSchoolSuppliesDialog(true)}
                      // disabled={
                      //   voucherTypes &&
                      //   voucherTypes.filter(
                      //     (e) => e.name === "SCHOOL_SUPPLIES"
                      //   )[0].active === false
                      // }
                      color="secondary"
                    >
                      {LOCALE.results}
                    </Button>
                  </CardActions>
                ) : (
                  <Skeleton animation="wave" height={45} />
                )}
              </Card>
            </MagicHover>
            {openSchoolSuppliesDialog && (
              <DialogComponent
                dialogTitle={LOCALE.school_supplies}
                closeDialog={() => closeDialog("schoolSupplies")}
                contentText="contentText"
                dialogType="schoolSupplies"
              ></DialogComponent>
            )}
          </Grid> */}
        </Grid>
      </Container>
      <Grid container>
        <Grid
          item
          md={12}
          style={{
            textAlign: "center",
            bottom: "0",
            left: "0",
            width: "100%",
          }}
        >
          <Typography variant="caption" color="textSecondary" gutterBottom>
            {LOCALE.footer_h1} <br></br>
            {LOCALE.footer_h2}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ position: "absolute", top: "10px", right: "20px" }}>
        {LOCALE.getAvailableLanguages().map((item, index) =>
          LOCALE.getLanguage() === item ? (
            <IconButton
              key={index}
              style={{
                fontSize: "12px",
                color: "white",
                fontWeight: "bold",
                backgroundColor: "#cfbe93",
                padding: "6px",
              }}
              size="small"
              onClick={() => handleCloseLanguage(item)}
            >
              {item.toUpperCase()}
            </IconButton>
          ) : (
            <IconButton
              key={index}
              style={{
                fontSize: "12px",
                color: "gray",
                fontWeight: "bold",
                padding: "6px",
                margin: "2px",
              }}
              size="small"
              onClick={() => handleCloseLanguage(item)}
            >
              {item.toUpperCase()}
            </IconButton>
          )
        )}
      </div>
    </div>
  );
}
