import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Slide,
} from "@material-ui/core";
import bgImg from "../../assets/img/productCurvyLines.png";
import schoolSuppliesPurple from "../../assets/img/school-supplies-sm-purple.png";
import schoolSuppliesBlue from "../../assets/img/school-supplies-sm-blue.png";

import MagicHover from "magic-hover";

export default function DoneComponent() {
  return (
    // <div style={{ backgroundImage: "url(" + bgImg + ")" }}>
    <div
      style={{
        backgroundImage: "url(" + bgImg + ")",
        backgroundSize: "100% 100%",
        backgroundAttachment: "fixed",
      }}
    >
      <Slide direction="left" in={true}>
        <Container fixed style={{ paddingTop: "5vh" }}>
          <Grid container style={{ paddingTop: "25vh", paddingBottom: "50vh" }}>
            <Grid item md={2} sm={1}></Grid>
            <Grid item md={8} sm={10} style={{ textAlign: "center" }}>
              <Icon color="secondary" style={{ fontSize: "5.5em" }}>
                check_circle_outline
              </Icon>
              <Typography variant="h3">Успешно поднесена апликација</Typography>
            </Grid>
            <Grid item md={2} sm={1}></Grid>
          </Grid>
        </Container>
      </Slide>
      <Grid container>
        <Grid
          item
          md={12}
          style={{
            textAlign: "center",
            position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
          }}
        >
          <Typography variant="caption" color="textSecondary" gutterBottom>
            © 2020 - Министерство за образование и наука <br></br>Сите права
            задржани
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
