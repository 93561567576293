import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import {
  TablePagination,
  TableRow,
  TableFooter,
  Icon,
  IconButton,
  Tooltip,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Button,
  Chip,
  Grid,
} from "@material-ui/core";
// @material-ui/styles
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { makeStyles } from "@material-ui/core/styles";
import { ObjectionRepository } from "../eVoucher/ObjectionRepository";
import { LOCALE } from "../../properties/Locale";
import CardHeader from "../../common/components/Card/CardHeader";
import Card from "../../common/components/Card/Card";
import CardBody from "../../common/components/Card/CardBody";
import { changePageTitle } from "../../common/actions/CommonActions";
import styles from "../../assets/jss/components/tableStyle";
import { SETTINGS } from "../../properties/ApiProps";

const useStyles = makeStyles(styles);

export default function VoucherAppTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Table>
        {props.data && (
          <React.Fragment>
            <TableRow>
              <TableCell>{LOCALE.applicant}</TableCell>
              <TableCell>{props.data.nameSurname}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{LOCALE.embg}</TableCell>
              <TableCell>{props.data.embg}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{LOCALE.addressLine}</TableCell>
              <TableCell>{props.data.addressLine}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{LOCALE.email}</TableCell>
              <TableCell>{props.data.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{LOCALE.status}</TableCell>
              <TableCell>{props.data.status}</TableCell>
            </TableRow>
          </React.Fragment>
        )}
        {props.data.telephone && (
          <TableRow>
            <TableCell>{LOCALE.telephone}</TableCell>
            <TableCell>{props.data.telephone}</TableCell>
          </TableRow>
        )}
        {props.data.municipality && (
          <TableRow>
            <TableCell>{LOCALE.municipality}</TableCell>
            <TableCell>{props.data.municipality}</TableCell>
          </TableRow>
        )}
        {props.data.school && (
          <TableRow>
            <TableCell>{LOCALE.school}</TableCell>
            <TableCell>{props.data.school}</TableCell>
          </TableRow>
        )}
        {props.data.idNumber && (
          <TableRow>
            <TableCell>{LOCALE.idNumber}</TableCell>
            <TableCell>{props.data.idNumber}</TableCell>
          </TableRow>
        )}
        {props.data.idAuthority && (
          <TableRow>
            <TableCell>{LOCALE.idAuthority}</TableCell>
            <TableCell>{props.data.idAuthority}</TableCell>
          </TableRow>
        )}
        {props.data.idDateOfExpiry && (
          <TableRow>
            <TableCell>{LOCALE.idDateOfExpiry}</TableCell>
            <TableCell>
              {moment(props.data.idDateOfExpiry).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
        )}
        {props.data.faculty && (
          <TableRow>
            <TableCell>{LOCALE.faculty}</TableCell>
            <TableCell>{props.data.faculty}</TableCell>
          </TableRow>
        )}
        {props.data.yearsOfStudy && (
          <TableRow>
            <TableCell>{LOCALE.yearsOfStudy}</TableCell>
            <TableCell>{props.data.yearsOfStudy}</TableCell>
          </TableRow>
        )}
        {props.data.studentDoorm && (
          <TableRow>
            <TableCell>{LOCALE.studentDoorm}</TableCell>
            <TableCell>{props.data.studentDoorm}</TableCell>
          </TableRow>
        )}
        {props.data.proofsWay && (
          <TableRow>
            <TableCell>{LOCALE.proofsWay}</TableCell>
            <TableCell>{LOCALE.getString(props.data.proofsWay)}</TableCell>
          </TableRow>
        )}
        {props.data.proofs &&
          props.data.proofs.map((attachment) => (
            <TableRow>
              <TableCell>{LOCALE.getString(attachment.fileType)}</TableCell>
              <TableCell>
                <a
                  target="blank"
                  href={`${SETTINGS.API_URL}voucherRequest/attachments/${attachment.id}`}
                >
                  <IconButton
                    size="small"
                    color="secondary"
                    aria-label="Download"
                  >
                    <Icon>cloud_download</Icon>
                  </IconButton>
                </a>
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </React.Fragment>
  );
}
