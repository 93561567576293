import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import {
  TablePagination,
  TableRow,
  TableFooter,
  Icon,
  IconButton,
  Tooltip,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Button,
  Grid,
} from "@material-ui/core";
// @material-ui/styles
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/components/tableStyle";
import { LOCALE } from "../../../properties/Locale";
import { changePageTitle } from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import { MonitoringRepository } from "./MonitoringRepo";
import prettyBytes from "pretty-bytes";

const useStyles = makeStyles(styles);

export default function MonitoringComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [metrics, setMetrics] = useState();
  const globalState = useSelector((state) => state);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.monitoring));
    var metricsIntervalObject = setInterval(() => loadMetrics(), 3500);
    return () => clearInterval(metricsIntervalObject);
  }, []);

  const loadMetrics = () => {
    MonitoringRepository.fetchMetrics().then((res) => {
      setMetrics(res.data);
    });
  };

  return (
    <div>
      <Card>
        <CardHeader color="theme">{LOCALE.monitoring}</CardHeader>
        <CardBody>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Table className="table-stripped">
                <TableBody>
                  <TableRow>
                    <TableCell>{LOCALE.total_memory}</TableCell>
                    <TableCell>
                      {metrics && prettyBytes(metrics.mem * 1000)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.memory_free}</TableCell>
                    <TableCell>
                      {metrics && prettyBytes(metrics["mem.free"] * 1000)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.memory_used}</TableCell>
                    <TableCell>
                      {metrics &&
                        prettyBytes(
                          metrics["mem"] * 1000 - metrics["mem.free"] * 1000
                        )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.uptime}</TableCell>
                    <TableCell>
                      {metrics && Math.round(metrics["uptime"] / 1000)}
                      {"s"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.systemload_average}</TableCell>
                    <TableCell>
                      {metrics && metrics["systemload.average"]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.datasource_primary_active}</TableCell>
                    <TableCell>
                      {metrics && metrics["datasource.primary.active"]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.datasource_primary_usage}</TableCell>
                    <TableCell>
                      {metrics && metrics["datasource.primary.usage"] * 100}
                      {"%"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.http_sesssions_active}</TableCell>
                    <TableCell>
                      {metrics && metrics["httpsessions.active"]}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={6}>
              <Table className="table-stripped">
                <TableBody>
                  <TableRow>
                    <TableCell>{LOCALE.heap_commited}</TableCell>
                    <TableCell>
                      {metrics && prettyBytes(metrics["heap.committed"] * 1000)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.heap_init}</TableCell>
                    <TableCell>
                      {metrics && prettyBytes(metrics["heap.init"] * 1000)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.heap_used}</TableCell>
                    <TableCell>
                      {metrics && prettyBytes(metrics["heap.used"] * 1000)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.heap}</TableCell>
                    <TableCell>
                      {metrics && prettyBytes(metrics["heap"] * 1000)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.nonheap_committed}</TableCell>
                    <TableCell>
                      {metrics &&
                        prettyBytes(metrics["nonheap.committed"] * 1000)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.nonheap_init}</TableCell>
                    <TableCell>
                      {metrics && prettyBytes(metrics["nonheap.init"] * 1000)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.nonheap_used}</TableCell>
                    <TableCell>
                      {metrics && prettyBytes(metrics["nonheap.used"] * 1000)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.nonheap}</TableCell>
                    <TableCell>
                      {metrics && prettyBytes(metrics["nonheap"] * 1000)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </div>
  );
}
