import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { Dialog, IconButton, Icon } from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { UsersRepository } from "./UsersRepository";
import { LOCALE } from "../../../properties/Locale";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import AdministratorFormComponent from "./forms/AdministratorFormComponent";
import CommissionFormComponent from "./forms/CommissionFormComponent";

const useStyles = makeStyles(styles);

export default function UserEditComponent(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    loadUser(props.match.params.userId);
  }, []);

  const loadUser = (id) => {
    showLoadingBar();
    UsersRepository.fetchUser(id).then((res) => {
      setData(res.data);
      hideLoadingBar();
    });
  };

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const handleClose = () => {};

  return (
    <div>
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.user_edit}${
                data?.username ? ": " + data?.username : ""
              }`}

              <Link to={`/app/admin/users/`} style={{ textDecoration: "none" }}>
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {data?.type === "ADMINISTRATOR" && (
              <div>
                <AdministratorFormComponent
                  data={data}
                  disabled={false}
                  action={"edit"}
                  showLoadingBar={showLoadingBar}
                  hideLoadingBar={hideLoadingBar}
                />
              </div>
            )}
            {data?.type === "COMMISSION_USER" && (
              <div>
                <CommissionFormComponent
                  data={data}
                  disabled={false}
                  action={"edit"}
                  showLoadingBar={showLoadingBar}
                  hideLoadingBar={hideLoadingBar}
                />
              </div>
            )}
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
