import { LOCALE } from "./Locale";

export const routes = [
  {
    name: LOCALE.eVoucherApp,
    url: "/app/schoolSupplies/",
    icon: "cast-for-education",
    position: "1",
    hasAccess: ["ROLE_COMMISSION_SCHOOL_SUPPLIE"],
  },
  {
    name: LOCALE.eVoucherApp,
    url: "/app/studyCosts",
    icon: "cast-for-education",
    position: "1",
    hasAccess: ["ROLE_COMMISSION_STUDY_COSTS"],
  },
  {
    name: LOCALE.eVoucherApp,
    url: "/app/transportCosts",
    icon: "cast-for-education",
    position: "1",
    hasAccess: ["ROLE_COMMISSION_TRANSPORT_COSTS"],
  },
  {
    name: LOCALE.eVoucherApp,
    url: "/app/studentsDoorms",
    icon: "cast-for-education",
    position: "1",
    hasAccess: ["ROLE_COMMISSION_STUDENTS_DOORMS"],
  },
  {
    name: LOCALE.eVoucherApp,
    url: "/app/privateAccommodation",
    icon: "cast-for-education",
    position: "1",
    hasAccess: ["ROLE_COMMISSION_PRIVATE_ACCOMMODATION"],
  },
  {
    name: LOCALE.dashboard,
    url: "/app/dashboard",
    icon: "dashboard",
    position: "1",
    hasAccess: ["ROLE_ADMINISTRATION"],
  },
  {
    name: LOCALE.monitoring,
    url: "/app/admin/monitoring",
    icon: "power",
    position: "1",
    hasAccess: ["ROLE_ADMINISTRATION"],
  },
  {
    name: LOCALE.objections,
    url: "/app/objectionReviews",
    icon: "emoji_people",
    position: "1",
    hasAccess: ["ROLE_OBJECTIONS"],
  },
  {
    name: LOCALE.charts,
    url: "/app/charts",
    icon: "dashboard",
    position: "1",
    hasAccess: ["ROLE_ADMINISTRATION"],
  },
  {
    name: LOCALE.administration,
    icon: "settings",
    hasAccess: ["ROLE_ADMINISTRATION"],
    children: [
      {
        name: LOCALE.users,
        url: "/app/admin/users",
        icon: "person",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.groups,
        url: "/app/admin/groups",
        icon: "groups",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.privileges,
        url: "/app/admin/privileges",
        icon: "report",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.settings,
        url: "/app/admin/settings",
        icon: "settings",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.audit_log,
        url: "/app/admin/auditLog",
        icon: "track_changes",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.mail_log,
        url: "/app/admin/mailLog",
        icon: "all_inbox",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.mail_template,
        url: "/app/admin/mailTemplate",
        icon: "square_foot",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
    ],
  },
  {
    name: LOCALE.configuration,
    icon: "list",
    hasAccess: ["ROLE_ADMINISTRATION"],
    children: [
      // {
      //   name: LOCALE.municipalities,
      //   url: "/app/configuration/municipalities",
      //   icon: "apartment",
      //   position: "2",
      //   hasAccess: ["ROLE_ADMINISTRATION"],
      // },
      // {
      //   name: LOCALE.schools,
      //   url: "/app/configuration/schools",
      //   icon: "school",
      //   position: "2",
      //   hasAccess: ["ROLE_ADMINISTRATION"],
      // },
      {
        name: LOCALE.modules,
        url: "/app/eVoucher",
        icon: "settings",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
    ],
  },
];
