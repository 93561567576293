import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const studentPrivateAccommodationFormValidationEl = yup.object().shape({
  studentPrivateAccommodationVoucherRequest: yup.object().shape({
    nameSurname: yup
      .string()
      .required(LOCALE.nameSurname + LOCALE.required_field),
    embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
    addressLine: yup
      .string()
      .required(LOCALE.addressLine + LOCALE.required_field),
    email: yup
      .string()
      .required(LOCALE.email + LOCALE.required_field)
      .email(LOCALE.email + LOCALE.invalid_email_address_format),
    faculty: yup.string().required(LOCALE.faculty + LOCALE.required_field),
    yearsOfStudy: yup
      .string()
      .required(LOCALE.yearsOfStudy + LOCALE.required_field),
  }),
  file1: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file2: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file3: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file4: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file7: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file10: yup.array().required(LOCALE.proof + LOCALE.required_field),
});

export const studentPrivateAccommodationFormValidationElYearsOfStudy = yup
  .object()
  .shape({
    studentPrivateAccommodationVoucherRequest: yup.object().shape({
      nameSurname: yup
        .string()
        .required(LOCALE.nameSurname + LOCALE.required_field),
      embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
      addressLine: yup
        .string()
        .required(LOCALE.addressLine + LOCALE.required_field),
      email: yup
        .string()
        .required(LOCALE.email + LOCALE.required_field)
        .email(LOCALE.email + LOCALE.invalid_email_address_format),
      faculty: yup.string().required(LOCALE.faculty + LOCALE.required_field),
      yearsOfStudy: yup
        .string()
        .required(LOCALE.yearsOfStudy + LOCALE.required_field),
    }),
    file1: yup.array().required(LOCALE.proof + LOCALE.required_field),
    file2: yup.array().required(LOCALE.proof + LOCALE.required_field),
    file3: yup.array().required(LOCALE.proof + LOCALE.required_field),
    file4: yup.array().required(LOCALE.proof + LOCALE.required_field),
    file7: yup.array().required(LOCALE.proof + LOCALE.required_field),
    file10: yup.array().required(LOCALE.proof + LOCALE.required_field),
    file5: yup.array().required(LOCALE.proof + LOCALE.required_field),
    file6: yup.array().required(LOCALE.proof + LOCALE.required_field),
  });

export const studentPrivateAccommodationFormValidation = yup.object().shape({
  studentPrivateAccommodationVoucherRequest: yup.object().shape({
    nameSurname: yup
      .string()
      .required(LOCALE.nameSurname + LOCALE.required_field),
    embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
    addressLine: yup
      .string()
      .required(LOCALE.addressLine + LOCALE.required_field),
    email: yup.string().required(LOCALE.email + LOCALE.required_field),
    faculty: yup.string().required(LOCALE.faculty + LOCALE.required_field),
    yearsOfStudy: yup
      .string()
      .required(LOCALE.yearsOfStudy + LOCALE.required_field),
  }),
});
