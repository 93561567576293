import React, { useState } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  IconButton,
  Icon,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { LOCALE } from "../../../properties/Locale";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import AdministratorFormComponent from "./forms/AdministratorFormComponent";
import CommissionFormComponent from "./forms/CommissionFormComponent";

const useStyles = makeStyles(styles);

export default function UserCreateComponent() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [roleType, setRoleType] = useState("ADMINISTRATOR");

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const handleClose = () => {};
  return (
    <div>
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.create_new_user}`}
              <Link to={`/app/admin/users/`} style={{ textDecoration: "none" }}>
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            <DialogContent>
              {loading ? <LinearProgress color="secondary" /> : ""}
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                size="small"
              >
                <InputLabel>{LOCALE.role}</InputLabel>
                <Select
                  label={LOCALE.role}
                  onChange={(e) => setRoleType(e.target.value)}
                  defaultValue="ADMINISTRATOR"
                >
                  <MenuItem value="ADMINISTRATOR">
                    {LOCALE.administrator}
                  </MenuItem>
                  <MenuItem value="COMMISSION_USER">
                    {LOCALE.commission}
                  </MenuItem>
                </Select>
              </FormControl>
              {roleType === "ADMINISTRATOR" && (
                <div>
                  <AdministratorFormComponent
                    disabled={false}
                    action={"create"}
                    showLoadingBar={showLoadingBar}
                    hideLoadingBar={hideLoadingBar}
                  />
                </div>
              )}
              {roleType === "COMMISSION_USER" && (
                <div>
                  <div>
                    <CommissionFormComponent
                      disabled={false}
                      action={"create"}
                      showLoadingBar={showLoadingBar}
                      hideLoadingBar={hideLoadingBar}
                    />
                  </div>
                </div>
              )}
            </DialogContent>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
