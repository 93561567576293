import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Button,
  IconButton,
  Paper,
  TextField,
  Slide,
  makeStyles,
  Backdrop,
} from "@material-ui/core";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect } from "react-router-dom";
import { LOCALE } from "../../properties/Locale";
import { ReCaptcha } from "react-recaptcha-v3";
import { loadReCaptcha } from "react-recaptcha-v3";
import { objectionsFormValidation } from "./ObjectionFormValidator";
import { ObjectionRepository } from "./ObjectionRepository";
import HashLoader from "react-spinners/HashLoader";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffff",
  },
}));

export default function ObjectionComponent() {
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = React.useState();
  const [formData, setFormData] = React.useState({
    nameSurname: "",
    embg: "",
    email: "",
    phone: "",
    description: "",
  });
  const [rerender, triggerRerender] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState();
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [recaptcha, setRecaptcha] = React.useState();
  const [recaptchaToken, setRecaptchaToken] = React.useState();

  useEffect(() => {
    loadReCaptcha("6LcyOa4ZAAAAAEJeOd51mtNcrGMtOY6pGO4tybh5", verifyCallback);
  }, []);

  const verifyCallback = (recaptchaToken) => {
    setRecaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    recaptcha.execute();
  };

  const handleChange = (event, name) => {
    let data = formData;
    data[name] = event.target.value;
    setFormData(data);
    triggerRerender(!rerender);
  };

  const handleSubmit = () => {
    setBtnDisabled(true);
    updateToken();

    var validFormData = true;

    if (!objectionsFormValidation.isValidSync(formData)) {
      validFormData = false;
    }

    if (validFormData) {
      ObjectionRepository.createObjection(formData, recaptchaToken).then(
        (res) => {
          setRedirectTo("/done");
        },
        (err) => {
          if (err.response?.data) {
            setFormErrors(err.response.data);
          }
          setBtnDisabled(false);
          updateToken();
        }
      );
    } else {
      var validationErrors = {};
      (async () => {
        await objectionsFormValidation
          .validate(formData, { abortEarly: false })
          .catch((err) => {
            err.inner.forEach((validationError) => {
              validationErrors[validationError.path] = {};
              validationErrors[validationError.path] = validationError.message;
            });
          });
        setBtnDisabled(false);
        setFormErrors(validationErrors);
      })();
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(" + bgImg + ")",
        backgroundSize: "100% 100%",
        backgroundAttachment: "fixed",
      }}
    >
      <Backdrop open={btnDisabled} className={classes.backdrop}>
        <div style={{ textAlign: "center" }}>
          <HashLoader
            size={100}
            color={"#fff"}
            loading={btnDisabled}
            css="margin:30px"
          />
          <Typography>{LOCALE.please_wait}</Typography>
        </div>
      </Backdrop>
      {redirectTo && <Redirect to={redirectTo} />}
      <Slide direction="left" in={true}>
        <Container fixed style={{ paddingTop: "5vh" }}>
          <Grid container>
            <Grid item md={3} sm={1}></Grid>
            <Grid item md={6} sm={10}>
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{ textAlign: "center" }}
              >
                {LOCALE.submit_objection}
              </Typography>
            </Grid>
            <Grid item md={3} sm={1}></Grid>
          </Grid>
          <Grid container>
            <Grid item md={1}>
              <IconButton onClick={() => setRedirectTo("/")}>
                <Icon>arrow_back_ios</Icon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={2} sm={1}></Grid>
            <Grid item md={8} sm={10}>
              <Paper style={{ backgroundColor: "rgba(255, 255, 255, 0.65)" }}>
                <Grid container spacing={2}>
                  <Grid item md={2} sm={1}></Grid>
                  <Grid item md={8} sm={10}>
                    <Typography
                      variant="subtitle1"
                      style={{ textAlign: "center" }}
                      gutterBottom
                    >
                      {LOCALE.basic_info}
                    </Typography>
                    <Grid container spacing={1}>
                      <ReCaptcha
                        ref={(ref) => setRecaptcha(ref)}
                        sitekey="6LcyOa4ZAAAAAEJeOd51mtNcrGMtOY6pGO4tybh5"
                        action="request"
                        verifyCallback={verifyCallback}
                      />
                      <Grid item md={6} xs={12}>
                        <TextField
                          label={LOCALE.nameSurname}
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData.nameSurname}
                          onChange={(e) => handleChange(e, "nameSurname")}
                          error={
                            formErrors?.nameSurname ||
                            (formErrors && formErrors["nameSurname"])
                          }
                          helperText={
                            formErrors?.nameSurname ||
                            (formErrors && formErrors["nameSurname"])
                          }
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {" "}
                        <TextField
                          label={LOCALE.embg}
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData.embg}
                          onChange={(e) => handleChange(e, "embg")}
                          placeholder="XXXXXXXXXXXXX"
                          error={
                            formErrors?.embg ||
                            (formErrors && formErrors["embg"])
                          }
                          helperText={
                            (formErrors?.embg &&
                              LOCALE.getString(formErrors.embg)) ||
                            (formErrors && formErrors["embg"])
                          }
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      label={LOCALE.email}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="dense"
                      value={formData.email}
                      onChange={(e) => handleChange(e, "email")}
                      error={
                        formErrors?.email || (formErrors && formErrors["email"])
                      }
                      helperText={
                        formErrors?.email || (formErrors && formErrors["email"])
                      }
                    />
                    <TextField
                      label={LOCALE.telephone}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="dense"
                      value={formData.phone}
                      onChange={(e) => handleChange(e, "phone")}
                      error={
                        formErrors?.phone || (formErrors && formErrors["phone"])
                      }
                      helperText={
                        formErrors?.phone || (formErrors && formErrors["phone"])
                      }
                    />
                    <Grid container spacing={1}>
                      <Grid item md={12} sm={12} xs={12}>
                        {" "}
                        <TextField
                          label={LOCALE.objection}
                          fullWidth
                          size="small"
                          variant="outlined"
                          multiline
                          rows={8}
                          margin="dense"
                          value={formData?.description}
                          onChange={(e) => handleChange(e, "description")}
                          error={
                            formErrors?.description ||
                            (formErrors && formErrors["description"])
                          }
                          helperText={
                            formErrors?.description ||
                            (formErrors && formErrors["description"])
                          }
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    <Button
                      disabled={btnDisabled}
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => handleSubmit()}
                      fullWidth
                    >
                      {LOCALE.submit_objection}
                    </Button>
                  </Grid>
                  <Grid item md={2} sm={1}></Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={2} sm={1}></Grid>
          </Grid>
          <br></br>
          <br></br>
        </Container>
      </Slide>
      <Grid container>
        <Grid
          item
          md={12}
          style={{
            textAlign: "center",
            bottom: "0",
            left: "0",
            width: "100%",
          }}
        >
          <Typography variant="caption" color="textSecondary" gutterBottom>
            {LOCALE.footer_h1} <br></br>
            {LOCALE.footer_h2}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
