import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Tooltip,
  IconButton,
  Chip,
  TableFooter,
  TablePagination,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/tableStyle";
import { LOCALE } from "../../properties/Locale";
import { useDispatch } from "react-redux";
import { changePageTitle } from "../../common/actions/CommonActions";
import { EvoucherAppRepository } from "./EvoucherAppRepository";
import Card from "../../common/components/Card/Card";
import CardHeader from "../../common/components/Card/CardHeader";
import CardBody from "../../common/components/Card/CardBody";
import moment from "moment";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function SchoolSuppliesComponent() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState({
    totalElements: 0,
    number: 0,
    content: [],
  });
  const [redirect, setRedirect] = useState();
  const [embg, setEmbg] = useState();
  const [status, setStatus] = useState();
  const [nameFilter, setNameFilter] = useState();

  useEffect(() => {
    fetchData(page, size, "dateCreated", "DESC", null, null);
    dispatch(changePageTitle(LOCALE.eVoucherApp));
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, size, "dateCreated", "DESC", null, null);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    fetchData(
      page,
      parseInt(event.target.value, 10),
      "dateCreated",
      "DESC",
      null,
      null
    );
  };

  const fetchData = (
    page,
    size,
    orderBy,
    orderDirection,
    filterBy,
    filterValue
  ) => {
    EvoucherAppRepository.getAllSchoolSupplies({
      page: page,
      size: size,
      orderBy: orderBy,
      orderDirection: orderDirection,
      filterBy: filterBy,
      filterValue: filterValue,
    }).then((res) => {
      setData(res.data);
    });
  };

  const filterByEmbg = (embg) => {
    setEmbg(embg);
    if (embg.length > 12) {
      fetchData(page, size, "dateCreated", "DESC", "EMBG", embg);
    } else if (embg.length === 0) {
      fetchData(page, size, "dateCreated", "DESC");
    }
  };

  const filterByStatus = (status) => {
    setStatus(status);
    if (status == "ALL") {
      fetchData(page, size, "dateCreated", "DESC");
    } else {
      fetchData(page, size, "dateCreated", "DESC", "STATUS", status);
    }
  };

  const filterByNameSurname = (nameSurname) => {
    setNameFilter(nameSurname);
    if (nameSurname.length === 0) {
      fetchData(page, size, "dateCreated", "DESC");
    } else {
      fetchData(page, size, "dateCreated", "DESC", "nameSurname", nameSurname);
    }
  };

  return (
    <div>
      {redirect && <Redirect to={redirect} />}
      <React.Fragment>
        <Card>
          <CardHeader color="theme">
            <Typography>{LOCALE.apps_for_evoucher}</Typography>
          </CardHeader>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={2} md={2}>
                <FormControl
                  size="small"
                  fullWidth
                  label={LOCALE.status}
                  style={{ marginTop: "3px" }}
                >
                  <InputLabel>{LOCALE.status}</InputLabel>
                  <Select
                    label={LOCALE.status}
                    onChange={(e) => filterByStatus(e.target.value)}
                    defaultValue="ALL"
                  >
                    <MenuItem value={"ALL"}>{LOCALE.all}</MenuItem>
                    <MenuItem value={"APPROVED"}>{LOCALE.approved}</MenuItem>
                    <MenuItem value={"REJECTED"}>{LOCALE.rejected}</MenuItem>
                    <MenuItem value={"PENDING"}>{LOCALE.pending}</MenuItem>
                    <MenuItem value={"PROCESSING"}>
                      {LOCALE.in_processing}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} md={2}>
                <TextField
                  fullWidth
                  label={LOCALE.nameSurname}
                  value={nameFilter}
                  onChange={(e) => filterByNameSurname(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={2} md={2}>
                <TextField
                  fullWidth
                  label={LOCALE.embg}
                  value={embg}
                  onChange={(e) => filterByEmbg(e.target.value.trim())}
                ></TextField>
              </Grid>
            </Grid>
            <Table aria-label="users table" className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    {LOCALE.firstLastName}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    {LOCALE.email}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    {LOCALE.school}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    {LOCALE.date_created}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    {LOCALE.status}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    style={{
                      width: "100px",
                      fontWeight: "bold",
                    }}
                  >
                    {LOCALE.actions}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Icon
                        style={{ verticalAlign: "bottom", color: "orange" }}
                      >
                        warning
                      </Icon>
                      {LOCALE.no_apps_for_evaluating}
                    </TableCell>
                  </TableRow>
                )}
                {data?.content?.map((eVoucherApp, index) => (
                  <TableRow
                    key={eVoucherApp.id}
                    style={index % 2 === 1 ? { background: "#f0f0f0" } : {}}
                  >
                    <TableCell className={classes.tableCell}>
                      {eVoucherApp.nameSurname}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {eVoucherApp.email}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {eVoucherApp.school}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {eVoucherApp.dateCreated &&
                        moment(eVoucherApp.dateCreated).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {eVoucherApp.status === "PENDING" ? (
                        <Chip
                          label="PENDING"
                          size="small"
                          style={{
                            backgroundColor: "darkorange",
                            color: "white",
                          }}
                        />
                      ) : eVoucherApp.status === "PROCESSING" ? (
                        <Chip label="PROCESSING" size="small" color="primary" />
                      ) : eVoucherApp.status === "APPROVED" ? (
                        <Chip
                          label="APPROVED"
                          size="small"
                          style={{
                            backgroundColor: "green",
                            color: "white",
                          }}
                        />
                      ) : eVoucherApp.status === "REJECTED" ? (
                        <Chip label="REJECTED" size="small" color="secondary" />
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Tooltip
                        title={LOCALE.details}
                        aria-label={LOCALE.details}
                        style={{ color: "white" }}
                      >
                        <Link
                          to={`/app/schoolSuppliesEval/${eVoucherApp.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <IconButton
                            size="small"
                            color="primary"
                            aria-label="details"
                          >
                            <Icon>assignment_ind</Icon>
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={6}
                    count={data.totalElements}
                    rowsPerPage={size}
                    page={data.number}
                    labelRowsPerPage={LOCALE.rows_per_page}
                    SelectProps={{
                      inputProps: { "aria-label": LOCALE.rows_per_page },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </CardBody>
        </Card>
      </React.Fragment>
    </div>
  );
}
