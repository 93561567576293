import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const transportCostsFormValidationEl = yup.object().shape({
  transportCostsVoucherRequest: yup.object().shape({
    nameSurname: yup
      .string()
      .required(LOCALE.nameSurname + LOCALE.required_field),
    embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
    addressLine: yup
      .string()
      .required(LOCALE.addressLine + LOCALE.required_field),
    email: yup
      .string()
      .required(LOCALE.email + LOCALE.required_field)
      .email(LOCALE.email + LOCALE.invalid_email_address_format),
  }),
  file4: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file8: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file9: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file10: yup.array().required(LOCALE.proof + LOCALE.required_field),
});

export const transportCostsFormValidation = yup.object().shape({
  transportCostsVoucherRequest: yup.object().shape({
    nameSurname: yup
      .string()
      .required(LOCALE.nameSurname + LOCALE.required_field),
    embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
    addressLine: yup
      .string()
      .required(LOCALE.addressLine + LOCALE.required_field),
    email: yup.string().required(LOCALE.email + LOCALE.required_field),
  }),
});
