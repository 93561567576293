import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const EVoucherAdministrationRepository = {
  getVoucherTypes: () => {
    return Axios({
      url: SETTINGS.API_URL + "/voucherType",
      method: "GET",
    });
  },
  saveVoucherType: (voucherType) => {
    return Axios({
      url: SETTINGS.API_URL + "/voucherType",
      method: "PUT",
      data: voucherType,
    });
  },
};
