import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core";
// @material-ui/styles
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockIcon from "@material-ui/icons/Lock";
// core components
import { UsersRepository } from "../UsersRepository";
import { LOCALE } from "../../../../properties/Locale";
import EntityMetadata from "../../../../common/components/EntityMetadata";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  triggerRerender,
} from "../../../../common/actions/CommonActions";
import { administratorCreateUserForm } from "./Validations";

export default function AdministratorFormComponent(props) {
  const [disabled] = useState(props.disabled);
  const [action] = useState(props.action);
  const [showPassword, setShowPassword] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const {
    handleSubmit: userForm,
    register: registerForm,
    errors: errorsUser,
    setValue,
    getValues,
    control,
  } = useForm({ validationSchema: administratorCreateUserForm });
  const dispatch = useDispatch();

  useEffect(() => {
    if (action != "create") {
      loadUser();
    }
  }, []);

  const onSubmit = (values) => {
    values.name = values.firstName + " " + values.lastName;
    if (action == "create") {
      values.type = "ADMINISTRATOR";
      props.showLoadingBar();
      UsersRepository.createUser(values)
        .then((res) => {
          props.hideLoadingBar();
          dispatch(triggerRerender());
          dispatch(
            notifyShowSuccessMessage(LOCALE.user_create_success_message)
          );
          setRedirectTo("/app/admin/users");
        })
        .catch((err) => {
          props.hideLoadingBar();
          if (err.response?.data?.message) {
            dispatch(notifyShowErrorMessage(err.response?.data?.message));
          } else {
            dispatch(notifyShowErrorMessage(err.message));
          }
        });
    } else {
      props.showLoadingBar();
      values.name = values.firstName + " " + values.lastName;
      UsersRepository.updateUser(values)
        .then((res) => {
          props.hideLoadingBar();
          dispatch(triggerRerender());
          dispatch(
            notifyShowSuccessMessage(LOCALE.user_update_success_message)
          );
          if (props.form == "myProfile") {
            setRedirectTo("/app/profile");
          } else {
            setRedirectTo("/app/admin/users");
          }
        })
        .catch((err) => {
          props.hideLoadingBar();
          dispatch(notifyShowErrorMessage(err.message));
        });
    }
  };

  const loadUser = () => {
    Object.keys(props.data).map(function (item) {
      if (Object.keys(getValues()).indexOf(item) == -1) {
        registerForm({ name: item, type: "custom" });
      }
    });
    Object.entries(props.data).map(function (item) {
      setValue(item[0], item[1]);
    });

    if (props.data.name) {
      let name = props.data.name.split(" ");
      setValue("firstName", name[0]);
      setValue("lastName", name[1]);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = () => {
    UsersRepository.resetPassword(getValues())
      .then((res) => {
        props.hideLoadingBar();
        dispatch(triggerRerender());
        dispatch(notifyShowSuccessMessage(LOCALE.user_update_success_message));
        if (props.form == "myProfile") {
          setRedirectTo("/app/profile");
        } else {
          setRedirectTo("/app/admin/users");
        }
      })
      .catch((err) => {
        props.hideLoadingBar();
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <div>
        <form onSubmit={userForm(onSubmit)}>
          <Grid container>
            {action != "create" && (
              <Grid item md={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                >
                  <InputLabel>{LOCALE.role}</InputLabel>
                  <Controller
                    as={
                      <Select label={LOCALE.role}>
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="ADMINISTRATOR">
                          {LOCALE.administrator}
                        </MenuItem>
                        <MenuItem value="COMMISSION_USER">
                          {LOCALE.commission}
                        </MenuItem>
                      </Select>
                    }
                    name="type"
                    control={control}
                    defaultValue=""
                    disabled
                  />
                </FormControl>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  id="firstName"
                  label={LOCALE.firstName}
                  name="firstName"
                  fullWidth={true}
                  size="small"
                  margin="dense"
                  variant="outlined"
                  inputRef={registerForm}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    disabled: disabled,
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={errorsUser?.firstName}
                  helperText={errorsUser?.firstName?.message}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  id="lastName"
                  label={LOCALE.lastName}
                  name="lastName"
                  fullWidth={true}
                  size="small"
                  margin="dense"
                  variant="outlined"
                  inputRef={registerForm}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    disabled: disabled,
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={errorsUser?.lastName}
                  helperText={errorsUser?.lastName?.message}
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <TextField
                id="email"
                label={LOCALE.email}
                fullWidth={true}
                size="small"
                name="email"
                margin="dense"
                variant="outlined"
                inputRef={registerForm}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  disabled: disabled,
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                error={errorsUser?.email}
                helperText={errorsUser?.email?.message}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                id="id"
                label={LOCALE.username}
                fullWidth={true}
                size="small"
                name="username"
                margin="dense"
                variant="outlined"
                inputRef={registerForm}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  disabled: disabled || action != "create",
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                error={errorsUser?.username}
                helperText={errorsUser?.username?.message}
              />
            </Grid>
            {action === "create" && (
              <Grid item md={12}>
                <TextField
                  id="password"
                  type={showPassword ? "text" : "password"}
                  label={LOCALE.password}
                  size="small"
                  fullWidth={true}
                  margin="dense"
                  variant="outlined"
                  name="password"
                  inputRef={registerForm}
                  InputProps={{
                    disabled: disabled,
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={errorsUser?.password}
                  helperText={errorsUser?.password?.message}
                />
              </Grid>
            )}
            {action === "edit" && (
              <Grid item md={12}>
                <ExpansionPanel margin="dense">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{LOCALE.change_password}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={7}>
                        <TextField
                          id="password"
                          type={showPassword ? "text" : "password"}
                          label={LOCALE.password}
                          size="small"
                          fullWidth={true}
                          margin="dense"
                          variant="outlined"
                          name="password"
                          inputRef={registerForm}
                          InputProps={{
                            disabled: disabled,
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={errorsUser?.password}
                          helperText={errorsUser?.password?.message}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          fullWidth={true}
                          style={{ verticalAlign: "middle", marginTop: "9px" }}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleSubmit()}
                        >
                          {LOCALE.save}
                        </Button>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            )}
          </Grid>
          <EntityMetadata data={getValues()} />
          <DialogActions>
            {!disabled && (
              <Link to={`/app/admin/users/`} style={{ textDecoration: "none" }}>
                <Button size="small" variant="outlined" color="secondary">
                  {LOCALE.cancel}
                </Button>
              </Link>
            )}
            {action != "details" && (
              <Button
                type="submit"
                size="small"
                variant="contained"
                disabled={disabled}
                className={disabled ? "btn-loading" : ""}
                color="primary"
              >
                {LOCALE.save}
                {disabled && (
                  <CircularProgress
                    color="secondary"
                    size={20}
                    style={{ position: "absolute" }}
                  />
                )}
              </Button>
            )}
          </DialogActions>
        </form>
      </div>
    </div>
  );
}
