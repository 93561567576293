import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Button,
  IconButton,
  Paper,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  Radio,
  Slide,
  TableBody,
  TableRow,
  TableCell,
  Table,
  FormControl,
  FormHelperText,
  makeStyles,
  Backdrop,
} from "@material-ui/core";
import bgImg from "../../assets/img/productCurvyLines.png";

import { Redirect } from "react-router-dom";
import Upload from "../../common/components/Upload";
import { VoucherRequestRepository } from "./voucherRequestRepository";

import { LOCALE } from "../../properties/Locale";
import { ReCaptcha } from "react-recaptcha-v3";
import { loadReCaptcha } from "react-recaptcha-v3";
import {
  studyCostsFormValidationEl,
  studyCostsFormValidation,
  studyCostsFormValidationElYearsOfStudy,
} from "./studyCostsFormValidator";
import {
  studentPrivateAccommodationFormValidation,
  studentPrivateAccommodationFormValidationEl,
  studentPrivateAccommodationFormValidationElYearsOfStudy,
} from "./studentPrivateAccommodationValidator";
import { Alert, AlertTitle } from "@material-ui/lab";
import HashLoader from "react-spinners/HashLoader";
import DialogComponent from "../../common/components/DialogComponent";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffff",
  },
}));

export default function StudentPrivateAccommodationComponent() {
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = React.useState();
  const [termsAndContitions, setTermsAndConditions] = React.useState(false);
  const [formData, setFormData] = React.useState({
    nameSurname: "",
    embg: "",
    addressLine: "",
    email: "",
    faculty: "",
    yearsOfStudy: "",
    proofsWay: "electronic",
  });
  const [rerender, triggerRerender] = React.useState(false);
  const [file1, setFile1] = React.useState([]);
  const [file2, setFile2] = React.useState([]);
  const [file3, setFile3] = React.useState([]);
  const [file4, setFile4] = React.useState([]);
  const [file5, setFile5] = React.useState([]);
  const [file6, setFile6] = React.useState([]);
  const [file7, setFile7] = React.useState([]);
  const [file10, setFile10] = React.useState([]);
  const [formErrors, setFormErrors] = React.useState();
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [recaptcha, setRecaptcha] = React.useState();
  const [recaptchaToken, setRecaptchaToken] = React.useState();
  const [alertbox, setAlertbox] = React.useState();
  const [privacyPolicy, setPrivacyPolicy] = React.useState(false);

  useEffect(() => {
    loadReCaptcha("6LcyOa4ZAAAAAEJeOd51mtNcrGMtOY6pGO4tybh5", verifyCallback);
  }, []);

  const verifyCallback = (recaptchaToken) => {
    setRecaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    recaptcha.execute();
  };

  const handleChange = (event, name) => {
    let data = formData;
    data[name] = event.target.value;
    setFormData(data);
    triggerRerender(!rerender);
  };

  const handleSubmit = () => {
    setBtnDisabled(true);
    updateToken();

    let finalData = {
      studentPrivateAccommodationVoucherRequest: formData,
      file7: file7,
      file10: file10,
      file1: file1,
      file2: file2,
      file3: file3,
      file4: file4,
      file5: file5,
      file6: file6,
    };

    var validFormData = true;

    if (
      finalData.studentPrivateAccommodationVoucherRequest.proofsWay ===
      "electronic"
    ) {
      if (
        finalData.studentPrivateAccommodationVoucherRequest.yearsOfStudy > 1
      ) {
        if (
          !studentPrivateAccommodationFormValidationElYearsOfStudy.isValidSync(
            finalData
          )
        ) {
          validFormData = false;
        }
      } else {
        if (
          !studentPrivateAccommodationFormValidationEl.isValidSync(finalData)
        ) {
          validFormData = false;
        }
      }
    } else {
      if (!studentPrivateAccommodationFormValidation.isValidSync(finalData)) {
        validFormData = false;
      }
    }

    if (validFormData) {
      VoucherRequestRepository.createVoucherStudentPrivateAccommodationRequest(
        finalData,
        recaptchaToken
      ).then(
        (res) => {
          setRedirectTo("/done");
        },
        (err) => {
          if (err.response?.data) {
            setFormErrors(err.response.data);
            if (err.response?.data?.message?.includes("_inactive")) {
              setAlertbox({
                severity: "error",
                title: LOCALE.getString(err.response.data.message),
                message: "",
              });
            }
          }
          setBtnDisabled(false);
          updateToken();
        }
      );
    } else {
      var validationErrors = {};
      (async () => {
        if (
          finalData.studentPrivateAccommodationVoucherRequest.proofsWay ===
          "electronic"
        ) {
          if (
            finalData.studentPrivateAccommodationVoucherRequest.yearsOfStudy > 1
          ) {
            await studentPrivateAccommodationFormValidationElYearsOfStudy
              .validate(finalData, { abortEarly: false })
              .catch((err) => {
                err.inner.forEach((validationError) => {
                  validationErrors[validationError.path] = {};
                  validationErrors[validationError.path] =
                    validationError.message;
                });
              });
          } else {
            await studentPrivateAccommodationFormValidationEl
              .validate(finalData, { abortEarly: false })
              .catch((err) => {
                err.inner.forEach((validationError) => {
                  validationErrors[validationError.path] = {};
                  validationErrors[validationError.path] =
                    validationError.message;
                });
              });
          }
        } else {
          await studentPrivateAccommodationFormValidation
            .validate(finalData, { abortEarly: false })
            .catch((err) => {
              err.inner.forEach((validationError) => {
                validationErrors[validationError.path] = {};
                validationErrors[validationError.path] =
                  validationError.message;
              });
            });
        }
        setBtnDisabled(false);
        setFormErrors(validationErrors);
      })();
    }
  };

  const addAttachments = (type, attachments) => {
    if (type === "1") {
      setFile1(attachments);
    } else if (type === "2") {
      setFile2(attachments);
    } else if (type === "3") {
      setFile3(attachments);
    } else if (type === "4") {
      setFile4(attachments);
    } else if (type === "5") {
      setFile5(attachments);
    } else if (type === "6") {
      setFile6(attachments);
    } else if (type === "7") {
      setFile7(attachments);
    } else if (type === "10") {
      setFile10(attachments);
    }
  };

  const closeDialog = (type) => {
    if (type === "privacyPolicy") {
      setPrivacyPolicy(false);
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(" + bgImg + ")",
        backgroundSize: "100% 100%",
        backgroundAttachment: "fixed",
      }}
    >
      <Backdrop open={btnDisabled} className={classes.backdrop}>
        <div style={{ textAlign: "center" }}>
          <HashLoader
            size={100}
            color={"#fff"}
            loading={btnDisabled}
            css="margin:30px"
          />
          <Typography>{LOCALE.please_wait}</Typography>
        </div>
      </Backdrop>
      {redirectTo && <Redirect to={redirectTo} />}
      <Slide direction="left" in={true}>
        <Container fixed style={{ paddingTop: "5vh" }}>
          <Grid container>
            <Grid item md={3} sm={1}></Grid>
            <Grid item md={6} sm={10}>
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{ textAlign: "center" }}
              >
                {LOCALE.student_private_accommodation_h1}
              </Typography>
            </Grid>
            <Grid item md={3} sm={1}></Grid>
          </Grid>
          <Grid container>
            <Grid item md={1}>
              <IconButton onClick={() => setRedirectTo("/")}>
                <Icon>arrow_back_ios</Icon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={2} sm={1}></Grid>
            <Grid item md={8} sm={10}>
              <Paper
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.65)",
                  padding: "7px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={2} sm={1}></Grid>
                  <Grid item md={8} sm={10}>
                    <Typography
                      variant="subtitle1"
                      style={{ textAlign: "center" }}
                      gutterBottom
                    >
                      <br />
                      {LOCALE.basic_info}
                      <br />
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item sm={12}>
                        {alertbox && (
                          <Alert fullwidth="true" severity={alertbox.severity}>
                            <AlertTitle>{alertbox?.title}</AlertTitle>
                            {alertbox?.message}
                          </Alert>
                        )}
                      </Grid>
                      <ReCaptcha
                        ref={(ref) => setRecaptcha(ref)}
                        sitekey="6LcyOa4ZAAAAAEJeOd51mtNcrGMtOY6pGO4tybh5"
                        action="request"
                        verifyCallback={verifyCallback}
                      />
                      <Grid item md={6} xs={12}>
                        <TextField
                          label={LOCALE.nameSurname}
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData.nameSurname}
                          onChange={(e) => handleChange(e, "nameSurname")}
                          error={
                            formErrors?.nameSurname ||
                            (formErrors &&
                              formErrors[
                                "studentPrivateAccommodationVoucherRequest.nameSurname"
                              ])
                          }
                          helperText={
                            (formErrors?.nameSurname &&
                              LOCALE.getString(formErrors.nameSurname)) ||
                            (formErrors &&
                              formErrors[
                                "studentPrivateAccommodationVoucherRequest.nameSurname"
                              ])
                          }
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {" "}
                        <TextField
                          label={LOCALE.embg}
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData.embg}
                          onChange={(e) => handleChange(e, "embg")}
                          placeholder="XXXXXXXXXXXXX"
                          error={
                            formErrors?.embg ||
                            (formErrors &&
                              formErrors[
                                "studentPrivateAccommodationVoucherRequest.embg"
                              ])
                          }
                          helperText={
                            (formErrors?.embg &&
                              LOCALE.getString(formErrors.embg)) ||
                            (formErrors &&
                              formErrors[
                                "studentPrivateAccommodationVoucherRequest.embg"
                              ])
                          }
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      label={LOCALE.addressLine}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="dense"
                      value={formData.addressLine}
                      onChange={(e) => handleChange(e, "addressLine")}
                      placeholder={LOCALE.addressLinePlaceholder}
                      error={
                        formErrors?.addressLine ||
                        (formErrors &&
                          formErrors[
                            "studentPrivateAccommodationVoucherRequest.addressLine"
                          ])
                      }
                      helperText={
                        (formErrors?.addressLine &&
                          LOCALE.getString(formErrors.addressLine)) ||
                        (formErrors &&
                          formErrors[
                            "studentPrivateAccommodationVoucherRequest.addressLine"
                          ])
                      }
                    />
                    <TextField
                      label={LOCALE.email}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="dense"
                      value={formData.email}
                      onChange={(e) => handleChange(e, "email")}
                      placeholder="someone@provider.com"
                      error={
                        formErrors?.email ||
                        (formErrors &&
                          formErrors[
                            "studentPrivateAccommodationVoucherRequest.email"
                          ])
                      }
                      helperText={
                        (formErrors?.email &&
                          LOCALE.getString(formErrors.email)) ||
                        (formErrors &&
                          formErrors[
                            "studentPrivateAccommodationVoucherRequest.email"
                          ])
                      }
                    />
                    <Grid container spacing={1}>
                      <Grid item md={8} sm={8} xs={12}>
                        {" "}
                        <TextField
                          label={LOCALE.faculty}
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData.faculty}
                          onChange={(e) => handleChange(e, "faculty")}
                          placeholder={LOCALE.facultyPlaceholder}
                          error={
                            formErrors?.faculty ||
                            (formErrors &&
                              formErrors[
                                "studentPrivateAccommodationVoucherRequest.faculty"
                              ])
                          }
                          helperText={
                            (formErrors?.faculty &&
                              LOCALE.getString(formErrors.faculty)) ||
                            (formErrors &&
                              formErrors[
                                "studentPrivateAccommodationVoucherRequest.faculty"
                              ])
                          }
                        />
                      </Grid>
                      <Grid item md={4} sm={4} xs={12}>
                        {" "}
                        <TextField
                          label={LOCALE.yearsOfStudy}
                          fullWidth
                          type="number"
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData?.yearsOfStudy}
                          onChange={(e) => handleChange(e, "yearsOfStudy")}
                          error={
                            formErrors?.yearsOfStudy ||
                            (formErrors &&
                              formErrors[
                                "studentPrivateAccommodationVoucherRequest.yearsOfStudy"
                              ])
                          }
                          helperText={
                            (formErrors?.yearsOfStudy &&
                              LOCALE.getString(formErrors.yearsOfStudy)) ||
                            (formErrors &&
                              formErrors[
                                "studentPrivateAccommodationVoucherRequest.yearsOfStudy"
                              ])
                          }
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    <Divider></Divider> <Divider></Divider> <br></br>
                    <Grid container spacing={1}>
                      <Grid item md={12} xs={12}>
                        <Typography>{LOCALE.proofsWay}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={formData.proofsWay == "electronic"}
                              onChange={(e) => handleChange(e, "proofsWay")}
                              name="checkedB"
                              color="secondary"
                              value="electronic"
                              size="small"
                            />
                          }
                          label={LOCALE.electronic}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={formData.proofsWay == "paper"}
                              onChange={(e) => handleChange(e, "proofsWay")}
                              name="checkedB"
                              color="secondary"
                              value="paper"
                              size="small"
                            />
                          }
                          label={LOCALE.paper}
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    {formData?.proofsWay === "electronic" && (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file10}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ minWidth: "150px" }}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={
                                  formErrors?.WAY_OF_ATTENDING_CLASSES_PROOF
                                    ? true
                                    : false
                                }
                                error={formErrors?.file10 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="10"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {formErrors?.WAY_OF_ATTENDING_CLASSES_PROOF}
                                  {formErrors?.file10}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file7}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ minWidth: "150px" }}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={
                                  formErrors?.PURCHASE_AGREEMENT_WITH_TENANT_PROOF
                                    ? true
                                    : false
                                }
                                error={formErrors?.file7 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="7"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {
                                    formErrors?.PURCHASE_AGREEMENT_WITH_TENANT_PROOF
                                  }
                                  {formErrors?.file7}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file1}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ minWidth: "150px" }}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={
                                  formErrors?.REGULAR_STUDENT_PROOF
                                    ? true
                                    : false
                                }
                                error={formErrors?.file1 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="1"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {formErrors?.REGULAR_STUDENT_PROOF}
                                  {formErrors?.file1}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file2}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={
                                  formErrors?.FINANCIAL_STATUS_OF_FAMILY_PROOF
                                }
                                error={formErrors?.file2 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="2"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {formErrors?.FINANCIAL_STATUS_OF_FAMILY_PROOF}
                                  {formErrors?.file2}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file3}
                                <br></br>
                                <a
                                  target="_blank"
                                  href="http://mon.gov.mk/stored/document/Uredba_za_participacija.pdf"
                                >
                                  {LOCALE.link_uredba}
                                </a>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={
                                  formErrors?.LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF
                                }
                                error={formErrors?.file3 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="3"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {
                                    formErrors?.LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF
                                  }
                                  {formErrors?.file3}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file4}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={formErrors?.USE_OF_PERSONAL_DATA_PROOF}
                                error={formErrors?.file4 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="4"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {formErrors?.USE_OF_PERSONAL_DATA_PROOF}
                                  {formErrors?.file4}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          {formData.yearsOfStudy > 1 && (
                            <TableRow>
                              <TableCell>
                                <Typography variant="caption">
                                  {LOCALE.file5}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  margin="dense"
                                  error={formErrors?.NUMBER_OF_PASSED_EXAMS}
                                  error={formErrors?.file5 ? true : false}
                                >
                                  <Upload
                                    onComponentClose={addAttachments}
                                    type="5"
                                  />
                                  <FormHelperText id="municipality-helper-text">
                                    {formErrors?.NUMBER_OF_PASSED_EXAMS}
                                    {formErrors?.file5}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          )}
                          {formData.yearsOfStudy > 1 && (
                            <TableRow>
                              <TableCell>
                                <Typography variant="caption">
                                  {LOCALE.file6}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  margin="dense"
                                  error={formErrors?.UPPI_PROOF}
                                  error={formErrors?.file6 ? true : false}
                                >
                                  <Upload
                                    onComponentClose={addAttachments}
                                    type="6"
                                  />
                                  <FormHelperText id="municipality-helper-text">
                                    {formErrors?.UPPI_PROOF}
                                    {formErrors?.file6}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    )}
                    <Divider></Divider>
                    <Divider></Divider>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={termsAndContitions}
                          onChange={() =>
                            setTermsAndConditions(!termsAndContitions)
                          }
                          name="checkedB"
                          color="secondary"
                        />
                      }
                      label={LOCALE.accept_terms_and_conditions}
                    />
                    <IconButton onClick={() => setPrivacyPolicy(true)}>
                      <Icon size="small">help_outline</Icon>
                    </IconButton>
                    {privacyPolicy && (
                      <DialogComponent
                        dialogTitle={LOCALE.privacyPolicy}
                        closeDialog={() => closeDialog("privacyPolicy")}
                        contentText="privacyPolicy"
                        dialogType="privacyPolicy"
                      ></DialogComponent>
                    )}
                    <Button
                      disabled={!termsAndContitions || btnDisabled}
                      size="small"
                      variant="contained"
                      style={{ marginBottom: "20px" }}
                      color="secondary"
                      onClick={() => handleSubmit()}
                      fullWidth
                    >
                      {LOCALE.submit}
                    </Button>
                  </Grid>
                  <Grid item md={2} sm={1}></Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={2} sm={1}></Grid>
          </Grid>
          <br></br>
          <br></br>
        </Container>
      </Slide>
      <Grid container>
        <Grid
          item
          md={12}
          style={{
            textAlign: "center",
            bottom: "0",
            left: "0",
            width: "100%",
          }}
        >
          <Typography variant="caption" color="textSecondary" gutterBottom>
            {LOCALE.footer_h1} <br></br>
            {LOCALE.footer_h2}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
