import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const validateLoginForm = yup.object().shape({
  username: yup
    .string()
    .required(LOCALE.login_username + LOCALE.required_field),
  password: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field),
});
