import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const MunicipalitiesRepository = {
  fetchMunicipality: (id) => {
    return Axios.get(`${SETTINGS.API_URL}municipalities/${id}`);
  },

  fetchAllMunicipalities: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}municipalities`,
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterProperty: payload.filterBy,
        filterValue: payload.filterValue,
        isActive: payload.isActive,
      },
    });
  },
  createMunicipality: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}municipalities`,
      method: "POST",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  updateMunicipality: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}municipalities`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  deleteMunicipality: (id) => {
    return Axios.delete(`${SETTINGS.API_URL}municipalities/${id}`);
  },
};
