import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
// @material-ui/styles
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Icon,
  CircularProgress,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { LOCALE } from "../../../properties/Locale";
import EntityMetadata from "../../../common/components/EntityMetadata";
import { GroupsRepository } from "./GroupsRepository";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import { validateCreateGroupForm } from "./Validations";

const useStyles = makeStyles(styles);

export default function GroupCreateComponent() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [tabIndex] = useState(0);
  const [redirectTo, setRedirectTo] = useState();
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(false);
  const {
    handleSubmit: createGroupForm,
    register: registerGroupForm,
    errors: errorsGroupForm,
    getValues,
  } = useForm({ validationSchema: validateCreateGroupForm });
  const dispatch = useDispatch();

  const handleClose = () => {};

  const showLoadingBar = () => {
    setLoading(true);
    setBtnSubmitLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
    setBtnSubmitLoading(false);
  };

  const handleSubmit = (values) => {
    showLoadingBar(true);
    GroupsRepository.create(values)
      .then((res) => {
        hideLoadingBar(false);
        dispatch(triggerRerender());
        dispatch(notifyShowSuccessMessage(LOCALE.group_create_success_message));
        setRedirectTo(`/app/admin/groups/${res.data.id}/edit`);
      })
      .catch((err) => {
        hideLoadingBar(false);
        notifyShowErrorMessage(err.message);
      });
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.create_new_group}`}
              <Link
                to={`/app/admin/groups/`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {loading ? <LinearProgress color="secondary" /> : ""}
            <div>
              <form onSubmit={createGroupForm(handleSubmit)}>
                <DialogContent>
                  <Tabs
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label={LOCALE.group_details} />
                  </Tabs>
                  <br />

                  {tabIndex === 0 && (
                    <div>
                      <TextField
                        id="name"
                        label={LOCALE.group_name}
                        fullWidth={true}
                        size="small"
                        margin="normal"
                        variant="outlined"
                        name="name"
                        inputRef={registerGroupForm}
                        InputProps={{
                          disabled: btnSubmitLoading,
                        }}
                        error={errorsGroupForm?.name}
                        helperText={errorsGroupForm?.name?.message}
                      />
                      <EntityMetadata data={getValues()} />
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  <Link
                    to={`/app/admin/groups/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button size="small" variant="outlined" color="secondary">
                      {LOCALE.close}
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={btnSubmitLoading}
                    className={btnSubmitLoading ? "btn-loading" : ""}
                  >
                    {LOCALE.save}
                    {btnSubmitLoading && (
                      <CircularProgress
                        color="secondary"
                        size={20}
                        style={{ position: "absolute" }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
