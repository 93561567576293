import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TextField,
  Button,
  Icon,
  Checkbox,
  LinearProgress,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import tableStyles from "../../../assets/jss/components/tableStyle";
import styles from "../../../assets/jss/layouts/appStyle";
// core components
import { LOCALE } from "../../../properties/Locale";
import { SettingsRepository } from "./SettingsRepository";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  changePageTitle,
} from "../../../common/actions/CommonActions";
import TestMailSettings from "./TestMailSettingsComponent";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles, tableStyles);

export default function SettingsComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [persistedData, setPersistedData] = useState(null);
  const [propsHaveChanges, setPropsHaveChanges] = useState(false);
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(false);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.settings));
    loadProps();
  }, []);

  const loadProps = () => {
    SettingsRepository.fetchAll()
      .then((res) => {
        setData(res.data);
        setPersistedData(JSON.parse(JSON.stringify(res.data)));
        setPropsHaveChanges(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const showLoadingBar = () => {
    setLoading(true);
    setBtnSubmitLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
    setBtnSubmitLoading(false);
  };

  const handlePropFormChange = (index) => (event) => {
    let newArr = [...data];
    newArr[index].value = event.target.value;
    setData(newArr);
    setPropsHaveChanges(JSON.stringify(data) !== JSON.stringify(persistedData));
  };

  const handleCheckboxChange = (index) => (event) => {
    let newArr = [...data];
    let newValue = "false";
    if (event.target.checked) {
      newValue = "true";
    } else {
      newValue = "false";
    }

    newArr[index].value = newValue;
    setData(newArr);
    setPropsHaveChanges(JSON.stringify(data) !== JSON.stringify(persistedData));
  };

  const handleSaveAllProps = () => {
    showLoadingBar();
    data.map((item) => {
      SettingsRepository.update(item.key, item.value)
        .then((res) => {
          hideLoadingBar();
          dispatch(
            notifyShowSuccessMessage(
              LOCALE.system_settings_props_updated_success
            )
          );
          loadProps();
        })
        .catch((err) => {
          hideLoadingBar();
          dispatch(notifyShowErrorMessage(err.message));
        });
    });
  };

  return (
    <div>
      <Grid container spacing={4}>
        {loading ? <LinearProgress color="secondary" /> : ""}
        <Grid item md={6}>
          <div>
            <Card>
              <CardHeader color="theme">
                <h4 className={classes.cardTitleWhite}>{LOCALE.props}</h4>
              </CardHeader>
              <CardBody>
                <TableContainer>
                  <Table size="small" aria-label="system settings table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{LOCALE.key}</TableCell>
                        <TableCell>{LOCALE.value}</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            disabled={!propsHaveChanges}
                            onClick={() => handleSaveAllProps()}
                            icon={<Icon>save</Icon>}
                          >
                            {LOCALE.save}
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((prop, index) => (
                        <TableRow key={prop.key}>
                          <TableCell>{prop.key}</TableCell>
                          <TableCell colSpan={2}>
                            {prop.key.includes("ENABLED") ? (
                              <Checkbox
                                checked={
                                  data[index] && data[index].value
                                    ? data[index].value === "true"
                                    : false
                                }
                                onChange={handleCheckboxChange(index)}
                              />
                            ) : (
                              <TextField
                                size="small"
                                type={
                                  prop.key.includes("PASSWORD")
                                    ? "password"
                                    : "text"
                                }
                                id={`${prop.key}-input-field`}
                                fullWidth
                                onChange={handlePropFormChange(index)}
                                value={
                                  data[index] && data[index].value
                                    ? data[index].value
                                    : ""
                                }
                                InputProps={{
                                  disabled: btnSubmitLoading,
                                }}
                                margin="normal"
                                variant="outlined"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>
          </div>
        </Grid>
        <Grid item md={6}>
          <div>
            <TestMailSettings data={persistedData} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
