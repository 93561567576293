import Axios from "axios";
import { SETTINGS } from "../../properties/ApiProps";

export const ObjectionRepository = {
  createObjection: (data, recaptchaToken) => {
    return Axios({
      url: SETTINGS.API_URL + "objection",
      method: "POST",
      params: { "g-recaptcha-response": recaptchaToken },
      data: data,
    });
  },

  update: (data) => {
    return Axios({
      url: SETTINGS.API_URL + "objection",
      method: "PUT",
      data: data,
    });
  },

  fetchAll: (payload) => {
    return Axios({
      url: SETTINGS.API_URL + "objection",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterBy: payload.filterBy,
        filterValue: payload.filterValue,
      },
    });
  },

  fetch: (id) => {
    return Axios.get(`${SETTINGS.API_URL}objection/${id}`);
  },
};
