import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  TablePagination,
  TableRow,
  TableFooter,
  Icon,
  IconButton,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Chip,
  Tabs,
  Tab,
  Dialog,
  Tooltip,
  Link,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import tableStyles from "../../../assets/jss/components/tableStyle";
import styles from "../../../assets/jss/layouts/appStyle";
// core components
import { LOCALE } from "../../../properties/Locale";
import { AuditLogRepository } from "./AuditLogRepository";
import { SETTINGS } from "../../../properties/ApiProps";
import {
  notifyShowErrorMessage,
  changePageTitle,
} from "../../../common/actions/CommonActions";
import EntityMetadata from "../../../common/components/EntityMetadata";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tableStyles);

export default function AuditLogComponent() {
  const classes = useStyles();
  const classesTable = useTableStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({ totalElements: 0, number: 0 });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [tabIndex, setTabIndex] = useState(0);
  const [auditType, setAuditType] = useState(SETTINGS.AUDIT_LOG_TYPES[0]);
  const [auditTypes] = useState(SETTINGS.AUDIT_LOG_TYPES);
  const [auditLogRecordDetails, setAuditLogRecordDetails] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.audit_log));
    loadAuditLog(auditType.value, page, size);
  }, [page, size, auditType]);

  const loadAuditLog = (auditType, page, size) => {
    AuditLogRepository.fetchLog(auditType, page, size)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
    setAuditType(auditTypes[newTabIndex]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleOpenDialog = (data) => {
    setAuditLogRecordDetails(data);
    setDetailsDialogOpen(true);
  };

  const handleCloseDetailsDialog = () => {
    setDetailsDialogOpen(false);
    setAuditLogRecordDetails(null);
  };

  return (
    <div>
      <Card>
        <CardHeader color="theme"></CardHeader>
        <CardBody>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {auditTypes?.map((auditType) => (
              <Tab
                key={auditType.name}
                label={LOCALE.getString(auditType.name)}
              />
            ))}
          </Tabs>
          <Table aria-label="audit table" className={classesTable.table}>
            <TableHead>
              <TableRow className={classesTable.tableHeadRow}>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.id}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.created_by}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.created_at}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.modified_by}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.revtype}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.revtstmp}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content &&
                data?.content?.map((auditLogRow, index) => (
                  <TableRow
                    key={index}
                    className={classesTable.tableBodyRow}
                    style={index % 2 ? { background: "#f0f0f0" } : {}}
                  >
                    <TableCell className={classesTable.tableCell}>
                      {auditLogRow.row.id}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {auditLogRow.row.createdBy}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {moment(auditLogRow.row.dateCreated).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ) +
                        " " +
                        "(" +
                        moment(auditLogRow.row.dateCreated).fromNow() +
                        ")"}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {auditLogRow.row.modifiedBy}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {auditLogRow.row.revtype === 0 ? (
                        <Chip label="CREATE" color="primary" />
                      ) : auditLogRow.row.revtype === 1 ? (
                        <Chip
                          label="UPDATE"
                          color="secondary"
                          style={{
                            backgroundColor: "darkorange",
                            color: "white",
                          }}
                        />
                      ) : (
                        <Chip label="DELETE" color="secondary" />
                      )}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {moment(auditLogRow.row.revtstmp).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ) +
                        " " +
                        "(" +
                        moment(auditLogRow.row.revtstmp).fromNow() +
                        ")"}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      <Tooltip
                        title={LOCALE.details}
                        aria-label={LOCALE.details}
                        style={{ color: "white" }}
                      >
                        <Link
                          onClick={() => handleOpenDialog(auditLogRow.row)}
                          style={{ textDecoration: "none" }}
                        >
                          <IconButton
                            size="small"
                            color="primary"
                            aria-label="details"
                          >
                            <Icon>ballot</Icon>
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={7}
                  count={data.totalElements}
                  rowsPerPage={size}
                  page={data.number}
                  labelRowsPerPage={LOCALE.rows_per_page}
                  SelectProps={{
                    inputProps: { "aria-label": LOCALE.rows_per_page },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardBody>
      </Card>
      <Dialog
        open={detailsDialogOpen}
        onClose={handleCloseDetailsDialog}
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            overflowY: "unset",
          },
        }}
      >
        <Card>
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              <IconButton
                style={{ float: "right", color: "white", padding: "0px" }}
                aria-label="close"
                onClick={handleCloseDetailsDialog}
              >
                <Icon>close</Icon>
              </IconButton>
              {`${LOCALE.audit_details}`}
            </h4>
          </CardHeader>
          <CardBody>
            <div>
              <Table
                aria-label="audit details table"
                className={classesTable.table}
                fullWidth={true}
              >
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      <strong>{LOCALE.key}</strong>
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      {LOCALE.value}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditLogRecordDetails &&
                    Object.keys(auditLogRecordDetails).map((logPropKey) =>
                      [
                        "dateCreated",
                        "createdBy",
                        "dateModified",
                        "modifiedBy",
                        "rev",
                      ].indexOf(logPropKey) !== -1 ? (
                        ""
                      ) : (
                        <TableRow
                          key={logPropKey.id}
                          className={classes.tableBodyRow}
                        >
                          <TableCell className={classes.tableCell}>
                            {LOCALE.getString(logPropKey)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {logPropKey === "revtype" ? (
                              auditLogRecordDetails[logPropKey] === 0 ? (
                                <Chip label="CREATE" color="primary" />
                              ) : auditLogRecordDetails[logPropKey] === 1 ? (
                                <Chip
                                  label="UPDATE"
                                  color="orange"
                                  style={{
                                    backgroundColor: "darkorange",
                                    color: "white",
                                  }}
                                />
                              ) : (
                                <Chip label="DELETE" color="secondary" />
                              )
                            ) : logPropKey.includes("date") ||
                              logPropKey === "revtstmp" ? (
                              moment(auditLogRecordDetails[logPropKey]).format(
                                "YYYY-MM-DD HH:mm:ss"
                              ) +
                              " " +
                              "(" +
                              moment(
                                auditLogRecordDetails[logPropKey]
                              ).fromNow() +
                              ")"
                            ) : (
                              auditLogRecordDetails[logPropKey]
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
              {auditLogRecordDetails && (
                <EntityMetadata data={auditLogRecordDetails} />
              )}
            </div>
          </CardBody>
        </Card>
      </Dialog>
    </div>
  );
}
