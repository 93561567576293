import React, { useState } from "react";
import {
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import filesize from "filesize";
import FileIcon, { defaultStyles } from "react-file-icon";
import { LOCALE } from "../../properties/Locale";

export default function Upload(props) {
  const [files, setFiles] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    if (props.onClose) props.onClose();
    if (props.onComponentClose) props.onComponentClose(props.type, files);
  };

  const deleteFile = () => {
    setFiles([]);
  };

  return (
    <div>
      <label htmlFor="proofs-button-file">
        <Button
          variant="contained"
          component="span"
          color="secondary"
          size="small"
          onClick={() => setOpenDialog(!openDialog)}
        >
          <Icon>backup</Icon> &nbsp;{" "}
          {!props.disabled ? LOCALE.upload : LOCALE.download} (
          {files ? files.length : 0})
        </Button>
      </label>

      <Dialog
        open={openDialog}
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            overflowY: "unset",
          },
        }}
      >
        <DialogTitle>
          <IconButton
            style={{ float: "right" }}
            aria-label="close"
            onClick={() => handleDialogClose()}
          >
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!props.disabled && (
            <DropzoneArea
              multiple={false}
              onChange={(files) => setFiles(files)}
              showFileNamesInPreview={false}
              showPreviews={false}
              showPreviewsInDropzone={false}
              filesLimit={1}
              dropzoneText={LOCALE.dragNDrop}
              maxFileSize={10000000}
              acceptedFiles={[".pdf", ".docx", ".xls", ".xlsx", ".zip"]}
            ></DropzoneArea>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{LOCALE.docType}</TableCell>
                <TableCell>{LOCALE.docName}</TableCell>
                <TableCell>{LOCALE.docSize}</TableCell>
                <TableCell style={{ width: "100px" }}>
                  {LOCALE.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files &&
                files.map((attachment, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <FileIcon
                        size={50}
                        extension={
                          attachment.name.split(".")[1]
                            ? attachment.name.split(".")[1]
                            : ""
                        }
                        {...defaultStyles[
                          attachment.name.split(".")[1]
                            ? attachment.name.split(".")[1]
                            : ""
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      {attachment.name.split(".")[0]
                        ? attachment.name.split(".")[0]
                        : "/"}
                    </TableCell>
                    <TableCell>{filesize(attachment.size)}</TableCell>
                    <TableCell>
                      {!props.disabled && (
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="delete"
                          onClick={() => deleteFile()}
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{ float: "right" }}
            color="secondary"
            onClick={() => handleDialogClose()}
          >
            {LOCALE.save}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
