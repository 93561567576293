import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const ChartsRepository = {
  getSchoolSuppliesStatuses: () => {
    return Axios({
      url: SETTINGS.API_URL + "voucherRequest/report/schoolSuppliesStatuses",
      method: "GET",
    });
  },

  getStudyCostsStatuses: () => {
    return Axios({
      url: SETTINGS.API_URL + "voucherRequest/report/studyCostsStatuses",
      method: "GET",
    });
  },

  getTransportCostsStatuses: () => {
    return Axios({
      url: SETTINGS.API_URL + "voucherRequest/report/transportCostsStatuses",
      method: "GET",
    });
  },

  getStudentsDoormsStatuses: () => {
    return Axios({
      url: SETTINGS.API_URL + "voucherRequest/report/studentsDoormsStatuses",
      method: "GET",
    });
  },

  getPrivateAccommodationStatuses: () => {
    return Axios({
      url:
        SETTINGS.API_URL + "voucherRequest/report/privateAccommodationStatuses",
      method: "GET",
    });
  },
};
