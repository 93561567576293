import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Button,
  IconButton,
  Paper,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  Radio,
  Slide,
  TableBody,
  TableRow,
  TableCell,
  Table,
  FormControl,
  FormHelperText,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import bgImg from "../../assets/img/productCurvyLines.png";

import { Redirect } from "react-router-dom";
import Upload from "../../common/components/Upload";
import { VoucherRequestRepository } from "./voucherRequestRepository";
import {
  schoolSuppliesFormValidationEl,
  schoolSuppliesFormValidation,
} from "./schoolSuppliesFormValidations";
import { LOCALE } from "../../properties/Locale";
import { ReCaptcha } from "react-recaptcha-v3";
import { loadReCaptcha } from "react-recaptcha-v3";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import HashLoader from "react-spinners/HashLoader";
import DialogComponent from "../../common/components/DialogComponent";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffff",
  },
}));

export default function SchoolSuppliesFormComponent() {
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = React.useState();
  const [termsAndContitions, setTermsAndConditions] = React.useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState({
    nameSurname: "",
    embg: "",
    addressLine: "",
    email: "",
    proofsWay: "electronic",
    school: "",
    telephone: "",
    municipality: "",
    idNumber: "",
    idAuthority: "",
    idDateOfExpiry: new Date(),
  });
  const [rerender, triggerRerender] = React.useState(false);
  const [file1, setFile1] = React.useState([]);
  const [file2, setFile2] = React.useState([]);
  const [file3, setFile3] = React.useState([]);
  const [file4, setFile4] = React.useState([]);
  const [formErrors, setFormErrors] = React.useState();
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [recaptcha, setRecaptcha] = React.useState();
  const [recaptchaToken, setRecaptchaToken] = React.useState();
  const [alertbox, setAlertbox] = React.useState();
  const [privacyPolicy, setPrivacyPolicy] = React.useState(false);

  useEffect(() => {
    loadReCaptcha("6LcyOa4ZAAAAAEJeOd51mtNcrGMtOY6pGO4tybh5", verifyCallback);
  }, []);

  const verifyCallback = (recaptchaToken) => {
    setRecaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    recaptcha.execute();
  };

  const handleChange = (event, name) => {
    let data = formData;
    data[name] = event.target.value;
    setFormData(data);
    triggerRerender(!rerender);
  };

  const handleDateChange = (date) => {
    let data = formData;
    data.idDateOfExpiry = date;
    setFormData(data);
    triggerRerender(!rerender);
  };

  const handleSubmit = () => {
    setBtnDisabled(true);
    updateToken();

    let finalData = {
      schoolSuppliesVoucherRequest: formData,
      file1: file1,
      file2: file2,
      file3: file3,
      file4: file4,
    };

    var validFormData = true;
    if (finalData.schoolSuppliesVoucherRequest.proofsWay === "electronic") {
      if (!schoolSuppliesFormValidationEl.isValidSync(finalData)) {
        validFormData = false;
      }
    } else {
      if (!schoolSuppliesFormValidation.isValidSync(finalData)) {
        validFormData = false;
      }
    }

    if (validFormData) {
      VoucherRequestRepository.createVoucherSchoolSuppliesRequest(
        finalData,
        recaptchaToken
      ).then(
        (res) => {
          setRedirectTo("/done");
        },
        (err) => {
          if (err.response?.data) {
            setFormErrors(err.response.data);
            if (err.response?.data?.message?.includes("_inactive")) {
              setAlertbox({
                severity: "error",
                title: LOCALE.getString(err.response.data.message),
                message: "",
              });
            }
          }
          setBtnDisabled(false);
          updateToken();
        }
      );
    } else {
      var validationErrors = {};
      (async () => {
        if (finalData.schoolSuppliesVoucherRequest.proofsWay === "electronic") {
          await schoolSuppliesFormValidationEl
            .validate(finalData, { abortEarly: false })
            .catch((err) => {
              err.inner.forEach((validationError) => {
                validationErrors[validationError.path] = {};
                validationErrors[validationError.path] =
                  validationError.message;
              });
            });
        } else {
          await schoolSuppliesFormValidation
            .validate(finalData, { abortEarly: false })
            .catch((err) => {
              err.inner.forEach((validationError) => {
                validationErrors[validationError.path] = {};
                validationErrors[validationError.path] =
                  validationError.message;
              });
            });
        }
        setBtnDisabled(false);
        setFormErrors(validationErrors);
      })();
    }
  };

  const addAttachments = (type, attachments) => {
    if (type === "1") {
      setFile1(attachments);
    } else if (type === "2") {
      setFile2(attachments);
    } else if (type === "3") {
      setFile3(attachments);
    } else if (type === "4") {
      setFile4(attachments);
    }
  };

  const closeDialog = (type) => {
    if (type === "privacyPolicy") {
      setPrivacyPolicy(false);
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(" + bgImg + ")",
        backgroundSize: "100% 100%",
        backgroundAttachment: "fixed",
      }}
    >
      <Backdrop open={btnDisabled} className={classes.backdrop}>
        <div style={{ textAlign: "center" }}>
          <HashLoader
            size={100}
            color={"#fff"}
            loading={btnDisabled}
            css="margin:30px"
          />
          <Typography>{LOCALE.please_wait}</Typography>
        </div>
      </Backdrop>
      {redirectTo && <Redirect to={redirectTo} />}
      <Slide direction="left" in={true}>
        <Container fixed style={{ paddingTop: "5vh" }}>
          <Grid container>
            <Grid item md={3} sm={1}></Grid>
            <Grid item md={6} sm={10}>
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{ textAlign: "center" }}
              >
                {LOCALE.school_supplies_h1}
              </Typography>
            </Grid>
            <Grid item md={3} sm={1}></Grid>
          </Grid>
          <Grid container>
            <Grid item md={1}>
              <IconButton onClick={() => setRedirectTo("/")}>
                <Icon>arrow_back_ios</Icon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={2} sm={1}></Grid>
            <Grid item md={8} sm={10}>
              <Paper
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.65)",
                  padding: "7px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={2} sm={1}></Grid>
                  <Grid item md={8} sm={10}>
                    <Typography
                      variant="subtitle1"
                      style={{ textAlign: "center" }}
                      gutterBottom
                    >
                      <br />
                      {LOCALE.basic_info}
                      <br />
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item sm={12}>
                        {alertbox && (
                          <Alert fullwidth="true" severity={alertbox.severity}>
                            <AlertTitle>{alertbox?.title}</AlertTitle>
                            {alertbox?.message}
                          </Alert>
                        )}
                      </Grid>
                      <ReCaptcha
                        ref={(ref) => setRecaptcha(ref)}
                        sitekey="6LcyOa4ZAAAAAEJeOd51mtNcrGMtOY6pGO4tybh5"
                        action="request"
                        verifyCallback={verifyCallback}
                      />
                      <Grid item md={6} xs={12}>
                        <TextField
                          label={LOCALE.nameSurname}
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData.nameSurname}
                          onChange={(e) => handleChange(e, "nameSurname")}
                          error={
                            formErrors?.nameSurname ||
                            (formErrors &&
                              formErrors[
                                "schoolSuppliesVoucherRequest.nameSurname"
                              ])
                          }
                          helperText={
                            (formErrors?.nameSurname &&
                              LOCALE.getString(formErrors.nameSurname)) ||
                            (formErrors &&
                              formErrors[
                                "schoolSuppliesVoucherRequest.nameSurname"
                              ])
                          }
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {" "}
                        <TextField
                          label={LOCALE.embg}
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData.embg}
                          onChange={(e) => handleChange(e, "embg")}
                          placeholder="XXXXXXXXXXXXX"
                          error={
                            formErrors?.embg ||
                            (formErrors &&
                              formErrors["schoolSuppliesVoucherRequest.embg"])
                          }
                          helperText={
                            (formErrors?.embg &&
                              LOCALE.getString(formErrors.embg)) ||
                            (formErrors &&
                              formErrors["schoolSuppliesVoucherRequest.embg"])
                          }
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      label={LOCALE.addressLine}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="dense"
                      value={formData.addressLine}
                      onChange={(e) => handleChange(e, "addressLine")}
                      placeholder={LOCALE.addressLinePlaceholder}
                      error={
                        formErrors?.addressLine ||
                        (formErrors &&
                          formErrors[
                            "schoolSuppliesVoucherRequest.addressLine"
                          ])
                      }
                      helperText={
                        (formErrors?.addressLine &&
                          LOCALE.getString(formErrors.addressLine)) ||
                        (formErrors &&
                          formErrors[
                            "schoolSuppliesVoucherRequest.addressLine"
                          ])
                      }
                    />
                    <TextField
                      label={LOCALE.email}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="dense"
                      value={formData.email}
                      onChange={(e) => handleChange(e, "email")}
                      placeholder="someone@provider.com"
                      error={
                        formErrors?.email ||
                        (formErrors &&
                          formErrors["schoolSuppliesVoucherRequest.email"])
                      }
                      helperText={
                        (formErrors?.email &&
                          LOCALE.getString(formErrors.email)) ||
                        (formErrors &&
                          formErrors["schoolSuppliesVoucherRequest.email"])
                      }
                    />
                    <TextField
                      label={LOCALE.school}
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={formData.school}
                      onChange={(e) => handleChange(e, "school")}
                      margin="dense"
                    />
                    <TextField
                      label={LOCALE.telephone}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="dense"
                      value={formData.telephone}
                      onChange={(e) => handleChange(e, "telephone")}
                      placeholder="+389 7X XXX XXX"
                    />
                    <TextField
                      label={LOCALE.municipality}
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={formData.municipality}
                      onChange={(e) => handleChange(e, "municipality")}
                      margin="dense"
                    />{" "}
                    <br></br>
                    <Typography>{LOCALE.id_student_parent}</Typography>
                    <Grid container spacing={1}>
                      <Grid item md={4} xs={12}>
                        <TextField
                          label={LOCALE.idNumber}
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData.idNumber}
                          onChange={(e) => handleChange(e, "idNumber")}
                          placeholder="A1234567"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          label={LOCALE.idAuthority}
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="dense"
                          value={formData.idAuthority}
                          onChange={(e) => handleChange(e, "idAuthority")}
                          placeholder="МВР-СКОПЈЕ"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            margin="dense"
                            id="date-picker-inline"
                            label={LOCALE.idDateOfExpiry}
                            value={formData.idDateOfExpiry}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <br></br>
                    <Divider></Divider> <Divider></Divider> <br></br>
                    <Grid container spacing={1}>
                      <Grid item md={12} xs={12}>
                        <Typography>{LOCALE.proofsWay}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={formData.proofsWay == "electronic"}
                              onChange={(e) => handleChange(e, "proofsWay")}
                              name="checkedB"
                              color="secondary"
                              value="electronic"
                              size="small"
                            />
                          }
                          label={LOCALE.electronic}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={formData.proofsWay == "paper"}
                              onChange={(e) => handleChange(e, "proofsWay")}
                              name="checkedB"
                              color="secondary"
                              value="paper"
                              size="small"
                            />
                          }
                          label={LOCALE.paper}
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    {formData?.proofsWay === "electronic" && (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file1}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ minWidth: "150px" }}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={
                                  formErrors?.REGULAR_STUDENT_PROOF
                                    ? true
                                    : false
                                }
                                error={formErrors?.file1 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="1"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {formErrors?.REGULAR_STUDENT_PROOF}
                                  {formErrors?.file1}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file2}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={
                                  formErrors?.FINANCIAL_STATUS_OF_FAMILY_PROOF
                                }
                                error={formErrors?.file2 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="2"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {formErrors?.FINANCIAL_STATUS_OF_FAMILY_PROOF}
                                  {formErrors?.file2}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file3}
                                <br></br>
                                <a
                                  target="_blank"
                                  href="http://mon.gov.mk/stored/document/Uredba_za_participacija.pdf"
                                >
                                  {LOCALE.link_uredba}
                                </a>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={
                                  formErrors?.LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF
                                }
                                error={formErrors?.file3 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="3"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {
                                    formErrors?.LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF
                                  }
                                  {formErrors?.file3}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {LOCALE.file4}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                error={formErrors?.USE_OF_PERSONAL_DATA_PROOF}
                                error={formErrors?.file4 ? true : false}
                              >
                                <Upload
                                  onComponentClose={addAttachments}
                                  type="4"
                                />
                                <FormHelperText id="municipality-helper-text">
                                  {formErrors?.USE_OF_PERSONAL_DATA_PROOF}
                                  {formErrors?.file4}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )}
                    <Divider></Divider>
                    <Divider></Divider>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={termsAndContitions}
                          onChange={() =>
                            setTermsAndConditions(!termsAndContitions)
                          }
                          name="checkedB"
                          color="secondary"
                        />
                      }
                      label={LOCALE.accept_terms_and_conditions}
                    />
                    <IconButton onClick={() => setPrivacyPolicy(true)}>
                      <Icon size="small">help_outline</Icon>
                    </IconButton>
                    {privacyPolicy && (
                      <DialogComponent
                        dialogTitle={LOCALE.privacyPolicy}
                        closeDialog={() => closeDialog("privacyPolicy")}
                        contentText="privacyPolicy"
                        dialogType="privacyPolicy"
                      ></DialogComponent>
                    )}
                    <Button
                      disabled={!termsAndContitions || btnDisabled}
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{ marginBottom: "20px" }}
                      onClick={() => handleSubmit()}
                      fullWidth
                    >
                      {LOCALE.submit}
                    </Button>
                  </Grid>
                  <Grid item md={2} sm={1}></Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={2} sm={1}></Grid>
          </Grid>
          <br></br>
          <br></br>
        </Container>
      </Slide>
      <Grid container>
        <Grid
          item
          md={12}
          style={{
            textAlign: "center",
            bottom: "0",
            left: "0",
            width: "100%",
          }}
        >
          <Typography variant="caption" color="textSecondary" gutterBottom>
            {LOCALE.footer_h1} <br></br>
            {LOCALE.footer_h2}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
