import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/layouts/appStyle";
import { useDispatch } from "react-redux";
import {
  changePageTitle,
  notifyShowSuccessMessage,
} from "../../common/actions/CommonActions";
import { LOCALE } from "../../properties/Locale";
import {
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Icon,
} from "@material-ui/core";
import Card from "../../common/components/Card/Card";
import CardHeader from "../../common/components/Card/CardHeader";
import CardBody from "../../common/components/Card/CardBody";
import { EvoucherAppRepository } from "./EvoucherAppRepository";
import { SETTINGS } from "../../properties/ApiProps";

const useStyles = makeStyles(styles);

export default function StudyCostsDetailsComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.vacancy_evaluation));
    if (props?.match?.params?.studyCostsId) {
      EvoucherAppRepository.getStudyCostsApp(
        props?.match?.params?.studyCostsId
      ).then((res) => {
        if (res.data.status === "PENDING" || res.data.status === "PROCESSING") {
          EvoucherAppRepository.updateStudyCostAppStatus(
            res.data.id,
            "PROCESSING"
          );
        }
        setData(res.data);
      });
    }
  }, [dispatch]);

  const approveApp = () => {
    EvoucherAppRepository.updateStudyCostAppStatus(data.id, "APPROVED").then(
      (res) => {
        setRedirectTo("/app/studyCosts");
      }
    );
  };

  const rejectApp = () => {
    EvoucherAppRepository.updateStudyCostAppStatus(data.id, "REJECTED").then(
      (res) => {
        setRedirectTo("/app/studyCosts");
      }
    );
  };

  const handleClose = () => {};

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      {data && data.id && (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Card>
                <CardHeader
                  color="theme"
                  style={{ padding: "8px", paddingLeft: "15px" }}
                >
                  <Typography>{LOCALE.basic_info}</Typography>
                </CardHeader>
                <CardBody>
                  <Grid container spacing={1}>
                    <Grid item md={1} sm={1}></Grid>
                    <Grid item md={10} sm={10}>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            label={LOCALE.nameSurname}
                            fullWidth
                            size="small"
                            variant="outlined"
                            margin="dense"
                            value={data.nameSurname}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {" "}
                          <TextField
                            label={LOCALE.embg}
                            fullWidth
                            size="small"
                            variant="outlined"
                            margin="dense"
                            value={data.embg}
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                      <TextField
                        label={LOCALE.addressLine}
                        fullWidth
                        size="small"
                        variant="outlined"
                        margin="dense"
                        value={data.addressLine}
                        disabled={true}
                      />
                      <TextField
                        label={LOCALE.email}
                        fullWidth
                        size="small"
                        variant="outlined"
                        margin="dense"
                        value={data.email}
                        disabled={true}
                      />
                      <Grid container spacing={1}>
                        <Grid item md={8} sm={8} xs={12}>
                          {" "}
                          <TextField
                            label={LOCALE.faculty}
                            fullWidth
                            size="small"
                            variant="outlined"
                            margin="dense"
                            value={data.faculty}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                          {" "}
                          <TextField
                            label={LOCALE.study_year}
                            fullWidth
                            type="number"
                            size="small"
                            variant="outlined"
                            margin="dense"
                            value={data?.yearsOfStudy}
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                      <br></br>
                      <br></br>
                    </Grid>
                    <Grid item md={1} sm={1}></Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardHeader
                  color="theme"
                  style={{ padding: "8px", paddingLeft: "15px" }}
                >
                  <Typography>{LOCALE.documents}</Typography>
                </CardHeader>
                <CardBody>
                  {data?.proofsWay === "electronic" && (
                    <Table>
                      <TableBody>
                        {data &&
                          data.proofs &&
                          data.proofs.map((attachment, index) => (
                            <TableRow>
                              <TableCell>
                                <Typography variant="caption">
                                  {LOCALE.getString(attachment.fileType)}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ minWidth: "150px" }}>
                                <a
                                  target="blank"
                                  href={`${SETTINGS.API_URL}voucherRequest/attachments/${attachment.id}`}
                                >
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    aria-label="Download"
                                  >
                                    <Icon>cloud_download</Icon>
                                  </IconButton>
                                </a>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  )}
                  {data?.proofsWay === "paper" && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Icon
                          style={{ verticalAlign: "bottom", color: "orange" }}
                        >
                          warning
                        </Icon>{" "}
                        {LOCALE.paper_documents}
                      </TableCell>
                    </TableRow>
                  )}
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Grid item md={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item md={12} xs={12}>
                        <Grid container spacing={1}>
                          <Grid item md={3} xs={12}>
                            <Link
                              to={`/app/studyCosts/`}
                              style={{ textDecoration: "none" }}
                            >
                              <Button
                                size="small"
                                variant="outlined"
                                color="secondary"
                              >
                                {LOCALE.back}
                              </Button>
                            </Link>
                          </Grid>
                          <Grid item md={1} xs={12}></Grid>
                          <Grid item md={8} xs={12}>
                            {((data && data.status === "PENDING") ||
                              data.status == "PROCESSING") && (
                              <Grid container spacing={1}>
                                <Grid item md={3} xs={12}></Grid>
                                <Grid item md={4} xs={12}>
                                  <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => rejectApp()}
                                  >
                                    {LOCALE.rejectApp}
                                  </Button>
                                </Grid>
                                <Grid item md={5} xs={12}>
                                  <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => approveApp()}
                                  >
                                    {LOCALE.acceptApp}
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
