import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { SchoolsRepository } from "./SchoolsRepository";
import { LOCALE } from "../../../properties/Locale";
import EntityMetadata from "../../../common/components/EntityMetadata";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import { MunicipalitiesRepository } from "../../configuration/municipalities/MunicipalitiesRepository";

const useStyles = makeStyles(styles);

export default function SchoolCreateComponent() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(false);
  const [municipalities, setMunicipalities] = useState();
  const [schools, setSchools] = useState();
  const [schoolType, setschoolType] = useState();
  const [ownedBy, setOwnedBy] = useState();
  const [municipalityId, setMunicipalityId] = useState();
  const {
    handleSubmit: createSchoolForm,
    register: registerCreateForm,
    errors: errorsCreateSchool,
    control,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    MunicipalitiesRepository.fetchAllMunicipalities({
      page: 0,
      size: 100,
      orderBy: "name",
      orderDirection: "ASC",
    })
      .then((res) => {
        setMunicipalities(res.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (
      schoolType != undefined &&
      ownedBy != undefined &&
      municipalityId != undefined
    ) {
      SchoolsRepository.fetchAllSchoolsByMunicipalityTypeOwnedBy({
        municipalityId: municipalityId,
        schoolType: schoolType,
        ownedBy: ownedBy,
      }).then(
        (res) => {
          setSchools(res.data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [schoolType, ownedBy, municipalityId]);

  const showLoadingBar = () => {
    setLoading(true);
    setBtnSubmitLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
    setBtnSubmitLoading(false);
  };

  const handleSubmit = (values) => {
    showLoadingBar();
    SchoolsRepository.createSchool(values)
      .then((res) => {
        hideLoadingBar();
        dispatch(triggerRerender());
        dispatch(
          notifyShowSuccessMessage(LOCALE.schools_create_success_message)
        );
        setRedirectTo("/app/configuration/schools");
      })
      .catch((err) => {
        hideLoadingBar();
      });
  };

  const onMuniciChange = (event) => {
    setMunicipalityId(event.target.value);
  };

  const onOwnedChange = (event) => {
    setOwnedBy(event.target.value);
  };

  const onTypeChange = (event) => {
    setschoolType(event.target.value);
  };

  const handleClose = () => {};
  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.schools_create_new_school}`}
              <Link
                to={`/app/configuration/schools/`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {loading ? <LinearProgress color="secondary" /> : ""}
            <div>
              <form onSubmit={createSchoolForm(handleSubmit)}>
                <DialogContent>
                  <TextField
                    id="name"
                    label={LOCALE.name}
                    error={errorsCreateSchool?.name}
                    helperText={errorsCreateSchool?.name?.message}
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                    name="name"
                    size="small"
                    inputRef={registerCreateForm}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    margin="dense"
                  >
                    <InputLabel>{LOCALE.municipality}</InputLabel>
                    <Controller
                      as={
                        <Select
                          label={LOCALE.municipality}
                          defaultValue=""
                          onChange={onMuniciChange}
                        >
                          <MenuItem value="">None</MenuItem>
                          {municipalities?.content.map((municipality) => (
                            <MenuItem
                              key={municipality.id}
                              value={municipality.id}
                            >
                              {municipality.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name="municipality.id"
                      control={control}
                      defaultValue=""
                    />
                  </FormControl>

                  <EntityMetadata data={getValues()} />
                </DialogContent>
                <DialogActions>
                  {!btnSubmitLoading && (
                    <Link
                      to={`/app/configuration/schools/`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button size="small" variant="outlined" color="secondary">
                        {LOCALE.cancel}
                      </Button>
                    </Link>
                  )}
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    disabled={btnSubmitLoading}
                    className={btnSubmitLoading ? "btn-loading" : ""}
                    color="primary"
                  >
                    {LOCALE.save}
                    {btnSubmitLoading && (
                      <CircularProgress
                        color="secondary"
                        size={20}
                        style={{ position: "absolute" }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
