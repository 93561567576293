import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  IconButton,
  Icon,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/layouts/appStyle";
// core components
import { MunicipalitiesRepository } from "./MunicipalitiesRepository";
import { LOCALE } from "../../../properties/Locale";
import EntityMetadata from "../../../common/components/EntityMetadata";
import CardBody from "../../../common/components/Card/CardBody";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";

const useStyles = makeStyles(styles);

export default function MunicipalityDetailsComponent(props) {
  const classes = useStyles();
  const [municipalityId] = useState(props.match.params.municipalityId);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadMunicipality = (id) => {
      setLoading(true);
      MunicipalitiesRepository.fetchMunicipality(id).then((res) => {
        setData(res.data);
        setLoading(false);
      });
    };

    loadMunicipality(municipalityId);
  }, [municipalityId]);

  return (
    <div>
      <Card>
        <Dialog
          open={true}
          maxWidth="md"
          fullWidth={true}
          onClose={() => {}}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.municipalities_details}${
                data?.username ? ": " + data?.username : ""
              }`}
              <Link
                to={`/app/configuration/municipalities/`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {loading ? <LinearProgress /> : ""}
            <div>
              <DialogContent>
                <TextField
                  id="name"
                  label={LOCALE.name}
                  fullWidth={true}
                  value={data?.name ? data?.name : ""}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="nameEn"
                  label={LOCALE.name_en}
                  fullWidth={true}
                  value={data?.nameEn ? data?.nameEn : ""}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="nameAl"
                  label={LOCALE.name_al}
                  fullWidth={true}
                  value={data?.nameAl ? data?.nameAl : ""}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="code"
                  label={LOCALE.code}
                  fullWidth={true}
                  value={data?.code ? data?.code : ""}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <EntityMetadata data={data} />
              </DialogContent>
              <DialogActions>
                <Link
                  to={`/app/configuration/municipalities/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button size="small" variant="outlined" color="secondary">
                    {LOCALE.close}
                  </Button>
                </Link>
              </DialogActions>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
