import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Icon,
  Tooltip,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/components/tableStyle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
// core components
import { LOCALE } from "../../../properties/Locale";
import { UsersRepository } from "./UsersRepository";
import { changePageTitle } from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles);

export default function UsersComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    totalElements: 0,
    number: 0,
    content: [],
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("dateCreated");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [filterBy, setFilterBy] = useState();
  const [filterValue, setFilterValue] = useState();
  const [filterData, setFilterData] = useState({ name: "" });
  const globalState = useSelector((state) => state);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.users));
    loadUsers(page, size, orderBy, orderDirection);
  }, [
    page,
    size,
    globalState.triggerRerender,
    filterValue,
    orderBy,
    orderDirection,
  ]);

  const loadUsers = (page, size, orderBy, orderDirection) => {
    UsersRepository.fetchAllUsers({
      page: page,
      size: size,
      orderBy: orderBy,
      orderDirection: orderDirection,
      filterBy: filterBy,
      filterValue: filterValue,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleFilterUserFieldChange = (name) => (event) => {
    setFilterData({ [name]: event.target.value });
    setFilterBy(name);
    setFilterValue(event.target.value);
  };
  const handleClickChangeOrderBy = (event) => {
    let orderValue = event.currentTarget.value;
    if (orderBy === orderValue) {
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
    } else {
      setOrderBy(orderValue);
      setOrderDirection("DESC");
    }
  };

  return (
    <div>
      <Card>
        <CardHeader color="theme">
          <Tooltip
            title={LOCALE.create_new_user}
            aria-label={LOCALE.create_new_user}
          >
            <Link
              to={`/app/admin/users/create`}
              style={{ textDecoration: "none" }}
            >
              <Button
                color="primary"
                size="small"
                startIcon={<Icon>person_add</Icon>}
                variant="contained"
                aria-label="create"
              >
                {LOCALE.create_new_user}
              </Button>
            </Link>
          </Tooltip>
        </CardHeader>
        <CardBody>
          <Table aria-label="users table" className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.username}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"username"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "username" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                  <TextField
                    id="input_filter_username"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterUserFieldChange("username")}
                    placeholder={`${
                      LOCALE.filter_by
                    } ${LOCALE.username.toLowerCase()}`}
                    value={filterData?.username ? filterData?.username : ""}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.firstLastName}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"name"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "name" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                  <TextField
                    id="input_filter_name"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterUserFieldChange("name")}
                    placeholder={`${
                      LOCALE.filter_by
                    } ${LOCALE.firstLastName.toLowerCase()}`}
                    value={filterData?.name ? filterData?.name : ""}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.email}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"email"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "email" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                  <TextField
                    id="input_filter_email"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterUserFieldChange("email")}
                    placeholder={`${
                      LOCALE.filter_by
                    } ${LOCALE.email.toLowerCase()}`}
                    value={filterData?.email ? filterData?.email : ""}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.role}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"type"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "type" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    disabled
                  >
                    <InputLabel>{LOCALE.role}</InputLabel>
                    <Select
                      label={LOCALE.role}
                      onChange={handleFilterUserFieldChange("type")}
                      value={filterData?.type ? filterData?.type : ""}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="ADMINISTRATOR">
                        {LOCALE.administrator}
                      </MenuItem>
                      <MenuItem value="COMMISSION_USER">
                        {LOCALE.commission}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.date_created}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"dateCreated"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "dateCreated" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                  <TextField
                    id="input_filter_date_created"
                    placeholder={`${
                      LOCALE.filter_by
                    } ${LOCALE.date_created.toLowerCase()}`}
                    margin="normal"
                    disabled={true}
                    style={{ display: "block" }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      marginBottom: "62px",
                      minWidth: "80px",
                      fontWeight: "bold",
                    }}
                  >
                    {LOCALE.actions}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content?.map((user, index) => (
                <TableRow
                  key={user.username}
                  className={classes.tableBodyRow}
                  style={index % 2 ? { background: "#f0f0f0" } : {}}
                >
                  <TableCell className={classes.tableCell}>
                    {user.username}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.email}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.type === "ADMINISTRATOR"
                      ? `${LOCALE.administrator}`
                      : user.type === "COMMISSION_USER"
                      ? `${LOCALE.commission}`
                      : ""}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {moment(user.dateCreated).format("YYYY-MM-DD HH:mm:ss")} (
                    {moment(user.dateCreated).fromNow()})
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      title={LOCALE.details}
                      aria-label={LOCALE.details}
                      style={{ color: "white" }}
                    >
                      <Link
                        to={`/app/admin/users/${user.id}/details`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          color="primary"
                          aria-label="details"
                        >
                          <Icon>ballot</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title={LOCALE.edit} aria-label={LOCALE.edit}>
                      <Link
                        to={`/app/admin/users/${user.id}/edit`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          style={{ color: "#F1C40F" }}
                          aria-label="edit"
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title={LOCALE.delete} aria-label={LOCALE.delete}>
                      <Link
                        to={`/app/admin/users/${user.id}/delete`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="delete"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={6}
                  count={data.totalElements}
                  rowsPerPage={size}
                  page={data.number}
                  labelRowsPerPage={LOCALE.rows_per_page}
                  SelectProps={{
                    inputProps: { "aria-label": LOCALE.rows_per_page },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
