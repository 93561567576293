import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  Tabs,
  Tab,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableFooter,
  TablePagination,
  IconButton,
  Icon,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/layouts/appStyle";
import tableStyles from "../../../assets/jss/components/tableStyle";
// core components
import { LOCALE } from "../../../properties/Locale";
import EntityMetadata from "../../../common/components/EntityMetadata";
import { GroupsRepository } from "./GroupsRepository";
import { notifyShowErrorMessage } from "../../../common/actions/CommonActions";
import CardBody from "../../../common/components/Card/CardBody";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";

const useStyles = makeStyles(styles, tableStyles);

export default function GroupDetailsComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [groupId] = useState(props.match.params.groupId);
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState(null);
  const [membersData, setMembersData] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [filterData, setFilterData] = useState({ name: "" });

  useEffect(() => {
    loadGroup(groupId);
  }, [groupId, filterValue]);

  const loadGroup = (id) => {
    setLoading(true);
    GroupsRepository.fetch(id)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        loadGroupMembers(res.data.id, 0, 5);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const loadGroupMembers = (id, page, size) => {
    setLoading(true);
    GroupsRepository.fetchMembers(
      id,
      page,
      size,
      null,
      null,
      filterBy,
      filterValue
    )
      .then((res) => {
        setLoading(false);
        setMembersData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleChangePage = (newPage) => {
    loadGroupMembers(data.id, newPage, data.size);
  };

  const handleChangeRowsPerPage = (event) => {
    loadGroupMembers(data.id, 0, parseInt(event.target.value, 5));
  };

  const handleFilterUserFieldChange = (name) => (event) => {
    setFilterData({ [name]: event.target.value });
    setFilterBy(name);
    setFilterValue(event.target.value);
    loadGroupMembers(data.id, 0, membersData.size);
  };

  return (
    <div>
      <Card>
        <Dialog
          open={true}
          onClose={() => {}}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.group_details}${data?.name ? ": " + data?.name : ""}`}
              <Link
                to={`/app/admin/groups/`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {loading ? <LinearProgress color="secondary" /> : ""}
            <div>
              <DialogContent>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label={LOCALE.details} />
                  <Tab label={LOCALE.members} />
                </Tabs>
                <br />

                {tabIndex === 0 && (
                  <div>
                    <TextField
                      id="name"
                      label={LOCALE.group_name}
                      fullWidth={true}
                      value={data?.name ? data?.name : ""}
                      margin="normal"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <EntityMetadata data={data} />
                  </div>
                )}

                {tabIndex === 1 && (
                  <div>
                    <Table aria-label="users table" className={classes.table}>
                      <TableHead className={classes.tableHeadRow}>
                        <TableRow>
                          <TableCell
                            className={
                              classes.tableCell + " " + classes.tableHeadCell
                            }
                          >
                            {LOCALE.username}
                            <TextField
                              id="input_filter_username"
                              margin="normal"
                              style={{ display: "block" }}
                              onChange={handleFilterUserFieldChange("username")}
                              placeholder={`${
                                LOCALE.filter_by
                              } ${LOCALE.username.toLowerCase()}`}
                              value={
                                filterData?.username ? filterData?.username : ""
                              }
                              variant="outlined"
                              size="small"
                            />
                          </TableCell>
                          <TableCell
                            className={
                              classes.tableCell + " " + classes.tableHeadCell
                            }
                          >
                            {LOCALE.firstLastName}
                            <TextField
                              id="input_filter_name"
                              margin="normal"
                              style={{ display: "block" }}
                              onChange={handleFilterUserFieldChange("name")}
                              placeholder={`${
                                LOCALE.filter_by
                              } ${LOCALE.firstLastName.toLowerCase()}`}
                              value={filterData?.name ? filterData?.name : ""}
                              variant="outlined"
                              size="small"
                            />
                          </TableCell>
                          <TableCell
                            className={
                              classes.tableCell + " " + classes.tableHeadCell
                            }
                          >
                            {LOCALE.email}{" "}
                            <TextField
                              id="input_filter_email"
                              margin="normal"
                              style={{ display: "block" }}
                              onChange={handleFilterUserFieldChange("email")}
                              placeholder={`${
                                LOCALE.filter_by
                              } ${LOCALE.email.toLowerCase()}`}
                              value={filterData?.email ? filterData?.email : ""}
                              variant="outlined"
                              size="small"
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {membersData?.content.map((user, index) => (
                          <TableRow
                            key={user.username}
                            className={classes.tableBodyRow}
                            style={index % 2 ? { background: "#f0f0f0" } : {}}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableCell}
                            >
                              {user.username}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {user.name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {user.email}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={5}
                            count={membersData?.totalElements}
                            rowsPerPage={membersData?.size}
                            page={membersData?.number}
                            labelRowsPerPage={LOCALE.rows_per_page}
                            SelectProps={{
                              inputProps: {
                                "aria-label": LOCALE.rows_per_page,
                              },
                              native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Link
                  to={`/app/admin/groups/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button size="small" variant="outlined" color="secondary">
                    {LOCALE.close}
                  </Button>
                </Link>
              </DialogActions>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
