import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  Tabs,
  Tab,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableFooter,
  TablePagination,
  IconButton,
  Icon,
  Tooltip,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import tableStyles from "../../../assets/jss/components/tableStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { LOCALE } from "../../../properties/Locale";
import EntityMetadata from "../../../common/components/EntityMetadata";
import { GroupsRepository } from "./GroupsRepository";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles, tableStyles);

export default function GroupEditComponent(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState(null);
  const [membersData, setMembersData] = useState(null);
  const [notMembersData, setNotMembersData] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [filterData, setFilterData] = useState({ name: "" });
  const [addMembersFilterBy, setAddMembersFilterBy] = useState(null);
  const [addMembersFilterValue, setAddMembersFilterValue] = useState(null);
  const [addMembersFilterData, setAddMembersFilterData] = useState({
    name: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    loadGroup(props.match.params.groupId);
    loadGroupNotMembers(props.match.params.groupId, 0, 5);
  }, []);

  useEffect(() => {
    loadGroup(props.match.params.groupId);
    loadGroupNotMembers(props.match.params.groupId, 0, 5);
  }, [filterValue, addMembersFilterValue]);

  const loadGroup = (id) => {
    setLoading(true);
    GroupsRepository.fetch(id)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        loadGroupMembers(res.data.id, 0, 5);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const loadGroupMembers = (id, page, size) => {
    setLoading(true);
    GroupsRepository.fetchMembers(
      id,
      page,
      size,
      null,
      null,
      filterBy,
      filterValue
    )
      .then((res) => {
        setLoading(false);
        setMembersData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const loadGroupNotMembers = (id, page, size) => {
    setLoading(true);
    GroupsRepository.fetchNotMembers(
      id,
      page,
      size,
      null,
      null,
      addMembersFilterBy,
      addMembersFilterValue
    )
      .then((res) => {
        setLoading(false);
        setNotMembersData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const handleClose = () => {};

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleChangePage = (event, newPage) => {
    loadGroupMembers(data.id, newPage, membersData.size);
  };

  const handleChangeRowsPerPage = (event) => {
    loadGroupMembers(data.id, 0, parseInt(event.target.value, 10));
  };

  const handleNotMembersChangePage = (event, newPage) => {
    loadGroupNotMembers(data.id, newPage, notMembersData.size);
  };

  const handleNotMembersChangeRowsPerPage = (event) => {
    loadGroupNotMembers(data.id, 0, parseInt(event.target.value, 10));
  };

  const handleFilterUserFieldChange = (name) => (event) => {
    setFilterData({ ...filterData, [name]: event.target.value });
    setFilterBy(name);
    setFilterValue(event.target.value);
    loadGroupMembers(data.id, 0, membersData.size);
  };

  const handleFilterAddMembersUserFieldChange = (name) => (event) => {
    setAddMembersFilterData({
      ...addMembersFilterData,
      [name]: event.target.value,
    });
    setAddMembersFilterBy(name);
    setAddMembersFilterValue(event.target.value);
    loadGroupNotMembers(data.id, 0, notMembersData.size);
  };

  const handleAddGroupMember = (userId) => {
    setLoading(true);
    GroupsRepository.addMember(data.id, userId)
      .then((res) => {
        setLoading(false);
        dispatch(notifyShowSuccessMessage(LOCALE.member_added_success_message));
        loadGroupNotMembers(
          data.id,
          notMembersData.number,
          notMembersData.size
        );
        loadGroupMembers(data.id, membersData.number, membersData.size);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
        setLoading(false);
      });
  };

  const handleRemoveGroupMember = (userId) => {
    setLoading(true);
    GroupsRepository.removeMember(data.id, userId)
      .then((res) => {
        setLoading(false);
        dispatch(
          notifyShowSuccessMessage(LOCALE.member_removed_success_message)
        );
        loadGroupNotMembers(data.id, membersData.number, membersData.size);
        loadGroupMembers(data.id, membersData.number, membersData.size);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
        setLoading(false);
      });
  };

  const handleGroupFormChange = (name) => (event) => {
    setData({ ...data, [name]: event.target.value });
  };

  const handleSubmit = () => {
    setLoading(true);
    GroupsRepository.update(data)
      .then((res) => {
        dispatch(triggerRerender());
        dispatch(notifyShowSuccessMessage(LOCALE.group_update_success_message));
        setRedirectTo("/app/admin/groups");
      })
      .catch((err) => {
        setLoading(false);
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.group_edit}${data?.name ? ": " + data?.name : ""}`}

              <Link
                to={`/app/admin/groups/`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {loading ? <LinearProgress color="secondary" /> : ""}
            <div>
              <DialogContent>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label={LOCALE.group_details} />
                  <Tab label={LOCALE.members} />
                  <Tab label={LOCALE.add_new_members} />
                </Tabs>
                <br />

                {tabIndex === 0 && (
                  <div>
                    <TextField
                      id="name"
                      label={LOCALE.group_name}
                      fullWidth={true}
                      onChange={handleGroupFormChange("name")}
                      value={data?.name ? data?.name : ""}
                      margin="normal"
                      variant="outlined"
                      size="small"
                    />
                    <EntityMetadata data={data} />
                  </div>
                )}

                {tabIndex === 1 && (
                  <div>
                    <Table aria-label="users table" className={classes.table}>
                      <TableHead className={classes.tableHeadRow}>
                        <TableRow>
                          <TableCell
                            className={
                              classes.tableCell + " " + classes.tableHeadCell
                            }
                          >
                            {LOCALE.username}
                            <TextField
                              id="input_filter_username"
                              margin="normal"
                              style={{ display: "block" }}
                              onChange={handleFilterUserFieldChange("username")}
                              placeholder={`${
                                LOCALE.filter_by
                              } ${LOCALE.username.toLowerCase()}`}
                              value={
                                filterData?.username ? filterData?.username : ""
                              }
                              variant="outlined"
                              size="small"
                            />
                          </TableCell>
                          <TableCell
                            className={
                              classes.tableCell + " " + classes.tableHeadCell
                            }
                          >
                            {LOCALE.firstLastName}
                            <TextField
                              id="input_filter_name"
                              margin="normal"
                              style={{ display: "block" }}
                              onChange={handleFilterUserFieldChange("name")}
                              placeholder={`${
                                LOCALE.filter_by
                              } ${LOCALE.firstLastName.toLowerCase()}`}
                              value={filterData?.name ? filterData?.name : ""}
                              variant="outlined"
                              size="small"
                            />
                          </TableCell>
                          <TableCell
                            className={
                              classes.tableCell + " " + classes.tableHeadCell
                            }
                          >
                            {LOCALE.email}{" "}
                            <TextField
                              id="input_filter_email"
                              margin="normal"
                              style={{ display: "block" }}
                              onChange={handleFilterUserFieldChange("email")}
                              placeholder={`${
                                LOCALE.filter_by
                              } ${LOCALE.email.toLowerCase()}`}
                              value={filterData?.email ? filterData?.email : ""}
                              variant="outlined"
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                marginBottom: "62px",
                                minWidth: "80px",
                              }}
                            >
                              {LOCALE.actions}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {membersData?.content.map((user, index) => (
                          <TableRow
                            key={user.username}
                            className={classes.tableBodyRow}
                            style={index % 2 ? { background: "#f0f0f0" } : {}}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableCell}
                            >
                              {user.username}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {user.name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {user.email}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Tooltip
                                aria-label={LOCALE.remove}
                                title={LOCALE.remove}
                              >
                                <IconButton
                                  color="secondary"
                                  size="small"
                                  onClick={() =>
                                    handleRemoveGroupMember(user.id)
                                  }
                                  aria-label="delete"
                                >
                                  <Icon>delete</Icon>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={6}
                            count={membersData?.totalElements}
                            rowsPerPage={membersData?.size}
                            page={membersData?.number}
                            labelRowsPerPage={LOCALE.rows_per_page}
                            SelectProps={{
                              inputProps: {
                                "aria-label": LOCALE.rows_per_page,
                              },
                              native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </div>
                )}
                {tabIndex === 2 && (
                  <div>
                    <Table
                      aria-label="add members table"
                      className={classes.table}
                    >
                      <TableHead className={classes.tableHeadRow}>
                        <TableRow>
                          <TableCell
                            className={
                              classes.tableCell + " " + classes.tableHeadCell
                            }
                          >
                            {LOCALE.username}
                            <TextField
                              id="input_filter_not_members_username"
                              margin="normal"
                              style={{ display: "block" }}
                              onChange={handleFilterAddMembersUserFieldChange(
                                "username"
                              )}
                              placeholder={`${
                                LOCALE.filter_by
                              } ${LOCALE.username.toLowerCase()}`}
                              value={
                                addMembersFilterData?.username
                                  ? addMembersFilterData?.username
                                  : ""
                              }
                              variant="outlined"
                              size="small"
                            />
                          </TableCell>
                          <TableCell
                            className={
                              classes.tableCell + " " + classes.tableHeadCell
                            }
                          >
                            {LOCALE.firstLastName}
                            <TextField
                              id="input_filter_not_members_name"
                              margin="normal"
                              style={{ display: "block" }}
                              onChange={handleFilterAddMembersUserFieldChange(
                                "name"
                              )}
                              placeholder={`${
                                LOCALE.filter_by
                              } ${LOCALE.firstLastName.toLowerCase()}`}
                              value={
                                addMembersFilterData?.name
                                  ? addMembersFilterData?.name
                                  : ""
                              }
                              variant="outlined"
                              size="small"
                            />
                          </TableCell>
                          <TableCell
                            className={
                              classes.tableCell + " " + classes.tableHeadCell
                            }
                          >
                            {LOCALE.email}{" "}
                            <TextField
                              id="input_filter_not_members_email"
                              margin="normal"
                              style={{ display: "block" }}
                              onChange={handleFilterAddMembersUserFieldChange(
                                "email"
                              )}
                              placeholder={`${
                                LOCALE.filter_by
                              } ${LOCALE.email.toLowerCase()}`}
                              value={
                                addMembersFilterData?.email
                                  ? addMembersFilterData?.email
                                  : ""
                              }
                              variant="outlined"
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                marginBottom: "62px",
                                minWidth: "80px",
                              }}
                            >
                              {LOCALE.actions}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {notMembersData?.content.map((user, index) => (
                          <TableRow
                            key={user.username}
                            className={classes.tableBodyRow}
                            style={index % 2 ? { background: "#f0f0f0" } : {}}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableCell}
                            >
                              {user.username}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {user.name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {user.email}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Tooltip
                                title={LOCALE.add}
                                aria-label={LOCALE.add}
                              >
                                <IconButton
                                  size="small"
                                  color="primary"
                                  aria-label="details"
                                  onClick={() => handleAddGroupMember(user.id)}
                                >
                                  <Icon>person_add</Icon>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={5}
                            count={notMembersData?.totalElements}
                            rowsPerPage={notMembersData?.size}
                            page={notMembersData?.number}
                            labelRowsPerPage={LOCALE.rows_per_page}
                            SelectProps={{
                              inputProps: {
                                "aria-label": LOCALE.rows_per_page,
                              },
                              native: true,
                            }}
                            onChangePage={handleNotMembersChangePage}
                            onChangeRowsPerPage={
                              handleNotMembersChangeRowsPerPage
                            }
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Link
                  to={`/app/admin/groups/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button size="small" variant="outlined" color="secondary">
                    {LOCALE.close}
                  </Button>
                </Link>
                <Button
                  size="small"
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  {LOCALE.save}
                </Button>
              </DialogActions>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
