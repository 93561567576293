import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const validateUserProfile = yup.object().shape({
  name: yup.string().required(LOCALE.login_firstname + LOCALE.required_field),
  surname: yup.string().required(LOCALE.login_lastname + LOCALE.required_field),
  username: yup
    .string()
    .required(LOCALE.login_username + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.login_email + LOCALE.required_field)
    .email(LOCALE.login_email + LOCALE.required_field),
});

export const validateChangePasswordUserProfile = yup.object().shape({
  password: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field),
  confirmPassword: yup
    .string()
    .required(LOCALE.login_confirm_password + LOCALE.required_field)
    .oneOf([yup.ref("password"), null], LOCALE.password_doesnt_match),
});
