import React from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { changePageTitle } from "../../common/actions/CommonActions";
import { LOCALE } from "../../properties/Locale";

export default function DashboardComponent() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(changePageTitle(LOCALE.dashboard));
  }, []);

  return (
    <div>
      <Grid container spacing={2}></Grid>
    </div>
  );
}
