import React, { useState } from "react";
import {
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogContentText,
  Tab,
  Typography,
} from "@material-ui/core";
import { LOCALE } from "../../properties/Locale";
import { SETTINGS } from "../../properties/ApiProps";

export default function DialogComponent(props) {
  const handleClose = () => {
    props.closeDialog();
  };

  return (
    <React.Fragment>
      <Dialog
        open={true}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent dividers={true}>
          {props.dialogType === "schoolSupplies" && (
            <Table>
              <TableBody>
                {/* <TableRow>
                  <TableCell>
                    Уредба со законска сила за доделување на финансиски поддршка
                    на ученици запишани во јавните средни училишта за купување
                    на училишен прибор, а кои се од семејни домаќинства со низок
                    доход, за време на вонредна состојба
                  </TableCell>
                  <TableCell>
                    <a
                      target="blank"
                      href={`http://mon.gov.mk/stored/document/Uredba_za_ucenici_od_javni_sredni_ucilista.pdf`}
                    >
                      <IconButton
                        size="small"
                        color="secondary"
                        aria-label="Download"
                      >
                        <Icon>cloud_download</Icon>
                      </IconButton>
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.USE_OF_PERSONAL_DATA_PROOF}</TableCell>
                  <TableCell>
                    <a
                      target="blank"
                      href={`Izjava_za_licni_podatoci_ucilisen_pribor.doc`}
                    >
                      <IconButton
                        size="small"
                        color="secondary"
                        aria-label="Download"
                      >
                        <Icon>cloud_download</Icon>
                      </IconButton>
                    </a>
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell>{LOCALE.results}</TableCell>
                  <TableCell>
                    <a target="blank" href={`Results.pdf`}>
                      <IconButton
                        size="small"
                        color="secondary"
                        aria-label="Download"
                      >
                        <Icon>cloud_download</Icon>
                      </IconButton>
                    </a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          {props.dialogType === "studyCosts" && (
            <div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {LOCALE.WAY_OF_ATTENDING_CLASSES_PROOF}
                    </TableCell>
                    <TableCell>
                      <a
                        target="blank"
                        href={`Izjava_za_nachin_na_sledenje_na_nastava.doc`}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.USE_OF_PERSONAL_DATA_PROOF}</TableCell>
                    <TableCell>
                      <a
                        target="blank"
                        href={`Izjava_trosoci_za studiranje.doc`}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {LOCALE.FINANCIAL_STATUS_OF_FAMILY_PROOF}
                    </TableCell>
                    <TableCell>
                      <a target="blank" href={`Izjava_za_semejna_sostojba.doc`}>
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.UPPI}</TableCell>
                    <TableCell>
                      <a target="blank" href={`Obrazec_uppi.doc`}>
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
          {props.dialogType === "studentDoorms" && (
            <div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {LOCALE.WAY_OF_ATTENDING_CLASSES_PROOF}
                    </TableCell>
                    <TableCell>
                      <a
                        target="blank"
                        href={`Izjava_za_nachin_na_sledenje_na_nastava.doc`}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.USE_OF_PERSONAL_DATA_PROOF}</TableCell>
                    <TableCell>
                      <a
                        target="blank"
                        href={`Izjava_za_licni_podatoci_participacija_za_smestuvanje_vo_studentski_dom.doc`}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {LOCALE.FINANCIAL_STATUS_OF_FAMILY_PROOF}
                    </TableCell>
                    <TableCell>
                      <a target="blank" href={`Izjava_za_semejna_sostojba.doc`}>
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.UPPI}</TableCell>
                    <TableCell>
                      <a target="blank" href={`Obrazec_uppi.doc`}>
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
          {props.dialogType === "studentPrivateAccommodation" && (
            <div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {LOCALE.WAY_OF_ATTENDING_CLASSES_PROOF}
                    </TableCell>
                    <TableCell>
                      <a
                        target="blank"
                        href={`Izjava_za_nachin_na_sledenje_na_nastava.doc`}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.USE_OF_PERSONAL_DATA_PROOF}</TableCell>
                    <TableCell>
                      <a
                        target="blank"
                        href={`Izjava_za_licni_podatoci_participacija_za_privatno_smestuvanje.doc`}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {LOCALE.FINANCIAL_STATUS_OF_FAMILY_PROOF}
                    </TableCell>
                    <TableCell>
                      <a target="blank" href={`Izjava_za_semejna_sostojba.doc`}>
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.UPPI}</TableCell>
                    <TableCell>
                      <a target="blank" href={`Obrazec_uppi.doc`}>
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
          {props.dialogType === "transportCosts" && (
            <div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {LOCALE.WAY_OF_ATTENDING_CLASSES_PROOF}
                    </TableCell>
                    <TableCell>
                      <a
                        target="blank"
                        href={`Izjava_za_nachin_na_sledenje_na_nastava.doc`}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.USE_OF_PERSONAL_DATA_PROOF}</TableCell>
                    <TableCell>
                      <a
                        target="blank"
                        href={`Izjava_za_licni_podatoci_participacija_za_prevoz.doc`}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {LOCALE.FINANCIAL_STATUS_OF_FAMILY_PROOF}
                    </TableCell>
                    <TableCell>
                      <a target="blank" href={`Izjava_za_semejna_sostojba.doc`}>
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.UPPI}</TableCell>
                    <TableCell>
                      <a target="blank" href={`Obrazec_uppi.doc`}>
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="Download"
                        >
                          <Icon>cloud_download</Icon>
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
          {props.dialogType === "privacyPolicy" && (
            <div>
              <Typography variant="h6">
                Со поднесување на оваа апликација:
              </Typography>
              <ul>
                <li>
                  Потврдувам дека сум државјанин на Република Северна Македонија
                </li>
                <br></br>
                <li>
                  Потврдувам под морална, материјална и кривична одговорност
                  дека податоците прикажани/прикачени во електронската
                  апликација се во целост вистинити и одговараат на реалната
                  состојба.
                  <br></br> Во спортивно се согласувам пријавата да се смета за
                  невалидна
                </li>
              </ul>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {LOCALE.close}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
