import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import {
  TablePagination,
  TableRow,
  TableFooter,
  Icon,
  IconButton,
  Tooltip,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Button,
} from "@material-ui/core";
// @material-ui/styles
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/components/tableStyle";
import { LOCALE } from "../../../properties/Locale";
import { GroupsRepository } from "./GroupsRepository";
import { changePageTitle } from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles);

export default function GroupsComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    totalElements: 0,
    number: 0,
    content: [],
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("dateCreated");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const globalState = useSelector((state) => state);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.groups));
    loadGroups(page, size, orderBy, orderDirection);
  }, [page, size, globalState.triggerRerender, orderBy, orderDirection]);

  const loadGroups = (
    page,
    size,
    orderBy,
    orderDirection,
    filterBy = null,
    filterValue = null
  ) => {
    GroupsRepository.fetchAll({
      page: page,
      size: size,
      orderBy: orderBy,
      orderDirection: orderDirection,
      filterBy: filterBy,
      filterValue: filterValue,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleClickChangeOrderBy = (event) => {
    let orderValue = event.currentTarget.value;
    if (orderBy === orderValue) {
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
    } else {
      setOrderBy(orderValue);
      setOrderDirection("DESC");
    }
  };

  return (
    <div>
      <Card>
        <CardHeader color="theme">
          <Tooltip
            title={LOCALE.create_new_group}
            aria-label={LOCALE.create_new_group}
            arrow
            placement={"bottom"}
          >
            <Link
              to={`/app/admin/groups/create`}
              style={{ textDecoration: "none" }}
            >
              <Button
                color="primary"
                variant="contained"
                startIcon={<Icon>add_circle_outline</Icon>}
                size="small"
                aria-label="create"
              >
                {LOCALE.create_new_group}
              </Button>
            </Link>
          </Tooltip>
        </CardHeader>
        <CardBody>
          <Table aria-label="groups table" className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.group_name}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"name"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "name" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.date_created}
                  <IconButton
                    style={{ width: "26px", height: "26px", padding: "0px" }}
                    value={"dateCreated"}
                    onClick={handleClickChangeOrderBy}
                  >
                    {orderBy === "dateCreated" ? (
                      orderDirection == "DESC" ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell
                  style={{
                    marginBottom: "62px",
                    minWidth: "80px",
                    fontWeight: "bold",
                    width: "100px",
                  }}
                >
                  {LOCALE.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content.map((group, index) => (
                <TableRow
                  key={group.id}
                  className={classes.tableBodyRow}
                  style={index % 2 ? { background: "#f0f0f0" } : {}}
                >
                  <TableCell className={classes.tableCell}>
                    {group.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {moment(group.dateCreated).format("YYYY-MM-DD HH:mm:ss")} (
                    {moment(group.dateCreated).fromNow()})
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip title={LOCALE.details} aria-label={LOCALE.details}>
                      <Link
                        to={`/app/admin/groups/${group.id}/details`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          color="primary"
                          aria-label="details"
                        >
                          <Icon>ballot</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title={LOCALE.edit} aria-label={LOCALE.edit}>
                      <Link
                        to={`/app/admin/groups/${group.id}/edit`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          style={{ color: "orange" }}
                          aria-label="edit"
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title={LOCALE.delete} aria-label={LOCALE.delete}>
                      <Link
                        to={`/app/admin/groups/${group.id}/delete`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          color="secondary"
                          size="small"
                          aria-label="delete"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={6}
                  count={data.totalElements}
                  rowsPerPage={size}
                  page={data.number}
                  labelRowsPerPage={LOCALE.rows_per_page}
                  SelectProps={{
                    inputProps: { "aria-label": LOCALE.rows_per_page },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
