import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const PrivilegesRepository = {
  fetch: id => {
    return Axios.get(`${SETTINGS.API_URL}privileges/${id}`);
  },
  fetchPrivilegeGroups: id => {
    return Axios.get(`${SETTINGS.API_URL}privileges/${id}/groups`);
  },
  fetchPrivilegeNotMemberGroups: id => {
    return Axios.get(`${SETTINGS.API_URL}privileges/${id}/notMemberGroups`);
  },
  addPrivilegeToGroup: (privilegeId, groupId) => {
    return Axios({
      url: `${SETTINGS.API_URL}privileges/${privilegeId}/groups`,
      method: "PUT",
      params: {
        groupId: groupId
      }
    });
  },
  removePrivilegeFromGroup: (privilegeId, groupId) => {
    return Axios({
      url: `${SETTINGS.API_URL}privileges/${privilegeId}/groups`,
      method: "DELETE",
      params: {
        groupId: groupId
      }
    });
  },
  fetchAll: () => {
    return Axios.get(`${SETTINGS.API_URL}privileges`);
  }
};
