import Axios from "axios";
import { SETTINGS } from "../../properties/ApiProps";

export const EvoucherAppRepository = {
  getAllSchoolSupplies: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/schoolSupplie`,
      method: "GET",
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterBy: payload.filterBy,
        filterValue: payload.filterValue,
      },
    });
  },

  getSchoolSupplie: (id) => {
    return Axios.get(`${SETTINGS.API_URL}voucherRequest/schoolSupplie/${id}`);
  },

  updateSchoolSupplieAppStatus: (id, status) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/schoolSupplies/status`,
      method: "PUT",
      params: { id: id, status: status },
    });
  },

  getAllStudyCostsApp: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/studyCosts`,
      method: "GET",
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterBy: payload.filterBy,
        filterValue: payload.filterValue,
      },
    });
  },

  getStudyCostsApp: (id) => {
    return Axios.get(`${SETTINGS.API_URL}voucherRequest/studyCosts/${id}`);
  },

  updateStudyCostAppStatus: (id, status) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/studyCosts/status`,
      method: "PUT",
      params: { id: id, status: status },
    });
  },

  getAllTransportCostsApp: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/transportCosts`,
      method: "GET",
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterBy: payload.filterBy,
        filterValue: payload.filterValue,
      },
    });
  },

  getTransportCostsApp: (id) => {
    return Axios.get(`${SETTINGS.API_URL}voucherRequest/transportCosts/${id}`);
  },

  updateTransportCostAppStatus: (id, status) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/transportCosts/status`,
      method: "PUT",
      params: { id: id, status: status },
    });
  },

  getAllStudentsDoormsApp: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/studentsDoorms`,
      method: "GET",
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterBy: payload.filterBy,
        filterValue: payload.filterValue,
      },
    });
  },

  getStudentsDoormsApp: (id) => {
    return Axios.get(`${SETTINGS.API_URL}voucherRequest/studentsDoorms/${id}`);
  },

  updateStudentsDoormsAppStatus: (id, status) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/studentDoorms/status`,
      method: "PUT",
      params: { id: id, status: status },
    });
  },

  getAllPrivateAccommodationApp: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/privateAccommodation`,
      method: "GET",
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterBy: payload.filterBy,
        filterValue: payload.filterValue,
      },
    });
  },

  updatePrivateAccommodationAppStatus: (id, status) => {
    return Axios({
      url: `${SETTINGS.API_URL}voucherRequest/studentPrivateAccommodation/status`,
      method: "PUT",
      params: { id: id, status: status },
    });
  },

  getPrivateAccommodationApp: (id) => {
    return Axios.get(
      `${SETTINGS.API_URL}voucherRequest/privateAccommodation/${id}`
    );
  },
};
