import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./common/reducers/MainReducer";
import httpService from "./api/HttpClient";
import { Route, Redirect, BrowserRouter } from "react-router-dom";
import LoginComponent from "./modules/login/LoginComponent";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ForbidenComponent from "./modules/error/ForbidenComponent";
import EVoucherComponent from "./modules/eVoucher/eVoucherComponent";
import SchoolSuppliesFormComponent from "./modules/eVoucher/schoolSuppliesFormComponent";
import StudentPrivateAccommodationComponent from "./modules/eVoucher/studentPrivateAccommodationComponent";
import StudentsDoormsFormComponent from "./modules/eVoucher/studentsDoormsFormComponent";
import StudyCostsFormComponent from "./modules/eVoucher/studyCostsFormComponent";
import DoneComponent from "./modules/eVoucher/doneComponent";
import ObjectionComponent from "./modules/eVoucher/ObjectionComponent";
import TransportCostsFormComponent from "./modules/eVoucher/transportCostsFromComponent";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#2b84c0",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#C0392B",
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const store = createStore(reducer);
httpService.setupInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* PUBLICK ROUTES */}
      <Route path="/" exact={true} component={EVoucherComponent}></Route>
      {/* <Route
        path="/schoolSupplies"
        component={SchoolSuppliesFormComponent}
      ></Route> */}
      <Route
        path="/privateAccommodation"
        component={StudentPrivateAccommodationComponent}
      ></Route>
      <Route
        path="/studentsDoorm"
        component={StudentsDoormsFormComponent}
      ></Route>
      <Route path="/studyCosts" component={StudyCostsFormComponent}></Route>
      {/* <Route
        path="/transportCosts"
        component={TransportCostsFormComponent}
      ></Route> */}
      <Route path="/objection" component={ObjectionComponent}></Route>
      <Route path="/done" component={DoneComponent}></Route>

      {/* ======================= LOGIN/SECURITY ROUTES ======================= */}
      <Route path="/login" component={LoginComponent} />

      <Route
        path="/app"
        render={() =>
          !localStorage.getItem("accessToken") &&
          !localStorage.getItem("currentUser") ? (
            <Redirect to="/" />
          ) : (
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          )
        }
      />
      {/* ======================= ERRORS ROUTE ======================= */}
      <Route exact path="/error/403" component={ForbidenComponent} />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
