import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  TablePagination,
  TableRow,
  TableFooter,
  Icon,
  IconButton,
  Tooltip,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Chip,
  Tabs,
  Tab,
  Dialog,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import tableStyles from "../../../assets/jss/components/tableStyle";
import styles from "../../../assets/jss/layouts/appStyle";
// core components
import { LOCALE } from "../../../properties/Locale";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  changePageTitle,
} from "../../../common/actions/CommonActions";
import { MailRepository } from "./MailRepository";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useTableStyles = makeStyles(tableStyles);
const useStyles = makeStyles(styles);

export default function MailLogComponent() {
  const classesTable = useTableStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({ totalElements: 0, number: 0 });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [dialog, setDialog] = useState({
    dialogOpen: false,
    dialogTitle: null,
    dialogData: null,
    dialogType: null,
  });
  const [tabIndex, setTabIndex] = useState(0);
  const [filterStatus, setFilterStatus] = useState(null);
  const [statuses] = useState([
    "ALL",
    "SUCCESS",
    "PENDING",
    "PROCESSING",
    "FAILED",
    "CANCELED",
  ]);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.mail_log));
    loadMailLog(page, size, filterStatus);
  }, [page, size, filterStatus]);

  const loadMailLog = (page, size, filterStatus) => {
    MailRepository.fetchAll(page, size, filterStatus)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const handleTabChange = (event, newTabIndex) => {
    let status = statuses[newTabIndex];
    if (newTabIndex === 0) status = null;
    setTabIndex(newTabIndex);
    setFilterStatus(status);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleRequeue = (id) => {
    MailRepository.requeue(id)
      .then((res) => {
        dispatch(notifyShowSuccessMessage("Mail requeued successfully"));
        loadMailLog(data.number, data.size, filterStatus);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const handleCancel = (id) => {
    MailRepository.cancel(id)
      .then((res) => {
        dispatch(notifyShowSuccessMessage("Mail canceled successfully"));
        loadMailLog(data.number, data.size, filterStatus);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const handleCloseDialog = () => {
    setDialog({
      dialogOpen: false,
      dialogTitle: "",
      dialogData: null,
    });
  };

  const handleOpenDialog = (type, dialogTitle, data) => {
    setDialog({
      dialogTitle: dialogTitle,
      dialogOpen: true,
      dialogData: data,
      dialogType: type,
    });
  };

  return (
    <div>
      <Card>
        <CardHeader color="theme"></CardHeader>
        <CardBody>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label={LOCALE.mail_all} />
            <Tab label={LOCALE.mail_success} />
            <Tab label={LOCALE.mail_pending} />
            <Tab label={LOCALE.mail_processing} />
            <Tab label={LOCALE.mail_failed} />
            <Tab label={LOCALE.mail_canceled} />
          </Tabs>
          <Table aria-label="audit table" className={classesTable.table}>
            <TableHead>
              <TableRow className={classesTable.tableHeadRow}>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.mail_sender}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.mail_receiver}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.mail_createdAt}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.mail_sentAt}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.mail_status}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.mail_details}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.mail_requeued}
                </TableCell>
                <TableCell
                  className={
                    classesTable.tableCell + " " + classesTable.tableHeadCell
                  }
                >
                  {LOCALE.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content &&
                data?.content.map((mailMessage, index) => (
                  <TableRow
                    key={mailMessage.id}
                    className={classesTable.tableBodyRow}
                    style={index % 2 ? { background: "#f0f0f0" } : {}}
                  >
                    <TableCell className={classesTable.tableCell}>
                      {mailMessage.sender}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {mailMessage.receiver}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {moment(mailMessage.dateCreated).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ) +
                        " " +
                        "(" +
                        moment(mailMessage.dateCreated).fromNow() +
                        ")"}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {moment(mailMessage.dateSent).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ) +
                        " " +
                        "(" +
                        moment(mailMessage.dateSent).fromNow() +
                        ")"}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {mailMessage.status === "PENDING" ? (
                        <Chip
                          label="PENDING"
                          style={{
                            backgroundColor: "darkorange",
                            color: "white",
                          }}
                        />
                      ) : mailMessage.status === "PROCESSING" ? (
                        <Chip label="PROCESSING" color="primary" />
                      ) : mailMessage.status === "SUCCESS" ? (
                        <Chip
                          label="SUCCESS"
                          style={{
                            backgroundColor: "green",
                            color: "white",
                          }}
                        />
                      ) : mailMessage.status === "FAILED" ? (
                        <Chip label="FAILED" color="secondary" />
                      ) : mailMessage.status === "CANCELED" ? (
                        <Chip
                          label="CANCELED"
                          style={{
                            backgroundColor: "darkgrey",
                            color: "white",
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      {mailMessage.status === "FAILED" && (
                        <Tooltip title={LOCALE.mail_error_details}>
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleOpenDialog(
                                "mail_error_details",
                                LOCALE.mail_error_log,
                                mailMessage.failureReason
                              )
                            }
                            color="secondary"
                          >
                            <Icon>error</Icon>
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      <Chip
                        style={{ fontWeight: "bold" }}
                        label={mailMessage.requeued}
                      />
                    </TableCell>
                    <TableCell className={classesTable.tableCell}>
                      <Tooltip title={LOCALE.mail_view_mail_content}>
                        <IconButton
                          variant="contained"
                          style={{ color: "darkorange" }}
                          onClick={() =>
                            handleOpenDialog(
                              "mail_details",
                              LOCALE.mail_mail_details,
                              mailMessage
                            )
                          }
                        >
                          <Icon>contact_mail</Icon>
                        </IconButton>
                      </Tooltip>
                      &nbsp;
                      <Tooltip title={LOCALE.mail_requeue}>
                        <IconButton
                          color="primary"
                          variant="contained"
                          disabled={mailMessage.status === "PENDING"}
                          onClick={() => handleRequeue(mailMessage.id)}
                        >
                          <Icon>send</Icon>
                        </IconButton>
                      </Tooltip>
                      &nbsp;
                      <Tooltip title={LOCALE.cancel}>
                        <IconButton
                          color="secondary"
                          variant="contained"
                          disabled={
                            !["PENDING", "FAILED"].includes(mailMessage.status)
                          }
                          onClick={() => handleCancel(mailMessage.id)}
                        >
                          <Icon>cancel_schedule_send</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={8}
                  count={data.totalElements}
                  rowsPerPage={size}
                  page={data.number}
                  labelRowsPerPage={LOCALE.rows_per_page}
                  SelectProps={{
                    inputProps: { "aria-label": LOCALE.rows_per_page },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          <ErrorDialogDetails
            data={dialog.dialogData}
            dialogType={dialog.dialogType}
            dialogTitle={dialog.dialogTitle}
            dialogOpen={dialog.dialogOpen}
            handleCloseDialog={handleCloseDialog}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export function ErrorDialogDetails(props) {
  const classes = useStyles();
  return (
    <div>
      <Card>
        <Dialog
          open={props.dialogOpen}
          onClose={props.handleCloseDialog}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {props.dialogTitle}
              <IconButton
                style={{ float: "right", color: "white" }}
                onClick={props.handleCloseDialog}
                aria-label="close"
              >
                <Icon>close</Icon>
              </IconButton>
            </h4>
          </CardHeader>
          <CardBody>
            {props.dialogType === "mail_details" && (
              <Table>
                <TableRow>
                  <TableCell>{LOCALE.mail_sender}</TableCell>
                  <TableCell>{props.data?.sender}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.mail_receiver}</TableCell>
                  <TableCell>{props.data?.receiver}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.mail_subject}</TableCell>
                  <TableCell>{props.data?.subject}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.mail_content}</TableCell>
                  <TableCell>
                    <span
                      className="preline"
                      dangerouslySetInnerHTML={{ __html: props.data?.content }}
                    ></span>
                  </TableCell>
                </TableRow>
              </Table>
            )}
            {props.dialogType === "mail_error_details" && (
              <h4 className="preline">{props?.data}</h4>
            )}
            <br />
            <br />
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
