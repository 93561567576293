import React, { useState, useEffect } from "react";
import moment, { locale } from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Icon,
  Tooltip,
  TextField,
  Button,
  Checkbox,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/components/tableStyle";
import { LOCALE } from "../../../properties/Locale";
import { SchoolsRepository } from "./SchoolsRepository";
import { changePageTitle } from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles);

export default function SchoolsComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    page: null,
    size: null,
    content: [],
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState();
  const [orderDirection, setOrderDirection] = useState();
  const [filterBy, setFilterBy] = useState();
  const [filterValue, setFilterValue] = useState();
  const [filterData, setFilterData] = useState({ name: "" });
  const globalState = useSelector((state) => state);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.schools));
    loadSchools(page, size, orderBy, orderDirection);
  }, [
    page,
    size,
    globalState.triggerRerender,
    filterValue,
    orderBy,
    orderDirection,
  ]);

  const loadSchools = (page, size, orderBy = null, orderDirection = null) => {
    SchoolsRepository.fetchAllSchools({
      page: page,
      size: size,
      orderBy: orderBy,
      orderDirection: orderDirection,
      filterProperty: filterBy,
      filterValue: filterValue,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, size));
  };

  const handleFilterSchoolFieldChange = (name) => (event) => {
    setFilterData({ [name]: event.target.value });
    setFilterBy(name);
    setFilterValue(event.target.value);
  };

  const handleClickChangeOrderBy = (event) => {
    let orderValue = event.currentTarget.value;
    if (orderBy === orderValue) {
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
    } else {
      setOrderBy(orderValue);
      setOrderDirection("DESC");
    }
  };
  return (
    <div>
      <Card>
        <CardHeader color="theme">
          <TableContainer>
            <Tooltip
              title={LOCALE.schools_create_new_school}
              aria-label={LOCALE.schools_create_new_school}
            >
              <Link
                to={`/app/configuration/schools/create`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="primary"
                  size="small"
                  startIcon={<Icon>person_add</Icon>}
                  variant="contained"
                  aria-label="create"
                >
                  {LOCALE.schools_create_new_school}
                </Button>
              </Link>
            </Tooltip>
          </TableContainer>
        </CardHeader>
        <CardBody>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.name}
                  <TextField
                    id="input_filter_name"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterSchoolFieldChange("name")}
                    value={filterData?.name ? filterData?.name : ""}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.email}
                  <TextField
                    id="input_filter_email"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterSchoolFieldChange("email")}
                    value={filterData?.email ? filterData?.email : ""}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  {LOCALE.municipality}
                  <TextField
                    id="input_filter_municipality"
                    margin="normal"
                    style={{ display: "block" }}
                    onChange={handleFilterSchoolFieldChange("municipality")}
                    value={
                      filterData?.municipality ? filterData?.municipality : ""
                    }
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      marginBottom: "62px",
                      minWidth: "80px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {LOCALE.actions}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content.map((school, index) => (
                <TableRow
                  key={school.name}
                  className={classes.tableBodyRow}
                  style={index % 2 ? { background: "#f0f0f0" } : {}}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableCell}
                  >
                    {school.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {school.email}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {window.localStorage.locale === "mk"
                      ? school.municipality.name
                      : window.localStorage.locale === "en"
                      ? school.municipality.nameEn
                      : school.municipality.nameAl}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: "100px", textAlign: "center" }}
                  >
                    <Tooltip title={LOCALE.details} aria-label={LOCALE.details}>
                      <Link
                        to={`/app/configuration/schools/${school.id}/details`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          color="primary"
                          aria-label="details"
                        >
                          <Icon>ballot</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title={LOCALE.edit} aria-label={LOCALE.edit}>
                      <Link
                        to={`/app/configuration/schools/${school.id}/edit`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          style={{ color: "#F1C40F" }}
                          aria-label="edit"
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title={LOCALE.delete} aria-label={LOCALE.delete}>
                      <Link
                        to={`/app/configuration/schools/${school.id}/delete`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          aria-label="delete"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={6}
                  count={data.totalElements}
                  rowsPerPage={size}
                  page={data.number}
                  labelRowsPerPage={LOCALE.rows_per_page}
                  SelectProps={{
                    inputProps: { "aria-label": LOCALE.rows_per_page },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
