import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  TableRow,
  Icon,
  IconButton,
  Tooltip,
  TableCell,
  TableBody,
  TableHead,
  Table,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import tableStyles from "../../../assets/jss/components/tableStyle";
import styles from "../../../assets/jss/layouts/appStyle";
// core components
import { LOCALE } from "../../../properties/Locale";
import { PrivilegesRepository } from "./PrivilegesRepository";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  changePageTitle,
} from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles, tableStyles);

export default function PrivilegesComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [privilege, setPrivilege] = useState(null);
  const [membersData, setMembersData] = useState(null);
  const [notMembersData, setNotMembersData] = useState(null);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.privileges));
    loadPrivileges();
  }, []);

  const loadPrivileges = () => {
    PrivilegesRepository.fetchAll()
      .then((res) => {
        setData(res.data);
        loadPrivilege(res.data[0].id);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const loadPrivilege = (id) => {
    PrivilegesRepository.fetch(id)
      .then((res) => {
        setPrivilege(res.data);
        loadPrivilegeMemberGroups(res.data.id);
        loadPrivilegeNotMemberGroups(res.data.id);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const loadPrivilegeMemberGroups = (id) => {
    PrivilegesRepository.fetchPrivilegeGroups(id)
      .then((res) => {
        setMembersData(res.data);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const loadPrivilegeNotMemberGroups = (id) => {
    PrivilegesRepository.fetchPrivilegeNotMemberGroups(id)
      .then((res) => {
        setNotMembersData(res.data);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const handleSelectPrivilege = (event, privilegeId) => {
    loadPrivilege(privilegeId);
  };

  const handleAddGroupToPrivilege = (privilegeId, groupId) => {
    PrivilegesRepository.addPrivilegeToGroup(privilegeId, groupId)
      .then((res) => {
        dispatch(notifyShowSuccessMessage(LOCALE.group_added_success_message));
        loadPrivilegeMemberGroups(privilege.id);
        loadPrivilegeNotMemberGroups(privilege.id);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const handleRemoveGroupToPrivilege = (privilegeId, groupId) => {
    PrivilegesRepository.removePrivilegeFromGroup(privilegeId, groupId)
      .then((res) => {
        dispatch(
          notifyShowSuccessMessage(LOCALE.group_removed_success_message)
        );
        loadPrivilegeMemberGroups(privilege.id);
        loadPrivilegeNotMemberGroups(privilege.id);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Card>
            <CardHeader color="theme">
              <h4 className={classes.cardTitleWhite}>
                {LOCALE.all_privileges}
              </h4>
            </CardHeader>
            <CardBody>
              <List component="nav" aria-label="all-privileges">
                {data?.map((item) => (
                  <div key={item.name}>
                    <ListItem
                      button
                      selected={item.id === privilege?.id}
                      onClick={(event) => handleSelectPrivilege(event, item.id)}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardHeader color="theme">
              <h4 className={classes.cardTitleWhite}>
                {LOCALE.current_privilege_groups}
              </h4>
            </CardHeader>
            <CardBody>
              <Table aria-label="groups table" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {membersData?.map((group, index) => (
                    <TableRow
                      key={group.id}
                      className={classes.tableBodyRow}
                      style={{ borderLeft: "14px solid #4e9c4e" }}
                    >
                      <TableCell className={classes.tableCell}>
                        {group.name}
                      </TableCell>
                      <TableCell
                        style={{ width: "10%", textAlign: "center" }}
                        className={classes.tableCell}
                      >
                        <Tooltip
                          title={LOCALE.remove}
                          aria-label={LOCALE.remove}
                        >
                          <IconButton
                            onClick={() =>
                              handleRemoveGroupToPrivilege(
                                privilege.id,
                                group.id
                              )
                            }
                            size="small"
                            color="secondary"
                            aria-label="details"
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardHeader color="theme">
              <h4 className={classes.cardTitleWhite}>
                {LOCALE.add_groups_to_privilege}
              </h4>
            </CardHeader>
            <CardBody>
              <Table aria-label="groups table" className={classes.table}>
                <TableBody>
                  {notMembersData?.map((group, index) => (
                    <TableRow
                      key={group.id}
                      style={{ borderLeft: "14px solid #c53c3c" }}
                    >
                      <TableCell className={classes.tableCell}>
                        {group.name}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ width: "10%", textAlign: "center" }}
                      >
                        <Tooltip title={LOCALE.add} aria-label={LOCALE.add}>
                          <IconButton
                            onClick={() =>
                              handleAddGroupToPrivilege(privilege.id, group.id)
                            }
                            size="small"
                            color="primary"
                            aria-label="details"
                          >
                            <Icon>add_circle_outline</Icon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
