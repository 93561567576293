import { LOCALE } from "../../../properties/Locale";
import * as yup from "yup";

export const validateMunicipalityCreateForm = yup.object().shape({
  name: yup
    .string()
    .required(LOCALE.municipalities_municipality_name + LOCALE.required_field),
  nameEn: yup
    .string()
    .required(
      LOCALE.municipalities_municipality_name_en + LOCALE.required_field
    ),
  nameAl: yup
    .string()
    .required(
      LOCALE.municipalities_municipality_name_al + LOCALE.required_field
    ),
  code: yup
    .string()
    .required(LOCALE.municipalities_municipality_code + LOCALE.required_field),
});

export const validateMunicipalityEditForm = yup.object().shape({
  name: yup
    .string()
    .required(LOCALE.municipalities_municipality_name + LOCALE.required_field),
  nameEn: yup
    .string()
    .required(
      LOCALE.municipalities_municipality_name_en + LOCALE.required_field
    ),
  nameAl: yup
    .string()
    .required(
      LOCALE.municipalities_municipality_name_al + LOCALE.required_field
    ),
  code: yup
    .string()
    .required(LOCALE.municipalities_municipality_code + LOCALE.required_field),
});
