import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Checkbox,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { MunicipalitiesRepository } from "./MunicipalitiesRepository";
import { validateMunicipalityCreateForm } from "./MunicipalityValidations";
import { LOCALE } from "../../../properties/Locale";
import EntityMetadata from "../../../common/components/EntityMetadata";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles);

export default function MunicipalityCreateComponent() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(false);
  const [isActive, setIsActive] = useState();
  const {
    handleSubmit: createMunicipalityForm,
    register: registerCreateForm,
    errors: errorsCreateMunicipality,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    validationSchema: validateMunicipalityCreateForm,
  });
  const dispatch = useDispatch();

  const showLoadingBar = () => {
    setLoading(true);
    setBtnSubmitLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
    setBtnSubmitLoading(false);
  };

  const handleSubmit = (values) => {
    showLoadingBar();
    MunicipalitiesRepository.createMunicipality(values)
      .then((res) => {
        hideLoadingBar();
        dispatch(triggerRerender());
        dispatch(
          notifyShowSuccessMessage(LOCALE.municipalities_create_success_message)
        );
        setRedirectTo("/app/configuration/municipalities");
      })
      .catch((err) => {
        hideLoadingBar();
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const handleClose = () => {};
  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.municipalities_create_new_municipality}`}
              <Link
                to={`/app/configuration/municipalities/`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {loading ? <LinearProgress color="secondary" /> : ""}
            <div>
              <form onSubmit={createMunicipalityForm(handleSubmit)}>
                <DialogContent>
                  <TextField
                    id="name"
                    label={LOCALE.name}
                    error={errorsCreateMunicipality?.name}
                    helperText={errorsCreateMunicipality?.name?.message}
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                    name="name"
                    size="small"
                    inputRef={registerCreateForm}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <TextField
                    id="nameEn"
                    label={LOCALE.name_en}
                    error={errorsCreateMunicipality?.nameEn}
                    helperText={errorsCreateMunicipality?.nameEn?.message}
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                    name="nameEn"
                    size="small"
                    inputRef={registerCreateForm}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <TextField
                    id="nameAl"
                    label={LOCALE.name_al}
                    error={errorsCreateMunicipality?.nameAl}
                    helperText={errorsCreateMunicipality?.nameAl?.message}
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                    name="nameAl"
                    size="small"
                    inputRef={registerCreateForm}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <TextField
                    id="code"
                    label={LOCALE.code}
                    error={errorsCreateMunicipality?.code}
                    helperText={errorsCreateMunicipality?.code?.message}
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                    name="code"
                    size="small"
                    inputRef={registerCreateForm}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <label>{LOCALE.active}</label>

                  <Checkbox
                    id="isActive"
                    name="isActive"
                    isChecked={isActive}
                    onChange={setIsActive}
                  ></Checkbox>

                  <EntityMetadata data={getValues()} />
                </DialogContent>
                <DialogActions>
                  {!btnSubmitLoading && (
                    <Link
                      to={`/app/configuration/municipalities/`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button size="small" variant="outlined" color="secondary">
                        {LOCALE.cancel}
                      </Button>
                    </Link>
                  )}
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    disabled={btnSubmitLoading}
                    className={btnSubmitLoading ? "btn-loading" : ""}
                    color="primary"
                  >
                    {LOCALE.save}
                    {btnSubmitLoading && (
                      <CircularProgress
                        color="secondary"
                        size={20}
                        style={{ position: "absolute" }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
