import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { LOCALE } from "../../properties/Locale";

export default class ForbidenComponent extends React.Component {
  state = {
    counter: 6,
  };

  componentDidMount() {
    setInterval(() => {
      if (this.state.counter === 1) {
        window.location.href = "/app/dashboard";
      }
      this.setState({
        counter: this.state.counter - 1,
      });
    }, 1000);
  }

  render() {
    return (
      <div style={{ textAlign: "center", marginTop: "10%" }}>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Typography
              variant="h1"
              component="h1"
              style={{ fontSize: "10em" }}
            >
              403
            </Typography>
            <Typography variant="h4" component="h3">
              {LOCALE.forbidden_h1}
            </Typography>
            <br></br>
            <Typography variant="h4" component="h3">
              {LOCALE.forbidden_h2 + this.state.counter}
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </div>
    );
  }
}
