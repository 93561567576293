import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const GroupsRepository = {
  fetch: id => {
    return Axios.get(`${SETTINGS.API_URL}groups/${id}`);
  },
  fetchAll: payload => {
    return Axios({
      url: SETTINGS.API_URL + "groups",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER
      },
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterBy: payload.filterBy,
        filterValue: payload.filterValue
      }
    });
  },
  create: data => {
    return Axios({
      url: SETTINGS.API_URL + "groups",
      method: "POST",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER
      }
    });
  },
  update: data => {
    return Axios({
      url: SETTINGS.API_URL + "groups",
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER
      }
    });
  },
  delete: id => {
    return Axios.delete(`${SETTINGS.API_URL}groups/${id}`);
  },
  fetchMembers: (
    groupId,
    page,
    size,
    orderBy,
    orderDirection,
    filterProperty,
    filterValue
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}groups/${groupId}/members`,
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER
      },
      params: {
        page: page,
        size: size,
        orderBy: orderBy,
        orderDirection: orderDirection,
        filterProperty: filterProperty,
        filterValue: filterValue
      }
    });
  },
  fetchNotMembers: (
    groupId,
    page,
    size,
    orderBy,
    orderDirection,
    filterProperty,
    filterValue
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}groups/${groupId}/notMembers`,
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER
      },
      params: {
        page: page,
        size: size,
        orderBy: orderBy,
        orderDirection: orderDirection,
        filterProperty: filterProperty,
        filterValue: filterValue
      }
    });
  },
  removeMember: (groupId, userId) => {
    return Axios({
      url: `${SETTINGS.API_URL}groups/${groupId}/members`,
      method: "DELETE",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER
      },
      params: {
        groupId: groupId,
        userId: userId
      }
    });
  },
  addMember: (groupId, userId) => {
    return Axios({
      url: `${SETTINGS.API_URL}groups/${groupId}/members`,
      method: "PUT",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER
      },
      params: {
        groupId: groupId,
        userId: userId
      }
    });
  }
};
