import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Checkbox,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { MunicipalitiesRepository } from "./MunicipalitiesRepository";
import { validateMunicipalityEditForm } from "./MunicipalityValidations";
import { LOCALE } from "../../../properties/Locale";
import EntityMetadata from "../../../common/components/EntityMetadata";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles);

export default function MunicipalityEditComponent(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(true);
  const [isActive, setIsActive] = useState();
  const {
    handleSubmit: editMunicipalityForm,
    register: registerEditForm,
    errors: errorsEditMunicipality,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    validationSchema: validateMunicipalityEditForm,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    loadMunicipalities(props.match.params.municipalityId);
  }, []);

  const onEditMunicipality = (values) => {
    showLoadingBar();
    MunicipalitiesRepository.updateMunicipality(values)
      .then((res) => {
        hideLoadingBar();
        dispatch(triggerRerender());
        dispatch(
          notifyShowSuccessMessage(LOCALE.municipalities_update_success_message)
        );
        setRedirectTo("/app/configuration/municipalities");
      })
      .catch((err) => {
        hideLoadingBar();
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  const loadMunicipalities = (id) => {
    showLoadingBar();
    MunicipalitiesRepository.fetchMunicipality(id).then((res) => {
      Object.keys(res.data).map(function (item) {
        if (Object.keys(getValues()).indexOf(item) == -1) {
          registerEditForm({ name: item, type: "custom" });
        }
      });
      Object.entries(res.data).map(function (item) {
        setValue(item[0], item[1]);
      });
      hideLoadingBar();
    });
  };

  const showLoadingBar = () => {
    setLoading(true);
    setBtnSubmitLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
    setBtnSubmitLoading(false);
  };

  const handleClose = () => {};
  const handleChange = (event) => {
    // updating an object instead of a Map
    setIsActive({ ...isActive, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.municipalities_edit}${
                registerEditForm.username
                  ? ": " + registerEditForm.username
                  : ""
              }`}

              <Link
                to={`/app/configuration/municipalities/`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {loading ? <LinearProgress color="secondary" /> : ""}
            <div>
              <form onSubmit={editMunicipalityForm(onEditMunicipality)}>
                <DialogContent>
                  <TextField
                    id="name"
                    label={LOCALE.name}
                    error={errorsEditMunicipality?.name}
                    helperText={errorsEditMunicipality?.name?.message}
                    fullWidth={true}
                    size="small"
                    name="name"
                    margin="normal"
                    variant="outlined"
                    inputRef={registerEditForm}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                      disabled: btnSubmitLoading,
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="nameEn"
                    label={LOCALE.name_en}
                    error={errorsEditMunicipality?.nameEn}
                    helperText={errorsEditMunicipality?.nameEn?.message}
                    fullWidth={true}
                    size="small"
                    name="nameEn"
                    margin="normal"
                    variant="outlined"
                    inputRef={registerEditForm}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <TextField
                    id="nameAl"
                    label={LOCALE.name_al}
                    error={errorsEditMunicipality?.nameAl}
                    helperText={errorsEditMunicipality?.nameAl?.message}
                    name="nameAl"
                    fullWidth={true}
                    size="small"
                    margin="normal"
                    variant="outlined"
                    inputRef={registerEditForm}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <TextField
                    id="code"
                    label={LOCALE.code}
                    error={errorsEditMunicipality?.code}
                    helperText={errorsEditMunicipality?.code?.message}
                    name="code"
                    fullWidth={true}
                    size="small"
                    margin="normal"
                    variant="outlined"
                    inputRef={registerEditForm}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />

                  <Checkbox
                    checked={setIsActive}
                    onChange={setIsActive}
                  ></Checkbox>
                  <EntityMetadata data={getValues()} />
                </DialogContent>
                <DialogActions>
                  {!btnSubmitLoading && (
                    <Link
                      to={`/app/configuration/municipalities/`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button size="small" variant="outlined" color="secondary">
                        {LOCALE.cancel}
                      </Button>
                    </Link>
                  )}
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    disabled={btnSubmitLoading}
                    className={btnSubmitLoading ? "btn-loading" : ""}
                    color="primary"
                  >
                    {LOCALE.save}
                    {btnSubmitLoading && (
                      <CircularProgress
                        color="secondary"
                        size={20}
                        style={{ position: "absolute" }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
