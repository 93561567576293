import LocalizedStrings from "react-localization";

export const LOCALE = new LocalizedStrings({
  mk: {
    link_uredba: "Линк до уредба",
    statementForm: "Образец на изјави",
    NUMBER_OF_PASSED_EXAMS: "Уверение за број на предвидени и положени испити",
    file10: "Изјава за начин на следење на наставата.",
    WAY_OF_ATTENDING_CLASSES_PROOF: "Изјава за начин на следење на наставата.",
    file8:
      "Месечна карта за превоз од местото на живеење до местото во кое се одвиваат студиите.",
    file9:
      "Потврда дека е запишан по прв пат на прв циклус на студии на јавните и приватните високообразовни установи.",
    MONTHLY_TRANSPORTATION_CARD_PROOF:
      "Месечна карта за превоз од местото на живеење до местото во кое се одвиваат студиите.",
    FIRST_TIME_IN_THE_FIRST_CYCLE_OF_STUDIES_PROOF:
      "Потврда дека е запишан по прв пат на прв циклус на студии на јавните и приватните високообразовни установи.",
    TRANSPORT_COSTS: "Трошоци за превоз",
    transportCosts: "Трошоци за превоз",
    transportCosts_h1:
      "Аплицирање за финансиска поддршка на месечна сума на средства за покривање на трошоците за превоз од местото на живеење до местото во кое се одвиваат студиите.",
    results: "Резултати",
    all: "Сите",
    privacyPolicy: "Услови за користење",
    please_wait: "Ве молиме, почекајте!",
    facultyPlaceholder:
      "Факултет за информатички науки и компјутерско инженерство - Скопје",
    addressLinePlaceholder: "Френклин Рузвелт, 3",
    MAIL_TEMPLATE_REJECT_OBJECTION: "Одбиен приговор",
    MAIL_TEMPLATE_APPROVE_OBJECTION: "Прифатен приговор",
    MAIL_TEMPLATE_REJECT_VOUCHER_REQUEST: "Одбиена апликација",
    MAIL_TEMPLATE_APPROVE_VOUCHER_REQUEST: "Прифатена апликација",
    MAIL_TEMPLATE_APP_SUCCESS: "Успешно поднесена апликација",
    voucher_type_inactive: "Овој вид на финансирање во моментов е неактивен",
    accept: "Прифати",
    reject: "Одбиј",
    response: "Одоговор",
    electronic: "Електронски",
    paper: "Хартиено, во министерство",
    applicant: "Подносител",
    application: "Апликација",
    docType: "Тип на документ",
    docName: "Име на документ",
    docSize: "Големина на документ",
    dragNDrop: "Повлечете ја датотеката тука",
    download: "Превземи",
    upload: "Прикачи",
    no_app_found: "Не е пронајдена апликација",
    objection: "Приговор",
    objections: "Приговори",
    view: "Преглед",
    pending: "Поднесено",
    accepted: "Прифатено",
    rejected: "Одбиено",
    no_app_found: "Не е пронајдена апликација",
    submit_objection: "Поднеси приговор",
    reports: "Извештаи",
    download_report_approved: "Превземи листа на одобрени ваучери",
    not_valid: "Не валиден внес",
    already_exist: "Веќе е поднесена апликација!",
    date_from: "Датум од",
    date_to: "Датум до",
    STUDENT_DOORM: "Студентски домови",
    SCHOOL_SUPPLIES: "Училишен прибор",
    STUDENT_PRIVATE_ACCOMMODATION: "Приватно сместување",
    STUDY_COSTS: "Трошоци за студирање",
    modules: "Модули",
    module_administration: "Администрација на модули",
    status: "Статус",
    school_supplies: "Училишен прибор",
    study_costs: "Трошоци за студирање",
    student_doorms: "Студентски дом",
    submit: "Аплицирај",
    details: "Детали",
    student_private_accommodation: "Приватно сместување",
    student_private_accommodation_h1:
      "Аплицирање за еднократна финансиска поддршка на студентите за плаќање на дел од партиципацијата на трошоците за приватно сместување за студентите.",
    student_doorms_h1:
      "Аплицирање за еднократна финансиска поддршка на студентите за плаќање на дел од партиципацијата за сместување во студентски дом.",
    studentDoorm: "Студентски дом",
    faculty: "Факултет",
    yearsOfStudy: "Година на студии",
    study_costs_h1:
      "Аплицирање за еднократна финансиска поддршка на студентите за плаќање на дел од партиципацијата во трошоците за студирање.",
    accept_terms_and_conditions: "Ги прифаќам условите за користење",
    footer_h1: "© 2020 - Министерство за образование и наука",
    ministry_of_education_and_science: "Министерство за образование и наука",
    footer_h2: "Сите права задржани",
    proofsWay: "Начин на доставување на докази",
    id_student_parent: "Лична карта ученик/родител",
    basic_info: "Основни податоци",
    school_supplies_h1:
      "Аплицирање за еднократна финансиска поддршка на ученици запишани во јавните средни училишта во Република Северна Македонија за купување на училишен прибор.",
    nameSurname: "Име и Презиме",
    embg: "Матичен број",
    addressLine: "Адреса на живеење",
    email: "Е-маил",
    telephone: "Телефон за контакт",
    municipality: "Општина на живеење",
    idNumber: "Број",
    idAuthority: "Издадена од",
    idDateOfExpiry: "Датум на важење",
    file1: "Потврда за редовен студент",
    file2: "Изјава за семејна состојба",
    file3:
      "Потврда за последна плата на вработени членови на семејството/чек за пензија/други документи кои се бараат согласно Уредбата (zip формат)",
    file4:
      "Изјава за користење на личните податоци на студентот и неговото потесно семејство",
    file5: "Уверение за број на предвидени и положени испити",
    file6: "Заверен образец УППИ",
    file7:
      "Договор за закуп со закуподавач, нотарски заверен и пријавен во УЈП и сметка на закуподавецот.",
    REGULAR_STUDENT_PROOF: "Потврда за редовен студент",
    FINANCIAL_STATUS_OF_FAMILY_PROOF: "Изјава за семејна состојба",
    LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF:
      "Потврда за последна плата на вработени членови на семејството/чек за пензија/други документи кои се бараат согласно Уредбата (zip формат)",
    USE_OF_PERSONAL_DATA_PROOF:
      "Изјава за користење на личните податоци на студентот и неговото потесно семејство",
    NUMBER_OF_PASSED_EXAMS_PROOF:
      "Уверение за број на предвидени и положени испити",
    UPPI_PROOF: "Заверен образец УППИ",
    UPPI: "Oбразец УППИ",
    PURCHASE_AGREEMENT_WITH_TENANT_PROOF:
      "Договор за закуп со закуподавач, нотарски заверен и пријавен во УЈП и сметка на закуподавецот.",
    proof: "Датотеката",
    dashboard: "Работна табла",
    administration: "Администраторски панел",
    users: "Корисници",
    groups: "Групи",
    privileges: "Привилегии",
    settings: "Подесувања",
    audit_log: "Аудит лог",
    mail_log: "Лог на е-пошта",
    mail_template: "Урнеци за е-пошта",
    configuration: "Конфигурација",
    municipalities: "Општини",
    schools: "Училишта",
    eVoucherApp: "Апликации за ваучер",
    charts: "Извештаи",

    language: "Јазик",
    my_profile: "Мој профил",

    login_h1: "Министерство за образование и наука",
    login_h2: "Финансиска поддршка",
    login_signin: "Најави се",
    login_username: "Корисничко име",
    login_password: "Лозинка",
    logout: "Oдјави се",
    title: "Наслов",
    id: "ID",
    name: "Име",
    filter_by: "Филтер по",
    username: "Корисничко име",
    firstLastName: "Име и презиме",
    firstName: "Име",
    lastName: "Презиме",
    email: "Е-пошта",
    date_created: "Датум на креирање",
    actions: "Акции",
    role: "Улога",
    administrator: "Администратор",
    commission: "Комисија",
    details: "Детали",
    edit: "Промени",
    delete: "Избриши",
    rows_per_page: "Редови по страница",
    password: "Лозинка",
    confirm_password: "Потврди лозинка",
    change_password: "Промени лозинка",
    cancel: "Откажи",
    save: "Зачувај",
    created_by: "Kреирано од",
    created_at: "Креирано на",
    modified_by: "Променето од",
    modified_at: "Променето на",
    invalid_email_address_format: "Форматот на меил адресата е невалиден",
    required_field: " е задолжително поле",
    close: "Затвори",
    entity_delete_confirmation_text: "Дали сте сигурни",
    group_name: "Име на група",
    members: "Членови",
    add_new_members: "Додади нови членови",
    remove: "Отстрани",
    add: "Додади",
    key: "Клуч",
    value: "Вредност",
    props: "Параметри",
    success: "Успешно",
    test_failure: "Неуспешно",
    reason: "Причина",
    send: "Испрати",
    revtype: "Тип на акција",
    revtstmp: "Време на акција",
    propKey: "Име на поставката",
    propVal: "Вредност на поставката",
    forbidden_h1: "Извинете, забрането ви е да ja пристапите оваа страница.",
    forbidden_h2: "Пренасочување кон почетната страница за ",
    name_en: "Име Англиски",
    name_al: "Име Албански",
    code: "Код",
    active: "Активна",
    apps_for_evoucher: "Преглед на поднесени апликации за ваучер",
    no_apps_for_evaluating: "Нема поднесено апликации за преглед",
    acceptApp: "Прифати барање",
    rejectApp: "Одбиј барање",
    back: "Врати се назад",
    number: "Број",
    authortity: "Издадена од",
    date_of: "Датум на важење",
    documents: "Документи",
    paper_documents:
      "Документите ќе бидат доставени хартиено во Министерство за образование и наука",
    study_year: "Година на студии",
    submitted: "Поднесени",
    in_processing: "Во обработка",
    approved: "Одобрени",
    rejected: "Одбиени",

    create_new_user: "Креирај нов корисник",
    user_details: "Детали за корисникот",
    user_edit: "Промена за корисникот",
    user_delete: "Бришење на корисник",
    user_create_success_message: "Корисникот е успешно креиран",
    user_update_success_message: "Корисникот е успешно ажуриран",
    user_deleted_success_message: "Корисникот е успешно избришан",

    create_new_group: "Креирај нова група",
    group_details: "Детали за група",
    group_edit: "Промена на група",
    group_delete: "Бришење на група",
    group_create_success_message: "Групата е успешно креирана",
    group_update_success_message: "Групата е успешно ажурирана",
    group_deleted_success_message: "Групата е успешно избришана",
    member_added_success_message: "Членот е успешно додаден",
    member_removed_success_message: "Членот е успешно отстранет",

    all_privileges: "Сите привилегии",
    current_privilege_groups: "Групи на привилегија",
    add_groups_to_privilege: "Додади групи на привилегија",
    group_added_success_message: "Групата е успешно додадена",
    group_removed_success_message: "Групата е успешно отстранета",

    mail_test_results_info_header: "Резултати од тестот",
    mail_test_results_info_message:
      "Овде ќе го видите резултатот после тестирањето",
    mail_sent_success_message: "Пораката е успешно испратена",
    test_mail_server_header: "Тестирање на е-меил серверот",
    test_mail_server_subheader:
      "Во овој дел можете да го тестирате е-меил серверот",
    test_mail_server_description:
      "Моментално се подесени следните SMTP параметри",
    for_testing_purposes_only:
      "Ова функционалност треба да се користи исклучиво за тестирање на е-меил (SMTP) серверот",
    user_your_own_email_address: "Користи ја само својата е-меил адреса",

    audit_details: "Детали за лог запис",
    users_audit_log: "Корисници",
    groups_audit_log: "Групи",
    system_settings_audit_log: "Системски подесувања",
    system_settings_props_updated_success: "Подесувањата се успешно ажурирани",

    mail_all: "Сите",
    mail_success: "Успешни",
    mail_pending: "На чекање",
    mail_processing: "Процесирање",
    mail_failed: "Неуспешни",
    mail_canceled: "Откажани",
    mail_sender: "Испраќач",
    mail_receiver: "Примач",
    mail_createdAt: "Креирана на",
    mail_sentAt: "Испратена на",
    mail_status: "Статус",
    mail_details: "Детали",
    mail_requeued: "Препратени",
    mail_requeue: "Препрати",
    mail_content: "Содржина",
    mail_view_mail_content: "Содржина на е-меил пораката",
    mail_error_log: "Детали за грешката",
    mail_error_details: "Детали за грешка при испраќање",
    mail_subject: "Наслов/Предмет",
    mail_mail_details: "Детали за меил пораката",

    MAIL_TEMPLATE_REGISTRATION: "Успешна Регистрација",
    MAIL_TEMPLATE_FORGOT_PASSWORD: "Заборавена Лозинка",
    mail_template_save_success: "Успешно зачуван урнек",
    mail_template_save_failure: "Грешка при зачувување на урнек",
    mail_template_available_params: "Достапни параметри",
    mail_send_test_mail: "Прати тест е-пошта",

    user_profile: "Кориснички профил",
    user_profile_general: "Општи податоци",

    municipality: "Општина",
    municipalities_create_new_municipality: "Креирај нова општина",
    municipalities_create_success_message: "Општината е успешно креирана",
    municipalities_update_success_message: "Општината е успешно ажуриранa",
    municipalities_deleted_success_message: "Општината е успешно избришана",
    municipalities_details: "Детали за општината",
    municipalities_edit: "Промени општина",
    municipalities_delete: "Бришење на општина",

    school: "Училиште",
    schools_create_new_school: "Креирај ново училиште",
    schools_create_success_message: "Училиштето е успешно креирано",
    schools_update_success_message: "Училиштето е успешно ажурирано",
    schools_deleted_success_message: "Училиштето е успешно избришано",
    schools_details: "Детали за училиште",
    schools_edit: "Промени податоци за училиштето",
    schools_delete: "Избриши училиште",

    monitoring: "Мониторинг",
    total_memory: "Total memory",
    memory_free: "Free memory",
    memory_used: "Used memory",
    uptime: "Uptime",
    systemload_average: "System load (average)",
    heap_commited: "Heap commited",
    heap_init: "Heap init",
    heap_used: "Heap used",
    heap: "Heap",
    nonheap_committed: "Nonheap commited",
    nonheap_init: "Nonheap init",
    nonheap_used: "Nonheap used",
    nonheap: "Nonheap",
    datasource_primary_active: "Datasource (Active/Inactive)",
    datasource_primary_usage: "Datasource usage",
    http_sesssions_active: "Active HTTP sessions",
  },
  al: {
    link_uredba: "Lidhje me urdhëresën",
    statementForm: "Formularin e deklaratës",
    NUMBER_OF_PASSED_EXAMS:
      "Certifikatë për numrin e provimeve të planifikuara dhe të mbartura",
    file10: "Deklaratë për mënyrën e ndjekjes së mësimeve.",
    WAY_OF_ATTENDING_CLASSES_PROOF:
      "Deklaratë për mënyrën e ndjekjes së mësimeve.",
    file8:
      "Bileta mujore për transport nga vendi i banimit në vendin ku zhvillohen studimet.",
    file9:
      "Konfirmim që ai është regjistruar për herë të parë në ciklin e parë të studimeve në institucionet e arsimit të lartë publik dhe privat.",
    MONTHLY_TRANSPORTATION_CARD_PROOF:
      "Bileta mujore për transport nga vendi i banimit në vendin ku zhvillohen studimet.",
    FIRST_TIME_IN_THE_FIRST_CYCLE_OF_STUDIES_PROOF:
      "Konfirmim që ai është regjistruar për herë të parë në ciklin e parë të studimeve në institucionet e arsimit të lartë publik dhe privat.",
    TRANSPORT_COSTS: "Kostot e transportit",
    transportCosts: "Kostot e transportit",
    transportCosts_h1:
      "Aplikimi për mbështetje financiare të një shume mujore fondesh për të mbuluar kostot e transportit nga vendi i banimit në vendin ku zhvillohen studimet.",
    results: "Rezultatet",
    all: "Тë gjithë",
    privacyPolicy: "Kushtet e përdorimit",
    please_wait: "Te lutem prit!",
    facultyPlaceholder: "Fakulteti i Administrimit të Biznesit në Kumanovë",
    addressLinePlaceholder: "Franklin Ruzvelt, 3",
    MAIL_TEMPLATE_REJECT_OBJECTION: "Kundërshtoi kundërshtimin",
    MAIL_TEMPLATE_APPROVE_OBJECTION: "Kundërshtim i aprovuar",
    MAIL_TEMPLATE_REJECT_VOUCHER_REQUEST: "Aplikimi i refuzuar",
    MAIL_TEMPLATE_APPROVE_VOUCHER_REQUEST: "Aplikimi i pranuar",
    MAIL_TEMPLATE_APP_SUCCESS: "Aplikimi i paraqitur me sukses",
    voucher_type_inactive: "Ky lloj financimi është aktualisht joaktiv",
    accept: "Pranoj",
    reject: "Refuzoj",
    response: "Përgjigje",
    electronic: "Elektronik",
    paper: "Letër, në ministri",
    applicant: "Kërkues",
    application: "Aplikacion",
    docType: "Lloji i dokumentit",
    docName: "Emri i dokumentit",
    docSize: "Madhësia e dokumentit",
    dragNDrop: "Tërhiq dhe lësho",
    download: "Shkarko",
    upload: "Ngarkoni",
    no_app_found: "Asnjë aplikacion nuk u gjet",
    objection: "Kundërshtim",
    objections: "Kundërshtimet",
    view: "Pamje",
    pending: "Paraqitur",
    accepted: "I pranuar",
    rejected: "Refuzuar",
    title: "Тitull",
    no_app_found: "Asnjë aplikacion nuk u gjet",
    submit_objection: "Parashtroni kundërshtim",
    not_valid: "Hyrja e pavlefshme",
    already_exist: "Tashmë është paraqitur një kërkesë!",
    date_from: "Data-prej",
    date_to: "Deri më datën",
    STUDENT_DOORM: "Konviktet e studentëve",
    SCHOOL_SUPPLIES: "Pajisjet shkollore",
    STUDENT_PRIVATE_ACCOMMODATION: "Akomodim privat",
    STUDY_COSTS: "Shpenzimet e studimit",
    modules: "Modulet",
    module_administration: "Administrimi i modulit",
    status: "Statusi",
    school_supplies: "Pajisjet shkollore",
    study_costs: "Shpenzimet e studimit",
    student_doorms: "Konviktet e studentëve",
    submit: "Apliko",
    student_private_accommodation: "Akomodim privat",
    student_private_accommodation_h1:
      "Aplikim për ndihmë financiare -njëherëshme për studentët që të paguajnë në pjesë nga participimi i shpenzimeve për akomodim privat.",
    student_doorms_h1:
      "Aplikim për ndihmë financiare -njëherëshme për studentët që të paguajnë në pjesë nga participimi i shpenzimeve për akomodim studentorë.",
    studentDoorm: "Konvikti i studentëve",
    faculty: "Fakulteti",
    yearsOfStudy: "Viti i studimeve",
    study_costs_h1:
      "Aplikim për ndihmë financiare -njëherëshme për studentët që të paguajnë në pjesë nga participimi i shpenzimeve për studime.",
    accept_terms_and_conditions: "I pranoj kushtet e shfrytëzimit",
    footer_h1: "© 2020 - Ministria e arsimit dhe shkencës.",
    ministry_of_education_and_science: "Ministria e arsimit dhe shkencës",
    footer_h2: "Të gjitha të drejtat e ruajtura.",
    proofsWay: "Mënyra e paraqitjes së dëshmive",
    id_student_parent: "Letërnjoftimi i nxënësit/prindi",
    basic_info: "Të dhënat kryesore",
    school_supplies_h1:
      "Aplikim për ndihmë financiaretë  -njëherëshme për nxënësit e regjistruar në shkollat ​​e mesme publike në Republikën e Maqedonisësë Veriut për blerjen e pajisjeve shkollore.",
    nameSurname: "Emri dhe Mbiemri",
    embg: "Numri Amë",
    addressLine: "Adresa ku jetoni",
    email: "Posta elektronike e-maiel",
    telephone: "Telefoni kontaktues",
    municipality: "Komuna",
    idNumber: "Numri",
    idAuthority: "E lëshuar nga",
    idDateOfExpiry: "Data e vlefshmërisë",
    file1: "Vërtetim për nxënës të rregulltë",
    file2: "Vërtetim  të gjendjes financiare të familjes",
    file3:
      "Vërtetim i rrogës së fundit- të anëtarëve të familjes të punësuar/kontroll i pensionit / dokumente të tjera të kërkuara sipas dekretit (formati zip)",
    file4:
      "Deklaratë për shfrytëzimin  e të dhënave personale të nxënësit dhe familjes së tij të ngushtë",
    file5: "Vërtetim për numrin e provimeve të parapara dhe të dhanuna",
    file6: "Formulari i vërtetuar - UPPI",
    file7:
      "Marrëveshja e qirasë me qiradhënësin,e noterizuar dhe i paraqitur në AAP dhe llogarinë e qiradhënësit.",
    REGULAR_STUDENT_PROOF: "Vërtetim për nxënës të rregulltë",
    FINANCIAL_STATUS_OF_FAMILY_PROOF:
      "Vërtetim  të gjendjes financiare të familjes",
    LAST_SALARY_OF_EMPLOYED_FAMILY_MEMBERS_PROOF:
      "Vërtetim i rrogës së fundit- të anëtarëve të familjes të punësuar/kontroll i pensionit / dokumente të tjera të kërkuara sipas dekretit (formati zip)",
    USE_OF_PERSONAL_DATA_PROOF:
      "Deklaratë për shfrytëzimin  e të dhënave personale të nxënësit dhe familjes së tij të ngushtë",
    NUMBER_OF_PASSED_EXAMS_PROOF:
      "Vërtetim për numrin e provimeve të parapara dhe të dhanuna",
    UPPI_PROOF: "Formulari i vërtetuar - UPPI",
    UPPI: "Formulari - UPPI",
    PURCHASE_AGREEMENT_WITH_TENANT_PROOF:
      "Marrëveshja e qirasë me qiradhënësin,e noterizuar dhe i paraqitur në AAP dhe llogarinë e qiradhënësit.",
    proof: "Dëshmia",
    dashboard: "Tabela e punës",
    administration: "Paneli Administrativ",
    users: "Shfrytëzuesit",
    groups: "Grupet",
    privileges: "Privilegjet",
    settings: "Rregullimet",
    audit_log: "Llog i Auditimit",
    mail_log: "LlOG  i postës elektronike",
    mail_template: "Modelet e postës elektronike",
    configuration: "Konfiguracioni",
    municipalities: "Komunat",
    schools: "Shkollat",
    eVoucherApp: "Aplikimi i vauçerit",
    charts: "Raporte",

    language: "Gjuha",
    my_profile: "Profili im",

    login_h1: "Ministria e arsimit dhe shkencës",
    login_h2: "Мbeshtetje financiare",
    login_signin: "Identifikohu",
    login_username: "Emri shfytëzues",
    login_password: "Fjalëkalimi",
    logout: "Shkyçu",

    id: "ID",
    name: "Emri",
    filter_by: "Filter pas",
    username: "Emri shfytëzues",
    firstLastName: "Emri dhe Mbiemri",
    firstName: "Emri",
    lastName: "Mbiemri",
    email: "Е-Posta elektronike e-maiel",
    date_created: "Data e krijimit",
    actions: "Aksionet",
    role: "Rroli",
    administrator: "Administratori",
    commission: "Komisioni",
    details: "Detajet",
    edit: "Ndryshimet",
    delete: "Fshije",
    rows_per_page: "Rreshtat e faqës",
    password: "Fjalëkalimi",
    confirm_password: "Vërtetoje fjalëkalimin",
    change_password: "Ndrysho fjalëkalimin",
    cancel: "Anulo",
    save: "Ruaje",
    created_by: "Është krijuar",
    created_at: "Është krijuar në",
    modified_by: "Është ndryshuar nga",
    modified_at: "Është i ndryshuar në",
    invalid_email_address_format:
      "Formati i adresës së emailit është i pavlefshëm",
    required_field: " është fushë e nevojshme",
    close: "Mbylleni",
    entity_delete_confirmation_text: "Ajeni të sigurtë",
    group_name: "Emri i grupit",
    members: "Anëtarët",
    add_new_members: "Shtoni anëtarë të ri",
    remove: "Отстрани",
    add: "Shtoni",
    key: "Çelësi",
    value: "Vlera",
    props: "Parametrat",
    success: "E sukseshsme",
    test_failure: "E pasuksesshme",
    reason: "Arsyja",
    send: "Dërgo",
    revtype: "Lloji i aksioniot",
    revtstmp: "Koha e aksionit",
    propKey: "Emri i përcaktimit",
    propVal: "Vlera epërcaktimit",
    forbidden_h1: "Më falni,nuk ju lejohet të hyni në këtë faqe.",
    forbidden_h2: "Përcjellja në faqen kryesore për ",
    name_en: "Emri Anglishtë",
    name_al: "Emri Shqip",
    code: "Kodi",
    active: "Aktive",
    apps_for_evoucher: "Shqyrtimi i aplikacioneve të dorëzuara për kupon",
    no_apps_for_evaluating: "Nuk ka paraqitur  aplikime për shqyrtim",
    acceptApp: "Pranoje kërkesën",
    rejectApp: "Refuzo kërkesën",
    back: "Kthehu prapa",
    number: "Numri",
    authortity: "E lëshuar prej",
    date_of: "Data e vlerësimit",
    documents: "Dokumentet",
    paper_documents:
      "Dokumentet do të dorëzohen në letër Ministrisë së Arsimit dhe Shkencës",
    study_year: "Viti i studimeve",
    submitted: "Поднесени",
    in_processing: "Во обработка",
    approved: "Одобрени",
    rejected: "Одбиени",

    create_new_user: "Krijo shfrytëzues të ri",
    user_details: "Detajet e shfrytëzuesit",
    user_edit: "Ndryshim për shfrytëzuesin",
    user_delete: "Fshirja e shfrytëzuesit",
    user_create_success_message: "Shfrytëzuesi është krijuar me sukses",
    user_update_success_message: "Shfrytëzuesi është azhurnuar me sukses",
    user_deleted_success_message: "Shfrytëzuesi është fshirë  me sukses",

    create_new_group: "Krijo grupë të re",
    group_details: "Detajet  e grupit",
    group_edit: "Ndryshimi i grupit",
    group_delete: "Fshirja e grupit",
    group_create_success_message: "Grupi është krijuar me sukses",
    group_update_success_message: "Grupi është azhurnuar me sukses",
    group_deleted_success_message: "Grupi është fshirë  me sukses",
    member_added_success_message: "Anëtari është shtuar me sukses",
    member_removed_success_message: "Anëtari është hequr me sukses",

    all_privileges: "Të gjitha privilegjet",
    current_privilege_groups: "Grupet e priviligjuara",
    add_groups_to_privilege: "Shtoni grupe të  privilegjuara",
    group_added_success_message: "Grupi është shtuar me sukses",
    group_removed_success_message: "Grupi është larguar me sukses",

    mail_test_results_info_header: "Rezultatet nga testi",
    mail_test_results_info_message: "Këtu do të shihni rezultatin pas testimit",
    mail_sent_success_message: "Mesazhi u dërgua me sukses",
    test_mail_server_header: "Testimi i serverit të postës elektronike",
    test_mail_server_subheader:
      "Në këtë pjesë mund të provoni serverin e e-mail",
    test_mail_server_description:
      "Momentalisht janë vendosur parametrat SMTP në vijim",
    for_testing_purposes_only:
      "Ky funksionalitet duhet të përdoret vetëm për  testimin e   serverit të  e-mail -it(SMTP)",
    user_your_own_email_address: "Përdorni vetëm adresën tuaj të emailit",

    audit_details: "Detajet e Log regjistrimit",
    users_audit_log: "Shfrytëzuesit",
    groups_audit_log: "Grupet",
    system_settings_audit_log: "Vendosja  e sistemit",
    system_settings_props_updated_success: "Vendosjet janë azhurnuar me sukses",

    mail_all: "Të gjitha",
    mail_success: "Të suksesshme",
    mail_pending: "Në pritje",
    mail_processing: "Procesimi",
    mail_failed: "Të pasuksesshme",
    mail_canceled: "Të anuluara",
    mail_sender: "Dërguesi",
    mail_receiver: "Pranuesi",
    mail_createdAt: "E krijuar më",
    mail_sentAt: "Dërguar më",
    mail_status: "Status",
    mail_details: "Detaje",
    mail_requeued: "Të ridorëzuara",
    mail_requeue: "Të dorëzuara",
    mail_content: "Përmbajtja",
    mail_view_mail_content: "Përmbajtja e e-mail mesazhit",
    mail_error_log: "Detajet  e gabimit",
    mail_error_details: "Detajet e gabimit gjatë dërgimit",
    mail_subject: "Titulli / Lënda",
    mail_mail_details: "Detajet për e-mail mesazhin",

    MAIL_TEMPLATE_REGISTRATION: "Regjistrimi i suksesshëm",
    MAIL_TEMPLATE_FORGOT_PASSWORD: "Fjalëkalimi i harruar",
    mail_template_save_success: "Modeli i ruajtur me sukses",
    mail_template_save_failure: "Gabim në ruajtjen e modelit",
    mail_template_available_params: "Parametrat në dispozicion",
    mail_send_test_mail: "Dërgo e-mail testi",

    user_profile: "Profili i shfrytëzuesit",
    user_profile_general: "Të dhënat e përgjithshme",

    municipality: "Komunë",
    municipalities_create_new_municipality: "Krijo komunë të re",
    municipalities_create_success_message: "Komuna është krijuar me sukses",
    municipalities_update_success_message: "Komuna është azhurnuar me sukses",
    municipalities_deleted_success_message: "Komuna është fshirë me sukses",
    municipalities_details: "Detajet për komunën",
    municipalities_edit: "Ndryshimet për komunën",
    municipalities_delete: "Fshirja e komunës",

    school: "Shkolla",
    schools_create_new_school: "Krijo shkollë të re",
    schools_create_success_message: "Shkolla u krijua me sukses",
    schools_update_success_message: "Shkolla u azhurnua me sukses",
    schools_deleted_success_message: "Shkolla është fshirë me sukses",
    schools_details: "Detajet për shkollën",
    schools_edit: "Ndryshoni të dhënat e shkollës",
    schools_delete: "Fshi shkollën",

    monitoring: "Monitoring",
    total_memory: "Total memory",
    memory_free: "Free memory",
    memory_used: "Used memory",
    uptime: "Uptime",

    schools_delete: "Fshije shkollën",
  },
});

if (window.localStorage.getItem("locale") == null) {
  if (window.location.href.includes("digitalizirajse")) {
    window.localStorage.setItem("locale", "mk");
  } else if (window.location.href.includes("digjitalizohu")) {
    window.localStorage.setItem("locale", "al");
  } else {
    window.localStorage.setItem("locale", "mk");
  }
  LOCALE.setLanguage(window.localStorage.getItem("locale"));
} else {
  if (window.location.href.includes("digitalizirajse")) {
    window.localStorage.setItem("locale", "mk");
  } else if (window.location.href.includes("digjitalizohu")) {
    window.localStorage.setItem("locale", "al");
  }
  LOCALE.setLanguage(window.localStorage.getItem("locale"));
}

export const changeLanguage = (languageCode) => {
  var lng = window.localStorage.getItem("locale");
  if (typeof languageCode == "string" && (!lng || lng !== languageCode)) {
    window.localStorage.setItem("locale", languageCode);
    window.location.reload();
  }
};
