import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const SchoolsRepository = {
  fetchSchool: (id) => {
    return Axios.get(`${SETTINGS.API_URL}schools/${id}`);
  },
  fetchAllSchools: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}schools`,
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterProperty: payload.filterProperty,
        filterValue: payload.filterValue,
      },
    });
  },
  fetchAllSchoolsByMunicipality: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}schools/municipality`,
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        municipalityId: payload.municipalityId,
      },
    });
  },
  fetchAllSchoolsByMunicipalityTypeOwnedBy: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}schools/municipalityTypeOwned`,
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        municipalityId: payload.municipalityId,
        type: payload.schoolType,
        owned_by: payload.ownedBy,
      },
    });
  },
  createSchool: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}schools`,
      method: "POST",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  updateSchool: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}schools`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  deleteSchool: (id) => {
    return Axios.delete(`${SETTINGS.API_URL}schools/${id}`);
  },
};
