import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
// @material-ui/core components
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../../common/actions/CommonActions";
import {
  TableRow,
  TableCell,
  TextField,
  Button,
  Grid,
  Table,
  CircularProgress,
  LinearProgress,
  TableBody,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import tableStyles from "../../../assets/jss/components/tableStyle";
import styles from "../../../assets/jss/layouts/appStyle";
// core components
import { MailRepository } from "../mail/MailRepository";
import { LOCALE } from "../../../properties/Locale";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tableStyles);

export default function TestMailSettings(props) {
  const classes = useStyles();
  const classesTable = useTableStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [testResult, setTestResult] = useState({
    severity: "info",
    title: LOCALE.mail_test_results_info_header,
    message: LOCALE.mail_test_results_info_message,
  });

  const handleSendTestMail = () => {
    setLoading(true);
    setTestResult({
      severity: "info",
      title: LOCALE.mail_test_results_info_header,
      message: LOCALE.mail_test_results_info_message,
    });
    MailRepository.sendRegularTestMail({
      sender: props.data.filter(function (el) {
        return el.key === "SMTP_USERNAME";
      })[0].value,
      receiver: receiver,
      content: "<h3>This is a test message</h3>",
      subject: "Test mail",
    })
      .then((res) => {
        setLoading(false);
        if (res.data.status === "SUCCESS") {
          dispatch(notifyShowSuccessMessage(LOCALE.mail_sent_success_message));
          setTestResult({
            severity: "success",
            title: LOCALE.success,
            message: LOCALE.mail_sent_success_message,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setTestResult({
          severity: "error",
          title: LOCALE.test_failure,
          message: LOCALE.reason + ": " + err.response.data,
        });
        dispatch(notifyShowErrorMessage(err.response.data));
      });
  };

  return (
    <Card>
      <CardHeader color="theme">
        <h4 className={classes.cardTitleWhite}>
          {LOCALE.test_mail_server_header}
        </h4>
      </CardHeader>

      <CardBody>
        {loading ? <LinearProgress color="secondary" /> : ""}
        <Grid item md={12}>
          <p>
            <strong>{LOCALE.test_mail_server_subheader}.</strong>
          </p>
          <p>{LOCALE.test_mail_server_description}:</p>
          <Table className={classesTable.table}>
            <TableBody>
              {props?.data?.map(
                (item) =>
                  [
                    "SMTP_USERNAME",
                    "SMTP_SERVER_HOST",
                    "SMTP_SERVER_PORT",
                  ].includes(item.key) && (
                    <TableRow
                      key={item.key}
                      className={classesTable.tableBodyRow}
                    >
                      <TableCell className={classesTable.tableCell}>
                        {item.key}
                      </TableCell>
                      <TableCell className={classesTable.tableCell}>
                        {item.value}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
          <br />
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: "5px" }}>
          <Grid item md={7}>
            <TextField
              fullWidth={true}
              onChange={(e) => setReceiver(e.target.value)}
              value={receiver}
              disabled={loading}
              size="small"
              variant="outlined"
              label={LOCALE.email}
            />
          </Grid>
          <Grid item md={4}>
            <Button
              disabled={loading}
              fullWidth={true}
              style={{ verticalAlign: "middle", marginTop: "1px" }}
              variant="contained"
              onClick={() => handleSendTestMail()}
              color="secondary"
            >
              {loading && (
                <CircularProgress
                  color="secondary"
                  size={20}
                  style={{ position: "absolute" }}
                />
              )}
              <span style={{ color: loading ? "transparent" : "inherit" }}>
                {LOCALE.send}
              </span>
            </Button>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Alert fullwidth="true" severity={testResult?.severity}>
            <AlertTitle>{testResult?.title}</AlertTitle>
            {testResult?.message}
          </Alert>
        </Grid>
        <Grid item md={12}>
          <Alert fullwidth="true" severity="warning">
            <AlertTitle>{LOCALE.for_testing_purposes_only}</AlertTitle>
            {LOCALE.user_your_own_email_address}
          </Alert>
        </Grid>
      </CardBody>
    </Card>
  );
}
