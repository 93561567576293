import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const AuditLogRepository = {
  fetchLog: (auditType, page, size) => {
    return Axios({
      url: `${SETTINGS.API_URL}audit/query`,
      params: {
        auditType: auditType,
        page: page,
        size: size
      }
    });
  }
};
