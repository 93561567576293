import { LOCALE } from "../../../../properties/Locale";
import * as yup from "yup";

export const administratorCreateUserForm = yup.object().shape({
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  username: yup.string().required(LOCALE.username + LOCALE.required_field),
  password: yup.string().required(LOCALE.password + LOCALE.required_field),
});

export const commissionCreateUserForm = yup.object().shape({
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  username: yup.string().required(LOCALE.username + LOCALE.required_field),
  password: yup.string().required(LOCALE.password + LOCALE.required_field),
});
