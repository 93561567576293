import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Icon,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Button,
  TextField,
  Paper,
  IconButton,
} from "@material-ui/core";
import { LOCALE } from "../../../properties/Locale";
import { useDispatch } from "react-redux";
import { changePageTitle } from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card";
import CardHeader from "../../../common/components/Card/CardHeader";
import CardBody from "../../../common/components/Card/CardBody";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { PieChart } from "react-minimal-pie-chart";
import moment from "moment";
import { ChartsRepository } from "./ChartsRepository";
import { EVoucherAdministrationRepository } from "../../administration/eVoucher/EVoucherAdministrationRepository";
import { SETTINGS } from "../../../properties/ApiProps";
import { AuthService } from "../../../auth/AuthService";

const colors = {
  PENDING: "#C13C37",
  PROCESSING: "orange",
  APPROVED: "green",
  EVALUATED: "#B38652",
  REJECTED: "red",
};

export default function ChartsComponent() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedVoucherType, setSelectedVoucherType] = useState();
  const [hovered, setHovered] = useState(0);
  const [dataMock, setDataMock] = useState([]);
  const [dataMock1, setDataMock1] = useState([]);
  const [dataMock2, setDataMock2] = useState([]);
  const [dataMock3, setDataMock3] = useState([]);
  const [dataMock4, setDataMock4] = useState([]);
  const [rerender, triggerRerender] = useState(false);
  const [voucherTypes, setVoucherTypes] = React.useState();

  React.useEffect(() => {
    dispatch(changePageTitle(LOCALE.charts));
    schoolSuppliesStatuses();
    studyCostsStatuses();
    studentsDoormsStatuses();
    privateAccommodationStatuses();
    transportCostsStatuses();
    getVoucherTypes();
  }, []);

  const schoolSuppliesStatuses = () => {
    (async () => {
      let data = [];
      await ChartsRepository.getSchoolSuppliesStatuses().then((res) => {
        let i = 0;
        res.data.forEach((element) => {
          if (element[1] > 0) {
            data.push({
              title: element[0],
              value: element[1],
              color: colors[element[0]],
            });
            i++;
          }
        });
      });
      setDataMock(data);
    })();
  };

  const studyCostsStatuses = () => {
    (async () => {
      let data = [];
      await ChartsRepository.getStudyCostsStatuses().then((res) => {
        let i = 0;
        res.data.forEach((element) => {
          if (element[1] > 0) {
            data.push({
              title: element[0],
              value: element[1],
              color: colors[element[0]],
            });
            i++;
          }
        });
      });
      setDataMock1(data);
    })();
  };

  const transportCostsStatuses = () => {
    (async () => {
      let data = [];
      await ChartsRepository.getTransportCostsStatuses().then((res) => {
        let i = 0;
        res.data.forEach((element) => {
          if (element[1] > 0) {
            data.push({
              title: element[0],
              value: element[1],
              color: colors[element[0]],
            });
            i++;
          }
        });
      });
      setDataMock4(data);
    })();
  };

  const studentsDoormsStatuses = () => {
    (async () => {
      let data = [];
      await ChartsRepository.getStudentsDoormsStatuses().then((res) => {
        let i = 0;
        res.data.forEach((element) => {
          if (element[1] > 0) {
            data.push({
              title: element[0],
              value: element[1],
              color: colors[element[0]],
            });
            i++;
          }
        });
      });
      setDataMock2(data);
    })();
  };

  const privateAccommodationStatuses = () => {
    (async () => {
      let data = [];
      await ChartsRepository.getPrivateAccommodationStatuses().then((res) => {
        let i = 0;
        res.data.forEach((element) => {
          if (element[1] > 0) {
            data.push({
              title: element[0],
              value: element[1],
              color: colors[element[0]],
            });
            i++;
          }
        });
      });
      setDataMock3(data);
    })();
  };

  const getVoucherTypes = () => {
    EVoucherAdministrationRepository.getVoucherTypes().then((res) => {
      setVoucherTypes(res.data);
    });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <CardHeader color="theme">
                  <Typography>{LOCALE.school_supplies}</Typography>
                </CardHeader>
                <CardBody>
                  {dataMock?.length > 0 ? (
                    <Grid container>
                      <Grid item xs={5}>
                        {dataMock?.map((element) => (
                          <Grid container>
                            <Grid item xs={2}>
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  backgroundColor: element.color,
                                }}
                              ></div>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography style={{ fontSize: "12px" }}>
                                {element.title === "PENDING"
                                  ? LOCALE.submitted
                                  : element.title === "PROCESSING"
                                  ? LOCALE.in_processing
                                  : element.title === "APPROVED"
                                  ? LOCALE.approved
                                  : element.title === "REJECTED"
                                  ? LOCALE.rejected
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={7}>
                        <PieChart
                          data={dataMock}
                          lineWidth={20}
                          paddingAngle={18}
                          rounded
                          label={({ dataEntry }) => dataEntry.value}
                          labelStyle={(index) => ({
                            fill: dataMock[index].color,
                            fontSize: "5px",
                            fontFamily: "sans-serif",
                            fontWeight: "bold",
                          })}
                          labelPosition={60}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={8}>
                        <Typography component="span">
                          {" "}
                          <Icon
                            style={{
                              verticalAlign: "bottom",
                              color: "orange",
                            }}
                          >
                            warning
                          </Icon>{" "}
                          {LOCALE.no_data_for_analysis}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                  )}
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <CardHeader color="theme">
                  <Typography>{LOCALE.study_costs}</Typography>
                </CardHeader>
                <CardBody>
                  {dataMock1?.length > 0 ? (
                    <Grid container>
                      <Grid item xs={5}>
                        {dataMock1?.map((element) => (
                          <Grid container>
                            <Grid item xs={2}>
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  backgroundColor: element.color,
                                }}
                              ></div>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography style={{ fontSize: "12px" }}>
                                {element.title === "PENDING"
                                  ? LOCALE.submitted
                                  : element.title === "PROCESSING"
                                  ? LOCALE.in_processing
                                  : element.title === "APPROVED"
                                  ? LOCALE.approved
                                  : element.title === "REJECTED"
                                  ? LOCALE.rejected
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={7}>
                        <PieChart
                          data={dataMock1}
                          lineWidth={20}
                          paddingAngle={18}
                          rounded
                          label={({ dataEntry }) => dataEntry.value}
                          labelStyle={(index) => ({
                            fill: dataMock1[index].color,
                            fontSize: "5px",
                            fontFamily: "sans-serif",
                            fontWeight: "bold",
                          })}
                          labelPosition={60}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={8}>
                        <Typography component="span">
                          {" "}
                          <Icon
                            style={{
                              verticalAlign: "bottom",
                              color: "orange",
                            }}
                          >
                            warning
                          </Icon>{" "}
                          {LOCALE.no_data_for_analysis}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                  )}
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <CardHeader color="theme">
                  <Typography>{LOCALE.transportCosts}</Typography>
                </CardHeader>
                <CardBody>
                  {dataMock4?.length > 0 ? (
                    <Grid container>
                      <Grid item xs={5}>
                        {dataMock4?.map((element) => (
                          <Grid container>
                            <Grid item xs={2}>
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  backgroundColor: element.color,
                                }}
                              ></div>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography style={{ fontSize: "12px" }}>
                                {element.title === "PENDING"
                                  ? LOCALE.submitted
                                  : element.title === "PROCESSING"
                                  ? LOCALE.in_processing
                                  : element.title === "APPROVED"
                                  ? LOCALE.approved
                                  : element.title === "REJECTED"
                                  ? LOCALE.rejected
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={7}>
                        <PieChart
                          data={dataMock4}
                          lineWidth={20}
                          paddingAngle={18}
                          rounded
                          label={({ dataEntry }) => dataEntry.value}
                          labelStyle={(index) => ({
                            fill: dataMock4[index].color,
                            fontSize: "5px",
                            fontFamily: "sans-serif",
                            fontWeight: "bold",
                          })}
                          labelPosition={60}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={8}>
                        <Typography component="span">
                          {" "}
                          <Icon
                            style={{
                              verticalAlign: "bottom",
                              color: "orange",
                            }}
                          >
                            warning
                          </Icon>{" "}
                          {LOCALE.no_data_for_analysis}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                  )}
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <CardHeader color="theme">
                  <Typography>{LOCALE.student_doorms}</Typography>
                </CardHeader>
                <CardBody>
                  {dataMock2?.length > 0 ? (
                    <Grid container>
                      <Grid item xs={5}>
                        {dataMock2?.map((element) => (
                          <Grid container>
                            <Grid item xs={2}>
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  backgroundColor: element.color,
                                }}
                              ></div>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography style={{ fontSize: "12px" }}>
                                {element.title === "PENDING"
                                  ? LOCALE.submitted
                                  : element.title === "PROCESSING"
                                  ? LOCALE.in_processing
                                  : element.title === "APPROVED"
                                  ? LOCALE.approved
                                  : element.title === "REJECTED"
                                  ? LOCALE.rejected
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={7}>
                        <PieChart
                          data={dataMock2}
                          lineWidth={20}
                          paddingAngle={18}
                          rounded
                          label={({ dataEntry }) => dataEntry.value}
                          labelStyle={(index) => ({
                            fill: dataMock2[index].color,
                            fontSize: "5px",
                            fontFamily: "sans-serif",
                            fontWeight: "bold",
                          })}
                          labelPosition={60}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={8}>
                        <Typography component="span">
                          {" "}
                          <Icon
                            style={{
                              verticalAlign: "bottom",
                              color: "orange",
                            }}
                          >
                            warning
                          </Icon>{" "}
                          {LOCALE.no_data_for_analysis}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                  )}
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <CardHeader color="theme">
                  <Typography>
                    {LOCALE.student_private_accommodation}
                  </Typography>
                </CardHeader>
                <CardBody>
                  {dataMock3?.length > 0 ? (
                    <Grid container>
                      <Grid item xs={5}>
                        {dataMock3?.map((element) => (
                          <Grid container>
                            <Grid item xs={2}>
                              <div
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  backgroundColor: element.color,
                                }}
                              ></div>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography style={{ fontSize: "12px" }}>
                                {element.title === "PENDING"
                                  ? LOCALE.submitted
                                  : element.title === "PROCESSING"
                                  ? LOCALE.in_processing
                                  : element.title === "APPROVED"
                                  ? LOCALE.approved
                                  : element.title === "REJECTED"
                                  ? LOCALE.rejected
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={7}>
                        <PieChart
                          data={dataMock3}
                          lineWidth={20}
                          paddingAngle={18}
                          rounded
                          label={({ dataEntry }) => dataEntry.value}
                          labelStyle={(index) => ({
                            fill: dataMock3[index].color,
                            fontSize: "5px",
                            fontFamily: "sans-serif",
                            fontWeight: "bold",
                          })}
                          labelPosition={60}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={8}>
                        <Typography component="span">
                          {" "}
                          <Icon
                            style={{
                              verticalAlign: "bottom",
                              color: "orange",
                            }}
                          >
                            warning
                          </Icon>{" "}
                          {LOCALE.no_data_for_analysis}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                  )}
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6} sm={12}>
          <Card>
            <CardHeader color="theme">
              <Typography>{LOCALE.reports}</Typography>
            </CardHeader>
            <CardBody>
              <Grid container spacing={3}>
                <Grid item md={12} sm={12}>
                  <Table>
                    <TableHead className={classes.tableHeadRow}>
                      <TableRow>
                        <TableCell>
                          <Typography>{LOCALE.modules}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {LOCALE.download_report_approved}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography>{LOCALE.school_supplies}</Typography>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "150px", textAlign: "center" }}
                        >
                          <a
                            target="blank"
                            href={`${
                              SETTINGS.API_URL
                            }voucherRequest/schoolSupplies/report?access_token=${
                              AuthService.getToken().access_token
                            }`}
                          >
                            <IconButton
                              size="small"
                              color="secondary"
                              aria-label="Download"
                            >
                              <Icon>cloud_download</Icon>
                            </IconButton>
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>{LOCALE.study_costs}</Typography>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "150px", textAlign: "center" }}
                        >
                          <a
                            target="blank"
                            href={`${
                              SETTINGS.API_URL
                            }voucherRequest/studyCosts/report?access_token=${
                              AuthService.getToken().access_token
                            }`}
                          >
                            <IconButton
                              size="small"
                              color="secondary"
                              aria-label="Download"
                            >
                              <Icon>cloud_download</Icon>
                            </IconButton>
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>{LOCALE.transportCosts}</Typography>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "150px", textAlign: "center" }}
                        >
                          <a
                            target="blank"
                            href={`${
                              SETTINGS.API_URL
                            }voucherRequest/transportCosts/report?access_token=${
                              AuthService.getToken().access_token
                            }`}
                          >
                            <IconButton
                              size="small"
                              color="secondary"
                              aria-label="Download"
                            >
                              <Icon>cloud_download</Icon>
                            </IconButton>
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>{LOCALE.studentDoorm}</Typography>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "150px", textAlign: "center" }}
                        >
                          <a
                            target="blank"
                            href={`${
                              SETTINGS.API_URL
                            }voucherRequest/studentDoorms/report?access_token=${
                              AuthService.getToken().access_token
                            }`}
                          >
                            <IconButton
                              size="small"
                              color="secondary"
                              aria-label="Download"
                            >
                              <Icon>cloud_download</Icon>
                            </IconButton>
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>
                            {LOCALE.student_private_accommodation}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "150px", textAlign: "center" }}
                        >
                          <a
                            target="blank"
                            href={`${
                              SETTINGS.API_URL
                            }voucherRequest/studentPrivateAccommodation/report?access_token=${
                              AuthService.getToken().access_token
                            }`}
                          >
                            <IconButton
                              size="small"
                              color="secondary"
                              aria-label="Download"
                            >
                              <Icon>cloud_download</Icon>
                            </IconButton>
                          </a>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
