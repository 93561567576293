import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const studentDoormsFormValidationEl = yup.object().shape({
  studentDoormsVoucherRequest: yup.object().shape({
    nameSurname: yup
      .string()
      .required(LOCALE.nameSurname + LOCALE.required_field),
    embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
    addressLine: yup
      .string()
      .required(LOCALE.addressLine + LOCALE.required_field),
    email: yup
      .string()
      .required(LOCALE.email + LOCALE.required_field)
      .email(LOCALE.email + LOCALE.invalid_email_address_format),
    studentDoorm: yup
      .string()
      .required(LOCALE.studentDoorm + LOCALE.required_field),
    yearsOfStudy: yup
      .string()
      .required(LOCALE.yearsOfStudy + LOCALE.required_field),
  }),
  file1: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file2: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file3: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file4: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file10: yup.array().required(LOCALE.proof + LOCALE.required_field),
});

export const studentDoormsFormValidationElYearsOfStudy = yup.object().shape({
  studentDoormsVoucherRequest: yup.object().shape({
    nameSurname: yup
      .string()
      .required(LOCALE.nameSurname + LOCALE.required_field),
    embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
    addressLine: yup
      .string()
      .required(LOCALE.addressLine + LOCALE.required_field),
    email: yup.string().required(LOCALE.email + LOCALE.required_field),
    studentDoorm: yup
      .string()
      .required(LOCALE.studentDoorm + LOCALE.required_field),
    yearsOfStudy: yup
      .string()
      .required(LOCALE.yearsOfStudy + LOCALE.required_field),
  }),
  file1: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file2: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file3: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file4: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file10: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file5: yup.array().required(LOCALE.proof + LOCALE.required_field),
  file6: yup.array().required(LOCALE.proof + LOCALE.required_field),
});

export const studentDoormsFormValidation = yup.object().shape({
  studentDoormsVoucherRequest: yup.object().shape({
    nameSurname: yup
      .string()
      .required(LOCALE.nameSurname + LOCALE.required_field),
    embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
    addressLine: yup
      .string()
      .required(LOCALE.addressLine + LOCALE.required_field),
    email: yup.string().required(LOCALE.email + LOCALE.required_field),
    studentDoorm: yup
      .string()
      .required(LOCALE.studentDoorm + LOCALE.required_field),
    yearsOfStudy: yup
      .string()
      .required(LOCALE.yearsOfStudy + LOCALE.required_field),
  }),
});
