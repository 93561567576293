import { blackColor, whiteColor, hexToRgb } from "../../mainStyle";

const cardStyle = (theme) => ({
  card: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "70px",
    },
    border: "0",
    marginBottom: "30px",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: whiteColor,
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    maxHeight: "100%",
    wordWrap: "break-word",
    fontSize: ".875rem",
    zIndex: "1",
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none",
  },
  cardProfile: {
    marginTop: "30px",
    textAlign: "center",
  },
  cardChart: {
    "& p": {
      marginTop: "0px",
      paddingTop: "0px",
    },
  },
});

export default cardStyle;
