import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import {
  TablePagination,
  TableRow,
  TableFooter,
  Icon,
  IconButton,
  Tooltip,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Button,
  Chip,
  Grid,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
// @material-ui/styles
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { makeStyles } from "@material-ui/core/styles";
import { ObjectionRepository } from "../eVoucher/ObjectionRepository";
import { LOCALE } from "../../properties/Locale";
import CardHeader from "../../common/components/Card/CardHeader";
import Card from "../../common/components/Card/Card";
import CardBody from "../../common/components/Card/CardBody";
import {
  changePageTitle,
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../common/actions/CommonActions";
import styles from "../../assets/jss/components/tableStyle";
import VoucherAppTable from "./VoucherAppTable";

const useStyles = makeStyles(styles);

export default function ObjectionReviewComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState();
  const [data, setData] = useState({
    totalElements: 0,
    number: 0,
    content: [],
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("dateCreated");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const globalState = useSelector((state) => state);
  const [response, setResponse] = useState();
  const [formDataErrors, setFormDataErrors] = useState({});

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.objection));
    loadObjection(props.match.params.objectionId);
  }, [page, size, globalState.triggerRerender, orderBy, orderDirection]);

  const loadObjection = (id) => {
    ObjectionRepository.fetch(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  const acceptObjection = () => {
    if (!validateFormData()) return;
    let objection = data;
    objection.response = response;
    objection.status = "ACCEPTED";
    ObjectionRepository.update(objection)
      .then((res) => {
        notifyShowSuccessMessage(LOCALE.success);
      })
      .catch((err) => {
        notifyShowErrorMessage(err.message);
      });
  };

  const rejectObjection = () => {
    if (!validateFormData()) return;
    let objection = data;
    objection.response = response;
    objection.status = "REJECTED";
    ObjectionRepository.update(objection)
      .then((res) => {
        notifyShowSuccessMessage(LOCALE.success);
      })
      .catch((err) => {
        notifyShowErrorMessage(err.message);
      });
  };

  const validateFormData = () => {
    if (!response) {
      setFormDataErrors({
        response: LOCALE.response + " " + LOCALE.required_field,
      });
      dispatch(
        notifyShowErrorMessage(LOCALE.response + " " + LOCALE.required_field)
      );
      return false;
    } else {
      setFormDataErrors({});
      return true;
    }
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Grid container spacing={3}>
        <Grid item sm={12} style={{ marginBottom: "10px" }}>
          <IconButton
            style={{ float: "left" }}
            aria-label="back"
            onClick={() => setRedirectTo("/app/objectionReviews")}
          >
            <Icon>arrow_back_ios</Icon>
          </IconButton>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={6} sm={12}>
          <Card>
            <CardHeader color="theme">{LOCALE.objection}</CardHeader>
            <CardBody style={{ padding: "10px" }}>
              <Table>
                <TableRow>
                  <TableCell>{LOCALE.name}</TableCell>
                  <TableCell>{data?.nameSurname && data.nameSurname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.embg}</TableCell>
                  <TableCell>{data?.embg && data.embg}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.telephone}</TableCell>
                  <TableCell>{data?.phone && data.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.email}</TableCell>
                  <TableCell>{data?.email && data.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.status}</TableCell>
                  <TableCell>
                    {data.status === "PENDING" && (
                      <Chip
                        label={LOCALE.pending.toUpperCase()}
                        color="primary"
                      />
                    )}
                    {data.status === "REJECTED" && (
                      <Chip
                        label={LOCALE.rejected.toUpperCase()}
                        color="secondary"
                      />
                    )}
                    {data.status === "ACCEPTED" && (
                      <Chip
                        label={LOCALE.accepted.toUpperCase()}
                        style={{ backgroundColor: "green", color: "white" }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.date_created}</TableCell>
                  <TableCell>
                    {moment(data.dateCreated).format("YYYY-MM-DD HH:mm:ss")} (
                    {moment(data.dateCreated).fromNow()})
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{LOCALE.objection}</TableCell>
                  <TableCell>{data?.description && data.description}</TableCell>
                </TableRow>
              </Table>
            </CardBody>
          </Card>

          <Card>
            <CardHeader color="theme">{LOCALE.response}</CardHeader>
            <CardBody style={{ padding: "10px" }}>
              <TextField
                id="response"
                label={LOCALE.response}
                multiline={true}
                disabled={data.status !== "PENDING"}
                rows={3}
                error={formDataErrors?.response}
                helperText={formDataErrors?.response}
                fullWidth={true}
                value={
                  data.status === "PENDING"
                    ? response
                    : data.response
                    ? data.response
                    : ""
                }
                onChange={(e) => setResponse(e.target.value)}
                margin="normal"
                variant="outlined"
                size="small"
              />
              <Button
                style={{ float: "right" }}
                size="small"
                variant="contained"
                color="primary"
                disabled={data.status !== "PENDING"}
                onClick={() => acceptObjection()}
              >
                {LOCALE.accept}
              </Button>
              <Button
                style={{ float: "right", marginRight: "5px" }}
                size="small"
                disabled={data.status !== "PENDING"}
                variant="contained"
                color="secondary"
                onClick={() => rejectObjection()}
              >
                {LOCALE.reject}
              </Button>
            </CardBody>
          </Card>
        </Grid>
        <Grid item md={6} sm={12}>
          <Card>
            <CardHeader color="theme">{LOCALE.application}</CardHeader>
            {data && data.studyCostsVoucherRequest && (
              <VoucherAppTable data={data.studyCostsVoucherRequest} />
            )}
            {data && data.studentPrivateAccommodationVoucherRequest && (
              <VoucherAppTable
                data={data.studentPrivateAccommodationVoucherRequest}
              />
            )}
            {data && data.schoolSuppliesVoucherRequest && (
              <VoucherAppTable data={data.schoolSuppliesVoucherRequest} />
            )}
            {data && data.studentDoormsVoucherRequest && (
              <VoucherAppTable data={data.studentDoormsVoucherRequest} />
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
