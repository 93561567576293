import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const objectionsFormValidation = yup.object().shape({
  nameSurname: yup
    .string()
    .required(LOCALE.nameSurname + LOCALE.required_field),
  embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.email + LOCALE.invalid_email_address_format),
  phone: yup.string().required(LOCALE.telephone + LOCALE.required_field),
  description: yup.string().required(LOCALE.objection + LOCALE.required_field),
});
